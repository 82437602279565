import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Currency } from "dinero.js";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { useFetch2 as useFetch } from "src/Components/Common/useFetch";
import FormatDate from "src/Utils/DateUtils";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { uiLoggerBcPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { RaisedIssueBc, RAISE_REQUEST_STATUS } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton, NdButton, NdStepIcon, NdTextField, validateEmail } from "../PartnerPortal/CommonComponents";
import { LoadingPage } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import {
  BcCustomisationDetails,
  CommonUploaderBox,
  ConfirmEditingDialog,
  StateDispatch,
} from "./BalanceConfirmationPortalCommon";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";

export interface BcPortalFinalPageDetails {
  closingBalance: number;
  ledgers: string[];
  confirmationDocument: string;
  isBalanceConfirmed: boolean;
  isPdfDownloaded: boolean;
  edited: boolean;
  approved: boolean;
}

export interface ClosingBalanceDetails {
  ownClosingBalance: string;
  businessPartnerClosingBalance: string;
  closingBalanceDate: Date;
  closingBalanceDifference: string;
  currency: Currency;
}

export interface RaiseRequestData {
  name: string;
  email: string;
  phone?: string;
  reason: string;
}

export interface NameAndLogo {
  name: string; // ruName
  logo: string;
  businessPartnerName: string;
  companyName: string;
  branchName: string;
}

const FinalPage = ({
  ruName,
  openDialog,
  setOpenDialog,
}: {
  ruName: string;
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const [details, setDetails] = useState<BcPortalFinalPageDetails>({} as any);
  const [closingBalanceDetails, setClosingBalanceDetails] = useState<ClosingBalanceDetails>({} as any);
  const [nameAndLogo, setNameAndLogo] = useState<NameAndLogo>({} as any);

  const [customization, setCustomization] = useState<{
    balanceConfirmed: BcCustomisationDetails;
    balanceNotConfirmed: BcCustomisationDetails;
  }>({} as any);

  const { setPageStatus, setEditing } = useContext(BcPortalContext);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const GetDetailsForFinalPage = () =>
    useFetch<{ details: BcPortalFinalPageDetails; closingBalanceDetails: ClosingBalanceDetails }>(
      "/api/BalanceConfirmationPortal/GetDetailsForFinalPage",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          setDetails(res.data?.details);
          setClosingBalanceDetails(res.data?.closingBalanceDetails);
        },
      }
    );
  // const promisedDetailsForFinalPage = GetDetailsForFinalPage();

  const GetPartnerNameAndLogo = () =>
    useFetch<NameAndLogo>("/api/BalanceConfirmationPortal/GetPartnerNameAndLogo", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setNameAndLogo(res.data);
      },
    });
  // const promisedNameAndLogo = GetPartnerNameAndLogo();

  const GetCustomisationDetails = () =>
    useFetch<{ balanceConfirmed: BcCustomisationDetails; balanceNotConfirmed: BcCustomisationDetails }>(
      "/api/BalanceConfirmationPortal/GetCustomisationDetails",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          const defaultCustomisation: BcCustomisationDetails = {
            isPdfRequired: false,
            isLedgerRequired: false,
          };

          setCustomization({
            balanceConfirmed: res.data?.balanceConfirmed || defaultCustomisation,
            balanceNotConfirmed: res.data?.balanceNotConfirmed || defaultCustomisation,
          });
        },
      }
    );

  const CheckAndUpdateIfEditable = () =>
    new Promise<boolean>((resolve, reject) => {
      useFetch<{ isEditable: boolean }>("/api/BalanceConfirmationPortal/CheckAndUpdateIfEditable", "POST", {
        setApiErrorMsg,
        setShowErrorAlert,
        data: {
          data: encryptedData,
        },
        thenCallBack: (res) => {
          if (res.data?.isEditable) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        catchCallBack: () => reject(false),
        errorCallback: () => reject(false),
      });
    });

  console.log(closingBalanceDetails);

  useEffect(() => {
    GetDetailsForFinalPage();
    GetPartnerNameAndLogo();
    GetCustomisationDetails();
    setEditing(false);
  }, []);

  const FinalPageDetails = ({ openDialog, setOpenDialog }) => {
    // const { details, closingBalanceDetails } = promisedDetailsForFinalPage.read();
    // const nameAndLogo = promisedNameAndLogo.read();

    return (
      <>
        <ApiErrorCatch apiErrorMsg={apiErrorMsg} showUploadErrorAlert={showErrorAlert} />
        <Stack className="fade_in details_box" py={4} gap={3} maxWidth={900} mx={"auto"}>
          <Box
            className={$.BR_fix + " space_between vertical_center_align"}
            px={4}
            py={3}
            gap={2}
            bgcolor={"#2E7D32"}
            color={"#fff"}
          >
            <Box className="left vertical_center_align" gap={2}>
              <NdStepIcon icon={1} active={false} completed={true} sx={{ color: "#fff !important" }} />
              <Typography variant="h5">
                Your response has been sent to {ruName[0].toUpperCase() + ruName.slice(1)}
              </Typography>
            </Box>
            <Box className="right">
              {details?.edited !== false && (
                <Chip
                  label={"Edited Response"}
                  color="default"
                  size="medium"
                  variant="outlined"
                  sx={{ "& > *": { font: "inherit", color: "#ccc" } }}
                />
              )}
            </Box>
          </Box>
          {/* <NdTextField
            placeholder="Closing Balance"
            label="Closing Balance"
            type="number"
            disabled={true}
            size="small"
            value={details.closingBalance || ""}
          /> */}
          <Box
            className={$.BR_fix + " balance_confirmed_box vertical_center_align space_between"}
            px={2}
            py={"12px"}
            gap={2}
            border="1px solid #D7D7D7"
          >
            <Typography variant="subtitle1">Balance Confirmed</Typography>
            <Typography variant="subtitle1" color={"#2E7D32"} fontWeight={700}>
              {details?.isBalanceConfirmed ? "YES" : "NO"}
            </Typography>
          </Box>
          {details?.isBalanceConfirmed ? (
            <ConfirmedSegment nameAndLogo={nameAndLogo} closingBalanceDetails={closingBalanceDetails} />
          ) : (
            <NotConfirmedSegment nameAndLogo={nameAndLogo} closingBalanceDetails={closingBalanceDetails} />
          )}
          <Stack gap={1}>
            {details.confirmationDocument && (
              <CommonUploaderBox
                fileType="signedPdf"
                setFileNames={null}
                viewMode={true}
                viewModePdfName={details.confirmationDocument}
                customization={
                  details?.isBalanceConfirmed ? customization.balanceConfirmed : customization.balanceNotConfirmed
                }
              />
            )}
            {details.ledgers?.length > 0 && (
              <CommonUploaderBox
                fileType="ledgerFile"
                setFileNames={null}
                viewMode={true}
                viewModeLedgerNames={details.ledgers}
                customization={
                  details?.isBalanceConfirmed ? customization.balanceConfirmed : customization.balanceNotConfirmed
                }
              />
            )}
          </Stack>
          <Box
            className={$.BR_fix + " vertical_center_align space_between"}
            px={2}
            py={"12px"}
            gap={2}
            border="1px solid #D7D7D7"
          >
            <Typography variant="subtitle1">
              If you think it's a mistake, please raise a request to resolve it
            </Typography>

            <Box className="d_flex" gap={2}>
              {details.approved !== true && (
                <NdAsyncButton
                  className={$.BR_fix}
                  onClick={async () => {
                    setOpenEditDialog(true);
                  }}
                  color="success"
                  buttonComponent={Button}
                >
                  Edit Response
                </NdAsyncButton>
              )}
              <Button
                className={$.BR_fix}
                onClick={() => {
                  setOpenDialog(true);
                  uiLoggerPortal(uiLoggerBcPortal.ui_PpBcPostSubmitRaiseRequestClick, encryptedData);
                }}
              >
                Raise Request
              </Button>
            </Box>
          </Box>
        </Stack>
        <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} finalPage={true} />
        <ConfirmEditingDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          approveAction={async () => {
            try {
              const isEditable = await CheckAndUpdateIfEditable();
              if (isEditable) {
                setEditing(true);
                setPageStatus("NotFilled");
              }
            } catch (error) {
              console.log({ error });
              setOpenEditDialog(false);
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingPage noHeader={true} />}>
        <FinalPageDetails openDialog={openDialog} setOpenDialog={setOpenDialog} />
      </Suspense>
    </>
  );
};

interface ConfirmationSegmentProps {
  nameAndLogo: NameAndLogo;
  closingBalanceDetails: ClosingBalanceDetails;
}

const ConfirmedSegment = ({ nameAndLogo, closingBalanceDetails }: ConfirmationSegmentProps) => {
  return (
    <>
      <Stack className={$.BR_fix + " cb_table_left_right"} border="1px solid #D7D7D7">
        <Box p={2}>
          <Typography sx={{ "& > b": { fontFamily: "inherit" } }}>
            Closing Balance in <b>{nameAndLogo?.businessPartnerName}</b> and <b>{nameAndLogo?.name}</b> ledger, as on{" "}
            <b>{FormatDate(closingBalanceDetails?.closingBalanceDate).toString()}</b>
          </Typography>
        </Box>
        <Box p={2} width={"100%"} borderTop="1px solid #D7D7D7">
          <Box className="vertical_center_align" gap={1}>
            <Chip
              label={Number(closingBalanceDetails?.businessPartnerClosingBalance) > 0 ? "Receivable" : "Payable"}
              color="default"
              size="small"
              variant="filled"
              sx={{ "& > *": { fontFamily: "inherit" } }}
            />
            <Typography variant="subtitle1" fontWeight={700}>
              {closingBalanceDetails?.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      Number(closingBalanceDetails?.businessPartnerClosingBalance),
                      (closingBalanceDetails?.currency as Currency) || "INR"
                    )
                  )}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

const NotConfirmedSegment = ({ nameAndLogo, closingBalanceDetails }: ConfirmationSegmentProps) => {
  return (
    <>
      <Stack className={$.BR_fix + " cb_table_left_right"} border="1px solid #D7D7D7">
        <Box p={2}>
          <Typography>
            Closing Balance as on <b>{FormatDate(closingBalanceDetails?.closingBalanceDate).toString()}</b> for:
          </Typography>
        </Box>
        <Box className="d_flex" borderTop="1px solid #D7D7D7" borderBottom="1px solid #D7D7D7" bgcolor={"#F3F3F3"}>
          <Box px={2} py={1} width={"100%"}>
            <Typography variant="subtitle1">
              {nameAndLogo?.name} {nameAndLogo?.companyName} {nameAndLogo?.branchName}
            </Typography>
          </Box>
          <Box px={2} py={1} width={"100%"} borderLeft="1px solid #D7D7D7">
            <Typography variant="subtitle1">{nameAndLogo?.businessPartnerName}</Typography>
          </Box>
        </Box>
        <Box className="d_flex">
          <Box p={2} width={"100%"}>
            <Chip
              label={Number(closingBalanceDetails?.ownClosingBalance) > 0 ? "Receivable" : "Payable"}
              color="default"
              size="small"
              variant="filled"
              sx={{ "& > *": { fontFamily: "inherit" } }}
            />
            <Typography variant="subtitle1" fontWeight={700}>
              {closingBalanceDetails?.ownClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      Number(closingBalanceDetails?.ownClosingBalance),
                      (closingBalanceDetails?.currency as Currency) || "INR"
                    )
                  )}
            </Typography>
          </Box>
          <Box p={2} width={"100%"} borderLeft="1px solid #D7D7D7">
            <Chip
              label={Number(closingBalanceDetails?.businessPartnerClosingBalance) > 0 ? "Receivable" : "Payable"}
              color="default"
              size="small"
              variant="filled"
              sx={{ "& > *": { fontFamily: "inherit" } }}
            />

            <Typography variant="subtitle1" fontWeight={700}>
              {closingBalanceDetails?.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      Number(closingBalanceDetails?.businessPartnerClosingBalance),
                      (closingBalanceDetails?.currency as Currency) || "INR"
                    )
                  )}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack className={$.BR_fix + " cb_difference_table"} border="1px solid #D7D7D7">
        <Box p={2}>
          <Typography>Closing Balance difference</Typography>
        </Box>
        <Box p={2} width={"100%"} borderTop="1px solid #D7D7D7">
          <Typography variant="subtitle1" fontWeight={700}>
            {closingBalanceDetails?.closingBalanceDifference === null
              ? null
              : formatMoney(
                  ToDineroObj(
                    Number(closingBalanceDetails?.closingBalanceDifference),
                    (closingBalanceDetails?.currency as Currency) || "INR"
                  )
                )}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

interface RaiseRequestDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  finalPage?: boolean;
}

export const RaiseRequestDialog = ({ openDialog, setOpenDialog, finalPage }: RaiseRequestDialogProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const encryptedData = params.get("data");

  const [partnerName, setPartnerName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isNewIssue, setIsNewIssue] = useState<boolean>(true);
  const [issues, setIssues] = useState<RaisedIssueBc[]>([]);

  const { confirmStatus } = useContext(BcPortalContext);

  useEffect(() => {
    if (openDialog) {
      getAllPreviouslyRaisedIssues();
      if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfRaiseIssueClick, encryptedData);
      else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfRaiseIssueClick, encryptedData);
    }
  }, [openDialog]);

  useEffect(() => {
    GetPartnerNameAndLogo();
  }, []);

  const GetPartnerNameAndLogo = () =>
    useFetch<{ name: string; logo: string }>("/api/BalanceConfirmationPortal/GetPartnerNameAndLogo", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (response) => {
        setPartnerName(response.data?.name);
      },
    });

  const getAllPreviouslyRaisedIssues = async () => {
    setLoading(true);
    useFetch<{ previousRequest: RaisedIssueBc[] }>("/api/BalanceConfirmationPortal/GetPreviouslyRaisedIssue", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (response) => {
        const { previousRequest } = response.data;
        if (previousRequest) {
          setIsNewIssue(previousRequest.length === 0);
          setIssues(previousRequest);
        }
        setLoading(false);
      },
    });
  };

  return (
    <>
      <ApiErrorCatch
        apiErrorMsg={apiErrorMsg}
        showUploadErrorAlert={showErrorAlert}
        showUploadSuccessAlert={showSuccessAlert}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <LoadingPage noHeader={true} />
        ) : isNewIssue ? (
          <DialogContentNewIssue
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            finalPage={finalPage}
            setApiErrorMsg={setApiErrorMsg}
            setShowErrorAlert={setShowErrorAlert}
            setShowSuccessAlert={setShowSuccessAlert}
          />
        ) : (
          <DialogContentExistingIssue
            setOpen={setOpenDialog}
            setIsNewIssue={setIsNewIssue}
            issues={issues}
            bpName={partnerName}
          />
        )}
      </Dialog>
    </>
  );
};

const DialogContentNewIssue = ({
  openDialog,
  setOpenDialog,
  finalPage,
  setApiErrorMsg,
  setShowErrorAlert,
  setShowSuccessAlert,
}: {
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  finalPage?: boolean;
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [notFilled, setNotFilled] = useState(true);
  const [filledData, setFilledData] = useState<RaiseRequestData>({ name: "", email: "", phone: "", reason: "" });

  const { confirmStatus } = useContext(BcPortalContext);

  const [submittingRequest, setSubmittingRequest] = useState(false);

  const checkFormInvalid = () => {
    for (const key in filledData) {
      if (key === "phone") filledData.phone = filledData.phone.trim();
      else if (!filledData[key]) {
        if (key === "email") return !validateEmail(filledData.email);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (checkFormInvalid()) setNotFilled(true);
    else setNotFilled(false);
  }, [filledData]);

  useEffect(() => {
    if (!openDialog) setFilledData({ name: "", email: "", phone: "", reason: "" });
  }, [openDialog]);

  const submitRequest = async () => {
    setSubmittingRequest(true);
    useFetch("/api/BalanceConfirmationPortal/RaiseIssue", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        data: encryptedData,
        name: filledData.name,
        email: filledData.email,
        phoneNumber: filledData.phone,
        reason: filledData.reason,
      },
      thenCallBack: (_res) => {
        setOpenDialog(false);
        setSubmittingRequest(false);
      },
      catchCallBack: () => {
        setOpenDialog(false);
        setSubmittingRequest(false);
      },
      errorCallback: () => {
        setOpenDialog(false);
        setSubmittingRequest(false);
      },
    });
  };

  return (
    <>
      <DialogTitle>Raise Query/ Issue</DialogTitle>
      <DialogContent className={$.flex_column} sx={{ pb: 2, px: 3, gap: 2 }}>
        <Stack gap={2} pt={2}>
          <FormControl className="d_flex" sx={{ flexFlow: "row", gap: 2, "&>div": { width: "33%" } }}>
            <NdTextField
              label="Full Name"
              required
              value={filledData.name}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, name: _e.target.value }))}
            />
            <NdTextField
              label="Email Address"
              required
              type="email"
              value={filledData.email}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, email: _e.target.value }))}
              error={filledData.email && !validateEmail(filledData.email)}
            />
            <NdTextField
              label="Phone"
              type="number"
              value={filledData.phone}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, phone: _e.target.value }))}
            />
          </FormControl>
          <NdTextField
            label="Reason"
            required
            fullWidth
            multiline
            maxRows={4}
            value={filledData.reason}
            onChange={(_e) => setFilledData((_d) => ({ ..._d, reason: _e.target.value }))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className={$.BR_fix} onClick={() => setOpenDialog(false)} color="inherit">
          Cancel
        </Button>
        <NdButton
          onClick={() => {
            submitRequest();
            if (finalPage) {
              uiLoggerPortal(uiLoggerBcPortal.ui_PpBcPostSubmitRaiseRequestSubmitClick, encryptedData);
            } else {
              if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfRaiseIssueSubmitClick, encryptedData);
              else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfRaiseIssueSubmitClick, encryptedData);
            }
          }}
          variant="contained"
          disabled={notFilled || submittingRequest}
        >
          <LoadingIcon loading={submittingRequest} /> Submit
        </NdButton>
      </DialogActions>
    </>
  );
};

interface DialogContentExistingIssueProps {
  setOpen: StateDispatch<boolean>;
  setIsNewIssue: StateDispatch<boolean>;
  issues: RaisedIssueBc[];
  bpName: string;
}

const DialogContentExistingIssue = ({ setOpen, setIsNewIssue, issues, bpName }: DialogContentExistingIssueProps) => {
  return (
    <>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <Box className="left vertical_center_align">
          <DialogTitle>Previously Raised Query/ Issues</DialogTitle>
          <NdButton variant="contained" onClick={() => setIsNewIssue(true)}>
            Raise New Issue
          </NdButton>
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent className={$.flex_column} sx={{ p: 2, gap: 2 }}>
        <Stack gap={2} className="Issues_container">
          {issues?.map((issue) => (
            <SingleIssue key={issue.issueId} bpName={bpName} {...issue} />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
};

interface SingleIssueProps extends RaisedIssueBc {
  bpName: string;
  markIssueResolved?: (issueId: number) => Promise<{
    success: boolean;
  }>;
  // replyAction?: (bpId: number, reason: string, requestedBy: string, emailId: string, phoneNumber?: string) => void;
  replyAction?: (issueId: number) => void;
}

export const SingleIssue = ({
  businessPartnerName,
  emailId,
  phoneNumber,
  reason,
  status,
  issueId,
  requestedBy,
  markIssueResolved,
  replyAction,
  date,
}: SingleIssueProps) => {
  return (
    <>
      <Box className="Issue" sx={{ border: "1px solid #d7d7d7", bgcolor: "#fafafa" }}>
        <Box className="vertical_center_align space_between" px={2} py={1} gap={1}>
          <Typography variant="subtitle1" fontWeight={700}>
            Request Raised by {businessPartnerName}
          </Typography>
          {status === RAISE_REQUEST_STATUS.resolved ? (
            <Chip label="Resolved" sx={{ bgcolor: "#11C881", "& *": { fontFamily: "inherit" } }} />
          ) : (
            <Chip label="Pending" sx={{ bgcolor: "#DFAE39", "& *": { fontFamily: "inherit" } }} />
          )}
        </Box>
        <Stack p={2} gap={1} borderTop={"1px solid #d7d7d7"}>
          <CardContent className={$.BR_fix + " d_flex"} sx={{ p: 1, gap: 3, bgcolor: "#fff" }}>
            <Box className="col" sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="overline">Full name</Typography>
              <Typography variant="subtitle2">{requestedBy}</Typography>
            </Box>
            <Box className="col" sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="overline">Email address</Typography>
              <Typography variant="subtitle2">{emailId}</Typography>
            </Box>
            <Box className="col">
              <Typography variant="overline">Phone</Typography>
              <Typography variant="subtitle2">{phoneNumber ? phoneNumber : "-"}</Typography>
            </Box>
            <Box className="col">
              <Typography variant="overline">Date</Typography>
              <Typography variant="subtitle2">{date ? FormatDate(date, "DD/MM/YYYY H:mm").toString() : "-"}</Typography>
            </Box>
          </CardContent>
          <CardContent className={$.BR_fix} sx={{ p: 1, gap: 3, bgcolor: "#fff" }}>
            <Typography variant="overline">Reason</Typography>
            <Typography variant="body2">{reason}</Typography>
          </CardContent>
        </Stack>
        <Box py={1} px={2} className="space_between" bgcolor={"#fff"}>
          {markIssueResolved && (
            <NdAsyncButton
              variant="contained"
              onClick={async () => {
                try {
                  const { success } = await markIssueResolved(issueId);
                  if (success) status = RAISE_REQUEST_STATUS.resolved;
                } catch {
                  status = RAISE_REQUEST_STATUS.pending;
                }
              }}
            >
              Mark As resolved
            </NdAsyncButton>
          )}
          {replyAction && (
            <NdButton
              variant="contained"
              onClick={() => {
                replyAction(issueId);
              }}
            >
              Reply
            </NdButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FinalPage;
