import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  NdButton,
  NdCheckbox,
  NdTextField,
  NotMsmeDetails,
  validateEmail,
  validatePhoneNumber,
} from "../PartnerPortal/CommonComponents";
import { BackdropLoader } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";

export type ContactDetailsData = Omit<NotMsmeDetails, "companyName" | "panNumber">;

export const SubmitContactDetails = ({
  openDialog,
  setOpenDialog,
  afterSubmitAction,
}: {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  afterSubmitAction: (details: ContactDetailsData) => void;
}) => {
  const [data, setData] = useState<ContactDetailsData>({
    designation: "",
    email: "",
    personName: "",
  });
  const [optionalData, setOptionalData] = useState<Pick<ContactDetailsData, "phoneNumber">>({
    phoneNumber: "",
  });

  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const [apiErrorMsg] = useState<string>(null);
  const [showErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert] = useState(false);
  params.get("data");

  const [notFilled, setNotFilled] = useState<boolean>(true);
  const [checkboxTicked, setCheckboxTicked] = useState(false);

  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const checkFormInvalid = () => {
    for (const key in data) {
      if (!data[key]) return true;
    }
    return false;
  };

  const submitData = async () => {
    setOpenBackDrop(true);
    afterSubmitAction({ ...data, ...optionalData });
  };

  useEffect(() => {
    if (checkFormInvalid()) setNotFilled(true);
    else setNotFilled(false);
  }, [data]);

  return (
    <>
      <ApiErrorCatch
        apiErrorMsg={apiErrorMsg}
        showUploadErrorAlert={showErrorAlert}
        showUploadSuccessAlert={showSuccessAlert}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Fill out your details to submit the response</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3, gap: 2 }}>
          <Stack gap={3}>
            <Alert className={$.BR_fix} severity="info">
              <Typography variant="body2">Details provided here will be used for the auditing purpose</Typography>
            </Alert>
            <Box className="d_flex" flexDirection={"column"} gap={1}>
              <FormControl fullWidth sx={{ gap: 2, flexFlow: "row", "&>div": { width: "50%" } }}>
                <NdTextField
                  label="Full Name"
                  required
                  value={data.personName}
                  onChange={(_e) => setData((_d) => ({ ..._d, personName: _e.target.value }))}
                />
                <NdTextField
                  label="Designation"
                  required
                  value={data.designation}
                  onChange={(_e) => setData((_d) => ({ ..._d, designation: _e.target.value }))}
                />
              </FormControl>
              <FormControl fullWidth sx={{ gap: 2, flexFlow: "row", "&>div": { width: "50%" } }}>
                <NdTextField
                  label="Email"
                  type="email"
                  required
                  value={data.email}
                  onChange={(_e) => setData((_d) => ({ ..._d, email: _e.target.value }))}
                  error={data.email && !validateEmail(data.email)}
                />
                <NdTextField
                  label="Phone Number"
                  type="number"
                  value={optionalData.phoneNumber}
                  onChange={(_e) => setOptionalData((_d) => ({ ..._d, phoneNumber: _e.target.value }))}
                  error={optionalData.phoneNumber && !validatePhoneNumber(optionalData.phoneNumber)}
                />
              </FormControl>
            </Box>
          </Stack>
          <FormControlLabel
            control={<NdCheckbox checked={checkboxTicked} onChange={() => setCheckboxTicked(!checkboxTicked)} />}
            label="I confirm that above given information is correct"
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButton disabled={notFilled || !checkboxTicked} onClick={submitData} autoFocus variant="contained">
            Submit
          </NdButton>
        </DialogActions>
      </Dialog>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Saving Details..."} />
    </>
  );
};

export default SubmitContactDetails;
