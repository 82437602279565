import React from "react";
const time = 5000;
export const apiSuccessErrorAlertSetTimeout = (
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>,
  setShowMsg: React.Dispatch<React.SetStateAction<string>>
) => {
  return setTimeout(() => {
    setShowAlert(false);
    setShowMsg("");
  }, time);
};

export const defaultErrorMsg = " Error in calling API ";
