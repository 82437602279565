import { Close, ContentPaste } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { ConfigUseCaseType, ErrorResponse, ResponseInterface } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table, { ColumnDefs } from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import "./Admin.scss";
import { DefaultResponse, ListTallyCompanyResponse } from "./AdminInterfaces";

export interface ListTallyLedgerByAdminResponse {
  name: string;
  totalLedgers: number;
  ledgerIds: LedgerIdsInterface[];
  message: string;
}

export interface LedgerIdsInterface {
  ledgerId: string;
}

export interface TallySyncProgressSummary {
  totalVouchersComingFromTallyConnector: number;
  totalVouchersSyncedInDb: number;
  vouchersToBeSynced: number;
  totalLedgersComingFromTallyConnector: number;
  totalLedgersSyncedInDb: number;
  ledgersToBeSynced: number;
  voucherTypeWiseVouchersCount: { [key: string]: number };
  totalLedgersComingFromTally: number;
  totalVouchersComingFromTally: number;
}

export interface TallySyncProgressSummaryResponse extends ResponseInterface {
  summary: TallySyncProgressSummary;
}

const ItemGrid = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return (
    <Grid
      container={true}
      justifyContent="center"
      sx={{
        justifyContent: "center",
        gap: 1,
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        border: "1px solid #eee",
        borderRadius: 5,
        my: 1,
        p: 1,
        width: "360px",
        overflow: "auto",
        minHeight: "40px",
      }}
    >
      {children}
    </Grid>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: "2px 8px",
  borderRadius: "24px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.14)",
}));

const TallyLedger = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [username, setUsername] = useState<string>(null);
  const [name, setName] = useState<string>(null);
  const [totalLedgers, setTotalLedgers] = useState<number>(null);
  const [tallyCompanies, setTallyCompanies] = useState<any[]>([]);
  const [integrated, setIntegrated] = useState<boolean>(false);
  const [qcMetricsFile, setQcMetricsFile] = useState<string>(null);
  const [showTallySyncProgress, setShowTallySyncProgress] = useState<boolean>(false);
  const [summaryOfTallySyncProgress, setSummaryOfTallySyncProgress] = useState<TallySyncProgressSummary>(null);
  const [rowsTallySyncError, setRowsTallySyncError] = useState<{ [k: string]: string }[]>([]);
  const [rowsFTallySyncError, setRowsFTallySyncError] = useState<{ [k: string]: string }[]>([]);
  const [openSyncError, setOpenSyncError] = useState(false);
  const companyId = useRef<string>(null);

  const columnDefinition = React.useMemo(
    () => [
      {
        Header: "Group",
        accessor: "Group",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "BusinessPartnerName",
        accessor: "businessPartnerName",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "LedgerIds",
        accessor: "ledgerId",
        Sort: true,
        minWidth: 480,
        maxWidth: 480,
        width: 480,
      },
      {
        Header: "CompanyId",
        accessor: "companyId",
        Sort: true,
        minWidth: 400,
        maxWidth: 400,
        width: 400,
      },
    ],
    []
  );
  const columnDefinitionTallySyncProgress = React.useMemo(
    () => [
      {
        Header: "VoucherType",
        accessor: (row: any) => row.VoucherType?.toLowerCase(),
        Sort: true,
        minWidth: 400,
        maxWidth: 400,
        width: 400,
        Cell: ({ row }: any) => <div>{row.original.VoucherType}</div>,
      },
      {
        Header: "Count",
        accessor: "Count",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
    ],
    []
  );

  const columnDefinitionTallySyncError = React.useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Error Id",
        accessor: "errorId",
        width: 100,
      },
      {
        Header: "Error",
        accessor: "error",
        width: 210,
      },
      {
        Header: "Type",
        accessor: "typeOfObject",
        width: 100,
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.createdAt).format("YYYY-MM-DD HH:MM"),
        width: 200,
      },
      {
        Header: "Updated At",
        accessor: (row) => moment(row.updatedAt).format("YYYY-MM-DD HH:MM"),
        width: 200,
      },
    ],
    []
  );

  const writeToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rowsTallySyncError);
    const worksheetL = XLSX.utils.json_to_sheet(rowsTallySyncError.filter((d) => d.typeOfObject === "Ledger"));
    const worksheetV = XLSX.utils.json_to_sheet(rowsTallySyncError.filter((d) => d.typeOfObject === "Voucher"));

    const companyName = tallyCompanies?.find((c) => c.companyId === companyId.current)?.companyName || "UnknownCompany";

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sync Error Details");
    XLSX.utils.book_append_sheet(workbook, worksheetL, "Ledger Sync Error Details");
    XLSX.utils.book_append_sheet(workbook, worksheetV, "Voucher Sync Error Details");

    XLSX.writeFile(workbook, `${username}-${companyName}-SyncError.xlsx`);
  };

  const clearData = () => {
    setTallyCompanies([]);
    setRowsData([]);
    setTotalLedgers(null);
    setName(null);
    setIntegrated(false);
    companyId.current = null;
  };

  const getTallyLedger = async (id: any) => {
    try {
      await axios
        .get<ListTallyLedgerByAdminResponse>("/api/ListTallyLedgerByAdmin", {
          params: {
            accountId: username,
            companyId: id,
          },
        })
        .then((response) => {
          setName(response.data.name);
          setTotalLedgers(response.data.totalLedgers);
          setRowsData(response.data.ledgerIds);
          setShowTallySyncProgress(false);
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyLedgerByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyLedgerByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const getTallySyncProgress = (id: string) => {
    try {
      axios
        .get<TallySyncProgressSummaryResponse>("/api/GetTallyProgress", {
          params: {
            accountId: username,
            companyId: id,
          },
        })
        .then((response) => {
          setSummaryOfTallySyncProgress(response.data.summary);
          setShowTallySyncProgress(true);
          getTallySyncError();
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetTallyProgress`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetTallyProgress`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const getTallyCompanyByAdmin = () => {
    try {
      axios
        .get<ListTallyCompanyResponse>("/api/ListTallyCompanyByAdmin", {
          params: {
            accountId: username,
          },
        })
        .then((response) => {
          setTallyCompanies(response.data.tallyCompanies);
          setIntegrated(true);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyCompanyByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyCompanyByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const getTallyMetricsByAdmin = (id: any) => {
    try {
      axios
        .get<DefaultResponse & { workbook: string }>("/api/GetTallyMetrics", {
          params: {
            accountId: username,
            companyId: id,
            // need to add dropdown for usecase
            configUseCaseType: ConfigUseCaseType.recon,
          },
        })
        .then((response) => {
          setQcMetricsFile(response.data.workbook);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getTallySyncError = () => {
    try {
      axios
        .get<{ errors: { [k: string]: string }[] }>("/api/ListTallyErrors", {
          params: {
            accountId: username,
            companyId: companyId.current,
          },
        })
        .then((response) => {
          setRowsTallySyncError(response.data.errors);
          setRowsFTallySyncError(response.data.errors);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyErrors`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyErrors`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const optionsListTallyCompanyByAdmin = tallyCompanies?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const companyNameSelect = (e: any, option: any) => {
    if (option !== null) {
      e.persist();
      getTallyLedger(option.companyId);
      companyId.current = option.companyId;
      getTallyMetricsByAdmin(option.companyId);
    } else if (option === null) {
      setRowsData([]);
      companyId.current = null;
    }
  };

  const qcMetricsDownloadFile = () => {
    const excelData = Buffer.from(qcMetricsFile, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Tally connector- Install QC Metrics.xlsx");
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Tally Ledger")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid className="pb_40">
        <Grid className="center_align" sx={{ gap: 8, flexWrap: "wrap" }}>
          <Card className="center_align tallyVoucher_card">
            <div>
              <div className="vertical_center_align">
                <TextField
                  id="outlined-number"
                  label="Account Id"
                  // type="number"
                  value={username}
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                    clearData();
                    setShowTallySyncProgress(false);
                  }}
                />
                <Button
                  variant="contained"
                  className="theme_btn tallyVoucher_submit_btn"
                  onClick={() => getTallyCompanyByAdmin()}
                >
                  Submit
                </Button>
              </div>
              {tallyCompanies.length > 0 && (
                <div className="mt_20">
                  <div className="center_align_ver_horiz h_40 text_center">
                    <label>Select Tally Company</label>
                  </div>
                  <Autocomplete
                    className="dropdown_Tally"
                    onChange={(e, value) => companyNameSelect(e, value)}
                    id="businessPartner"
                    options={optionsListTallyCompanyByAdmin.sort(
                      (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option: any) => option.firstLetter}
                    getOptionLabel={(option: any) => option.companyName}
                    renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                  />
                </div>
              )}
            </div>
          </Card>
          {integrated ? (
            <VouchersCard
              username={username}
              setApiErrorMsg={setApiErrorMsg}
              setShowErrorAlert={setShowErrorAlert}
              setShowSuccessAlert={setShowSuccessAlert}
            />
          ) : (
            <></>
          )}
          {companyId.current ? (
            <VouchersCard
              key={companyId.current}
              username={username}
              companyId={companyId.current}
              setApiErrorMsg={setApiErrorMsg}
              setShowErrorAlert={setShowErrorAlert}
              setShowSuccessAlert={setShowSuccessAlert}
            />
          ) : (
            <></>
          )}
        </Grid>

        {rowsData.length > 0 ? (
          <Grid>
            <Grid className="center_align m_25">
              {showTallySyncProgress ? (
                <Button
                  onClick={() => {
                    setShowTallySyncProgress(false);
                  }}
                  className="theme_outline_btn ml_20"
                >
                  Back to Tally Ledger
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      qcMetricsDownloadFile();
                    }}
                    className="theme_btn"
                  >
                    Download
                  </Button>
                  <Button
                    onClick={() => {
                      getTallySyncProgress(companyId?.current);
                    }}
                    className="theme_btn ml_20"
                  >
                    Tally Sync Progress
                  </Button>
                </>
              )}
            </Grid>
            {showTallySyncProgress ? (
              <Grid>
                <Grid className="text_center m_25">
                  <p>
                    Total ledgers available in TALLY ERP : {summaryOfTallySyncProgress?.totalLedgersComingFromTally}{" "}
                  </p>
                  <p>
                    Total ledgers available in TALLY Connector :{" "}
                    {summaryOfTallySyncProgress?.totalLedgersComingFromTallyConnector}{" "}
                  </p>
                  <p>
                    Total ledgers added till now in NAKAD server : {summaryOfTallySyncProgress?.totalLedgersSyncedInDb}
                  </p>
                  <p>Ledger to be added in NAKAD server : {summaryOfTallySyncProgress?.ledgersToBeSynced}</p>
                  <p>
                    Total Vouchers available in TALLY ERP : {summaryOfTallySyncProgress?.totalVouchersComingFromTally}
                  </p>
                  <p>
                    Total Vouchers available in TALLY Connector :{" "}
                    {summaryOfTallySyncProgress?.totalVouchersComingFromTallyConnector}
                  </p>
                  <p>
                    Total Vouchers added till now in NAKAD server :{" "}
                    {summaryOfTallySyncProgress?.totalVouchersSyncedInDb}
                  </p>
                  <p>Vouchers to be added in NAKAD : {summaryOfTallySyncProgress?.vouchersToBeSynced}</p>
                  <br />
                  <p>
                    <span>Error Vouchers: {rowsTallySyncError.filter((d) => d.typeOfObject === "Voucher").length}</span>
                    <Button
                      className="theme_btn ml_20"
                      size="small"
                      onClick={() => {
                        setRowsFTallySyncError(rowsTallySyncError.filter((d) => d.typeOfObject === "Voucher"));
                        setOpenSyncError(true);
                      }}
                    >
                      View
                    </Button>
                    <Button className="theme_btn ml_20" size="small" onClick={writeToExcel}>
                      Download
                    </Button>
                  </p>
                  <br />
                  <p>
                    <span>Error Ledgers: {rowsTallySyncError.filter((d) => d.typeOfObject === "Ledger").length}</span>
                    <Button
                      className="theme_btn ml_20"
                      size="small"
                      onClick={() => {
                        setRowsFTallySyncError(rowsTallySyncError.filter((d) => d.typeOfObject === "Ledger"));
                        setOpenSyncError(true);
                      }}
                    >
                      View
                    </Button>
                    <Button className="theme_btn ml_20" size="small" onClick={writeToExcel}>
                      Download
                    </Button>
                  </p>
                </Grid>
                <Grid className="center_align tallyVoucher_table">
                  <Table
                    columns={columnDefinitionTallySyncProgress}
                    rows={summaryOfTallySyncProgress?.voucherTypeWiseVouchersCount as any}
                    sortEnable={true}
                    showPagination={true}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <Grid className="center_align m_25">
                  User - {name} , Total Ledgers - {totalLedgers}
                </Grid>
                <Grid className="center_align tallyVoucher_table">
                  <Table columns={columnDefinition} rows={rowsData} sortEnable={true} showPagination={true} />
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : null}
      </Grid>
      <Dialog open={openSyncError} onClose={() => setOpenSyncError(false)} maxWidth="md" fullWidth={true}>
        <DialogTitle sx={{ m: 0, p: 2 }} className="dialog_header">
          Tally Sync Error Details
          <IconButton
            aria-label="close"
            onClick={() => setOpenSyncError(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: "16px !important" }}>
          <Table columns={columnDefinitionTallySyncError} rows={rowsFTallySyncError} sortEnable={true} />
        </DialogContent>
      </Dialog>
    </LoggedInSkeleton>
  );
};

const PasteFromClipboard = async () => {
  const clipBoard = navigator.clipboard;
  try {
    const data = await clipBoard.readText();
    return data;
  } catch (error) {
    return null;
  }
};

interface VoucherProp {
  username: string;
  companyId?: string;
  setShowErrorAlert: Dispatch<SetStateAction<boolean>>;
  setShowSuccessAlert: Dispatch<SetStateAction<boolean>>;
  setApiErrorMsg: Dispatch<SetStateAction<string>>;
}
const VouchersCard = ({ username, companyId, ...props }: VoucherProp) => {
  const [voucherRaw, setVoucherRaw] = useState<string>("");
  const [previewVouchers, setPreviewVouchers] = useState<string[]>([]);
  const [acceptedVouchers, setAcceptedVouchers] = useState<string[]>([]);

  const ListTallyAcceptedVoucherTypes = () => {
    try {
      axios
        .get<{ acceptedVoucherTypes: string[]; message: string }>("/api/ListTallyAcceptedVoucherTypesByAdmin", {
          params: {
            accountId: username,
            companyId: companyId || undefined,
          },
        })
        .then((response) => {
          setAcceptedVouchers(response.data.acceptedVoucherTypes || []);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };

  const UpdateTallyAcceptedVoucherTypes = () => {
    try {
      axios
        .post<{ message: string; response: string }>("/api/PostAcceptedTallyVoucherTypes", {
          accountId: username,
          companyId: companyId || undefined,
          voucherTypes: previewVouchers,
        })
        .then((response) => {
          ListTallyAcceptedVoucherTypes();
          props.setShowSuccessAlert(true);
          props.setApiErrorMsg(response.data.message || response.data.response);
          apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}GetTallyMetrics`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };

  // eslint-disable-next-line
  useEffect(() => ListTallyAcceptedVoucherTypes(), [username, companyId]);

  return (
    <Card className="center_align tallyVoucher_card" sx={{ flexFlow: "column", animation: "fade-in 0.5s ease-out" }}>
      <div className="vertical_center_align">
        <TextField
          label={companyId ? "Company Vouchers" : "Add Vouchers"}
          size="small"
          multiline={true}
          maxRows={4}
          value={voucherRaw}
          onChange={(e) => {
            setVoucherRaw(e.target.value);
            const datas = e.target.value.split(/\n/).filter((d) => d.trim());
            const data = Array.from<string>(new Set(datas));
            setPreviewVouchers(data);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="end"
                  title="Paste from clipboard"
                  onClick={async () => {
                    const str = await PasteFromClipboard();
                    setVoucherRaw(str);
                    const datas = str.split(/\n/).filter((d) => d.trim());
                    const data = Array.from<string>(new Set(datas));
                    setPreviewVouchers(data);
                  }}
                >
                  <ContentPaste />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          className="theme_btn tallyVoucher_submit_btn"
          onClick={UpdateTallyAcceptedVoucherTypes}
        >
          Add
        </Button>
      </div>
      <InputLabel sx={{ mt: 1 }}>Preview: </InputLabel>
      <ItemGrid>
        {previewVouchers.length ? (
          previewVouchers.map((voucher, i) => <Item key={voucher + i}>{voucher}</Item>)
        ) : (
          <em style={{ display: "flex", gridColumn: "1 / 4", justifyContent: "center" }}>Nothing to Preview.</em>
        )}
      </ItemGrid>
      <Divider orientation="horizontal" sx={{ width: "100%", height: "1px", mt: 1, border: "none" }} />
      <InputLabel sx={{ mt: 1 }}>Accepted Vouchers: </InputLabel>
      <ItemGrid>
        {acceptedVouchers.length ? (
          acceptedVouchers.map((voucher) => <Item key={voucher}>{voucher}</Item>)
        ) : (
          <em style={{ display: "flex", gridColumn: "1 / 4", justifyContent: "center" }}>Nothing to Display.</em>
        )}
      </ItemGrid>
    </Card>
  );
};

export default TallyLedger;
