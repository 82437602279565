import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import React, { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
export interface DialogProps {
  open: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  width70Per?: boolean;
  configWidth?: boolean;
  height70Per?: boolean;
  children?: React.ReactNode;
}

export const Dialog = (props: React.PropsWithChildren<DialogProps>) => {
  const renderDialog = () => {
    if (props.open) {
      // console.log(props.open);
      return (
        <div className="modal shown" style={{ display: "flex" }}>
          <div className="modal-background" onClick={props.onClose} />
          <div
            className="modal-card"
            style={{
              width: props.configWidth ? "1018px" : props.width70Per ? "70vw" : "655px",
              height: props.height70Per ? "70vh" : null,
            }}
          >
            {props.children}
          </div>
        </div>
      );
    }
  };
  // console.log(props.open);

  return props.open ? ReactDOM.createPortal(renderDialog(), document.body) : null;
};
