import {
  Autocomplete,
  Button,
  createFilterOptions,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import TabPanel from "../NewBusinessPartner/TabPanel";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../PartnerCommunication/CommonComponents";

const filter = createFilterOptions<any>();

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface AddNewBpProps {
  getListAllDesignation: () => void;
  getPartnerUserBulkUploadFailedList?: () => void;
  listAllBusinessPartnersForPartnerUseCaseMaster: () => void;
  listAllBusinessPartnersForPartnerUserMaster: () => void;
  listAllDesignation: any[];
  partnerUsersTemplateBase64: string;
  rowsDataPartnerUseCaseMaster: any[];
  setApiErrorMsg: StateDispatch<string>;
  setErrorListsPartnerUsersBase64: StateDispatch<string>;
  setLoader: StateDispatch<boolean>;
  setNumberOfUsersFailed: StateDispatch<number>;
  setNumberOfUsersSaved: StateDispatch<number>;
  setOpenPartnerUserBulkUploadErrorReport: StateDispatch<boolean>;
  setRowsDataPartnerUserBulkUploadErrorReport: StateDispatch<any[]>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}

const AddNewPartnerUser = (props: AddNewBpProps) => {
  const [openBusinessPopup, setOpenBusinessPopup] = useState<boolean>(false);
  const [tabvalue, setTabValue] = useState(0);
  const optionsUseCase = ["to", "cc", "bcc", "NA"];
  const [ledgerRequestSelected, setLedgerRequestSelected] = useState<string>("to");
  const [balanceConfirmationSelected, setBalanceConfirmationSelected] = useState<string>("to");
  const [paymentAdviceSelected, setPaymentAdviceSelected] = useState<string>("to");
  const [msmeConfirmationSelected, setMsmeConfirmationSelected] = useState<string>("to");

  const [name, setName] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errEmail, setErrEmail] = useState<boolean>(false);
  const [businessPartner, setBusinessPartners] = useState<number>(null);
  const [errBusinessPartner, setErrBusinessPartner] = useState<boolean>(false);
  const [errOptionsUseCase, setErrOptionsUseCase] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const { actor } = useContext(userContext);
  const [savingPartnerUser, setSavingPartnerUser] = useState(false);

  useEffect(() => {
    setValue({
      actorId: actor.id,
      designationId: null,
      designationName: designation,
      ledgerRequest: ledgerRequestSelected,
      balanceConfirmation: balanceConfirmationSelected,
      paymentAdvice: paymentAdviceSelected,
      msmeConfirmation: msmeConfirmationSelected,
    });
  }, []);
  const handleClickOpen = () => {
    setOpenBusinessPopup(true);
  };

  const clearAllState = () => {
    setName("");
    setEmail("");
    setBusinessPartners(null);
    setValue(null);
    setDesignation("");
    setLedgerRequestSelected("to");
    setBalanceConfirmationSelected("to");
    setPaymentAdviceSelected("to");
    setMsmeConfirmationSelected("to");
    setErrEmail(false);
    setErrBusinessPartner(false);
    setErrOptionsUseCase(false);
  };

  const handleClose = () => {
    setOpenBusinessPopup(false);
    clearAllState();
  };
  const handleChange = (_, newValue: number) => {
    setTabValue(newValue);
  };

  const addDesignations = () => {
    try {
      Axios.post<any>("/api/AddDesignations", {
        designations: [...props.listAllDesignation, value],
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      })
        .then(() => {
          props.getListAllDesignation();

          // props.setShowSuccessAlert(true);
          // props.setApiErrorMsg(response.data.message);
          // apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}AddDesignation`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}AddDesignation`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };

  const addNewPartnerUser = async () => {
    if (!email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
      setErrEmail(true);
    }

    if (businessPartner === null) {
      setErrBusinessPartner(true);
    }

    let emailSettingErr = false;
    if (ledgerRequestSelected === "NA" && balanceConfirmationSelected === "NA" && paymentAdviceSelected === "NA") {
      emailSettingErr = true;
      setErrOptionsUseCase(true);
    }

    if (
      email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$") &&
      businessPartner !== null &&
      !emailSettingErr
    ) {
      try {
        setSavingPartnerUser(true);
        await Axios.post<any>("/api/AddBusinessPartnerUserWithEmailSetting", {
          businessPartnerId: businessPartner,
          contact: {
            name,
            email,
            designationName: designation,
            ledgerRequest: ledgerRequestSelected === null ? "NA" : ledgerRequestSelected,
            balanceConfirmation: balanceConfirmationSelected === null ? "NA" : balanceConfirmationSelected,
            paymentAdvice: paymentAdviceSelected === null ? "NA" : paymentAdviceSelected,
            msmeConfirmation: msmeConfirmationSelected === null ? "NA" : msmeConfirmationSelected,
          },
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        })
          .then((response) => {
            setSavingPartnerUser(false);
            handleClose();
            props.setLoader(true);
            props.listAllBusinessPartnersForPartnerUserMaster();
            props.listAllBusinessPartnersForPartnerUseCaseMaster();

            if (props.listAllDesignation?.find((e: any) => e.designationName?.trim() === designation?.trim())) {
              // duplicate name present
              props.getListAllDesignation();
            } else {
              addDesignations();
            }

            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            setSavingPartnerUser(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUserWithEmailSetting`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        setSavingPartnerUser(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUserWithEmailSetting`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const downloadPartnerUsersTemplate = () => {
    const excelData = Buffer.from(props.partnerUsersTemplateBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Partner User addition template.xlsx");
  };

  const addBusinessPartnerUsersInBulk = (e: any) => {
    const reader = new FileReader();
    let data = null;
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (event) => {
      data = event.target.result;

      try {
        Axios.post<any>("/api/AddBusinessPartnerUsersInBulk", {
          businessPartnerUserDataBase64: data
            .toString()
            .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        })
          .then((response) => {
            handleClose();
            props.setLoader(true);
            props.listAllBusinessPartnersForPartnerUserMaster();
            props.listAllBusinessPartnersForPartnerUseCaseMaster();
            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);

            // failed entries ---- case -> modal
            if (response.data.response.numberOfUsersFailed > 0) {
              props.setRowsDataPartnerUserBulkUploadErrorReport(response.data.response.listOfFailedUsers);
              props.setNumberOfUsersFailed(response.data.response.numberOfUsersFailed);
              props.setNumberOfUsersSaved(response.data.response.numberOfUsersSaved);
              // props.getPartnerUserBulkUploadFailedList();
              props.setErrorListsPartnerUsersBase64(response.data.response.finalFailedListWorkbookBase64);
              props.setOpenPartnerUserBulkUploadErrorReport(true);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUsersInBulk`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUsersInBulk`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    };
  };

  const businessPartners: any[] = [];
  props.rowsDataPartnerUseCaseMaster?.forEach((valueData: any) => {
    businessPartners.push({
      name: valueData.businessPartnerName,
      id: valueData.businessPartnerId,
    });
  });
  const options = businessPartners?.map((option) => {
    const firstLetter = option.name[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const businessPartnerSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      setBusinessPartners(valueData.id);
      setErrBusinessPartner(false);
    } else {
      setBusinessPartners(null);
    }
  };
  const ledgerRequestSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      setLedgerRequestSelected(valueData);
      value.ledgerRequest = valueData;
      setErrOptionsUseCase(false);
    } else {
      value.ledgerRequest = null;
      setLedgerRequestSelected(null);
    }
  };
  const balanceConfirmationSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      value.balanceConfirmation = valueData;
      setBalanceConfirmationSelected(valueData);
      setErrOptionsUseCase(false);
    } else {
      value.balanceConfirmation = null;
      setBalanceConfirmationSelected(null);
    }
  };
  const paymentAdviceSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      value.paymentAdvice = valueData;
      setPaymentAdviceSelected(valueData);
      setErrOptionsUseCase(false);
    } else {
      value.paymentAdvice = null;
      setPaymentAdviceSelected(null);
    }
  };
  const msmeConfirmationSelect = (e: React.SyntheticEvent<Element, Event>, valueData: string) => {
    if (valueData !== null) {
      e.persist();
      value.msmeConfirmation = valueData;
      setMsmeConfirmationSelected(valueData);
      setErrOptionsUseCase(false);
    } else {
      value.msmeConfirmation = null;
      setMsmeConfirmationSelected(null);
    }
  };

  const changeDesignation = (_, newValue: any) => {
    if (typeof newValue === "string") {
      setValue({
        actorId: actor.id,
        designationId: null,
        designationName: newValue,
        ledgerRequest: ledgerRequestSelected,
        balanceConfirmation: balanceConfirmationSelected,
        paymentAdvice: paymentAdviceSelected,
        msmeConfirmation: msmeConfirmationSelected,
      });

      setDesignation(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        actorId: actor.id,
        designationId: null,
        designationName: newValue.inputValue,
        ledgerRequest: ledgerRequestSelected,
        balanceConfirmation: balanceConfirmationSelected,
        paymentAdvice: paymentAdviceSelected,
        msmeConfirmation: msmeConfirmationSelected,
      });
      setDesignation(newValue?.inputValue);
    } else {
      setValue(newValue);
      setDesignation(newValue?.designationName);
      setLedgerRequestSelected(newValue?.ledgerRequest === null ? "NA" : newValue?.ledgerRequest);
      setBalanceConfirmationSelected(newValue?.balanceConfirmation === null ? "NA" : newValue?.balanceConfirmation);
      setPaymentAdviceSelected(newValue?.paymentAdvice === null ? "NA" : newValue?.paymentAdvice);
      setMsmeConfirmationSelected(newValue?.msmeConfirmation === null ? "NA" : newValue?.msmeConfirmation);
    }
  };

  return (
    <>
      <MenuItem
        className=""
        onClick={() => {
          handleClickOpen();
        }}
      >
        Add Partner User
      </MenuItem>

      <Dialog open={openBusinessPopup}>
        <>
          <header className="modal-card-head">
            <div className="modal-card-title">
              <Tabs value={tabvalue} onChange={handleChange} aria-label="Business Partner" className="business_tabs">
                <Tab label="Add Partner User" />
                <Tab label="Bulk Upload Partner User" />
              </Tabs>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
            />
          </header>
          <section className="modal-card-body">
            <TabPanel value={tabvalue} index={0}>
              <Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Name : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth={true}
                      label="Name"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Email ID : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <TextField
                      size="small"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      label="Email"
                      name="email"
                      error={errEmail}
                      helperText={errEmail ? "please enter valid email" : ""}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value.length > 0) {
                          if (!e.target.value.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                            setErrEmail(true);
                          } else {
                            setErrEmail(false);
                          }
                        } else {
                          setErrEmail(false);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Business partner : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => businessPartnerSelect(e, valueData)}
                      id="businessPartnerAddBpUser"
                      size="small"
                      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="Select Business partner"
                          error={errBusinessPartner}
                          helperText={errBusinessPartner ? "please select business partner" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Designation : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    {/* <TextField
                      size="small"
                      variant="outlined"
                      fullWidth={true}
                      label="Designation"
                      name="designation"
                      value={designation}
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                    /> */}

                    <Autocomplete
                      size="small"
                      fullWidth={true}
                      value={value}
                      onChange={(event, newValue) => {
                        changeDesignation(event, newValue);
                      }}
                      filterOptions={(optionsFilter, params) => {
                        const filtered = filter(optionsFilter, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionsFilter.some((option) => inputValue === option.designationName);
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            designationName: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus={true}
                      clearOnBlur={true}
                      handleHomeEndKeys={true}
                      id="free-solo-with-text-demo"
                      options={props.listAllDesignation}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.designationName;
                      }}
                      renderOption={(propsA, option) => <li {...propsA}>{option.designationName}</li>}
                      // sx={{ width: 300 }}
                      freeSolo={true}
                      renderInput={(params) => <TextField {...params} label="Designation" />}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Ledger request : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => ledgerRequestSelect(e, valueData)}
                      id="ledgerRequestSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={ledgerRequestSelected}
                      value={ledgerRequestSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Balance confirmation : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => balanceConfirmationSelect(e, valueData)}
                      id="balanceConfirmationSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={balanceConfirmationSelected}
                      value={balanceConfirmationSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Payment advice : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => paymentAdviceSelect(e, valueData)}
                      id="paymentAdviceSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={paymentAdviceSelected}
                      value={paymentAdviceSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Msme confirmation : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => msmeConfirmationSelect(e, valueData)}
                      id="msmeConfirmationSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={msmeConfirmationSelected}
                      value={msmeConfirmationSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabvalue} index={1}>
              <Grid>
                <div>
                  <label
                    id="file-js-example"
                    className="field file has-name uploadSection center_align_ver_horiz"
                    style={{ marginBottom: "0" }}
                  >
                    <input
                      className="file-input"
                      type="file"
                      name="invoicefile"
                      value={""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => addBusinessPartnerUsersInBulk(e)}
                      required={true}
                      multiple={true}
                      accept=".xlsx"
                    />
                    <div>
                      <div className="center_align m_20">
                        <span className="file-icon">
                          <i className="fas fa-upload" style={{ fontSize: "25px" }} />
                        </span>
                      </div>
                      <div>
                        <span
                          className="file-cta closingblc_input businesspartner_btn theme_btn"
                          style={{
                            minWidth: "300px",
                            justifyContent: "center",
                          }}
                        >
                          <span className="file-label">Upload Business Partners</span>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </Grid>
            </TabPanel>
          </section>
          <footer className="modal-card-foot space_between">
            {tabvalue === 0 && (
              <>
                <div className="space_between">
                  <Button
                    className="theme_outline_btn"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    className="theme_btn"
                    onClick={() => {
                      addNewPartnerUser();
                    }}
                    startIcon={<LoadingIcon loading={savingPartnerUser} />}
                    disabled={savingPartnerUser}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {tabvalue === 1 && (
              <Tooltip
                title="No Data Available to Download. Verify Company name is Selected."
                arrow={true}
                open={!props.partnerUsersTemplateBase64}
                placement="top"
              >
                <Button
                  disabled={!props.partnerUsersTemplateBase64}
                  onClick={() => {
                    downloadPartnerUsersTemplate();
                  }}
                  className="theme_btn"
                >
                  Download Format
                </Button>
              </Tooltip>
            )}
          </footer>
        </>
      </Dialog>
    </>
  );
};

export default AddNewPartnerUser;
