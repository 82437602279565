import axios from "axios";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../Components/Recon360/PartnerCommunication/CommonComponents";

interface DefaultRes {
  message?: string;
  response?: string;
}

export const uiLogger = (eventName: string, companyId?: string, branchCode?: string, others?: Record<string, any>) => {
  axios.post<DefaultRes>("/api/uiLogger", {
    functionName: eventName,
    companyId: companyId || DEFAULT_COMPANY_ID,
    branchCode: branchCode || DEFAULT_BRANCH_CODE,
    ...others,
  });
};

export const uiLoggerPortal = (
  eventName: string,
  data: string,
  companyId?: string,
  branchCode?: string,
  others?: Record<string, any>
) => {
  axios.post<DefaultRes>("/api/UiPortalLogger", {
    functionName: eventName,
    data,
    companyId: companyId || DEFAULT_COMPANY_ID,
    branchCode: branchCode || DEFAULT_BRANCH_CODE,
    ...others,
  });
};

export default uiLogger;
