import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import LoadingIcon from "../../Common/LoadingIcon";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { Recon360Context } from "../Recon360";

const ManualChangesConfirmModal = () => {
  const {
    openManualChangesConfirmModal,
    setOpenManualChangesConfirmModal,
    getManuallyChangedEntries,
    businessPartnerSelected,
    businessPartnerSelectedCompanyIdRef,
    companyId,
    branchCode,
    setShowUploadErrorAlert,
    setApiErrorMsg,
    setShowUploadSuccessAlert,
    checkboxDataManualRecon,
    setCheckboxDataManualRecon,
    getSummaryForReconciliationCommonFunction,
  } = useContext(Recon360Context);
  const [showLoadingIconApplyBtn, setShowLoadingIconApplyBtn] = useState(false);
  // const [showLoadingIconDontApplyBtn, setShowLoadingIconDontApplyBtn] = useState(true);
  // const [showLoadingIconViewBtn, setShowLoadingIconViewBtn] = useState(true);

  const closeManualChangesConfirmModal = () => {
    setOpenManualChangesConfirmModal(false);
    clearManualChangesData();
  };
  const clearManualChangesData = () => {
    setCheckboxDataManualRecon([]);
  };
  const preAppliedChanges = async (changes) => {
    setShowLoadingIconApplyBtn(true);
    try {
      await Axios.post("/api/PreAppliedChanges", {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        changes,
      })
        .then((response) => {
          setShowLoadingIconApplyBtn(false);
          closeManualChangesConfirmModal();
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response?.data?.message);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          getSummaryForReconciliationCommonFunction();
        })
        .catch((error) => {
          console.log("error PreAppliedChanges", error?.response);
          setShowLoadingIconApplyBtn(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}PreAppliedChanges`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error PreAppliedChanges", error?.response);
      setShowLoadingIconApplyBtn(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}PreAppliedChanges`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <div>
      <MuiDialog open={openManualChangesConfirmModal} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Manual changes?</DialogTitle>
          <IconButton
            onClick={() => {
              closeManualChangesConfirmModal();
              getSummaryForReconciliationCommonFunction();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <FormLabel>Do you want to apply the manual changes?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button
            className="theme_btn"
            startIcon={<LoadingIcon loading={showLoadingIconApplyBtn} />}
            disabled={showLoadingIconApplyBtn}
            size="small"
            sx={{
              ".MuiButton-startIcon": {
                display: "inherit",
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              preAppliedChanges(checkboxDataManualRecon);
            }}
          >
            Apply
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            disabled={showLoadingIconApplyBtn}
            size="small"
            onClick={() => {
              closeManualChangesConfirmModal();
              getSummaryForReconciliationCommonFunction();
            }}
          >
            Don't Apply
          </Button>
          <Button
            className="theme_outline_btn"
            disabled={showLoadingIconApplyBtn}
            size="small"
            onClick={() => {
              closeManualChangesConfirmModal();
              getManuallyChangedEntries();
            }}
          >
            View
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ManualChangesConfirmModal;
