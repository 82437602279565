import { Cached, Download, FilterAlt, FilterAltOff, Visibility } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  createFilterOptions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { Currency } from "dinero.js";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import IndeterminateCheckbox from "src/Components/Admin/IndeterminateCheckbox";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { useFetch2 as useFetch } from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import BpDashboardTable from "src/Components/ReactTable/BpDashboardTable";
import {
  BusinessPartnersListForBcBetaHistory,
  BusinessPartnersListForBcBetaHistoryRes,
  MailThread,
  MsmeMailThread,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import { Currencies } from "src/Utils/Common/Constants";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../CommonComponents";
import MailThreadDialog from "../MailThreadDialog";
import { BcBetaFileType, getBlankData } from "./BalanceConfirmationBeta";
import BalanceConfirmationBetaFilter from "./BalanceConfirmationBetaFilter";

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

const filter = createFilterOptions<any>();

const BalanceConfirmationHistory = ({
  companyId,
  branchCode,
  setOpen,
}: {
  companyId: string;
  branchCode: string;
  setOpen: (val: boolean) => void;
}) => {
  const { actor } = useContext(userContext);

  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);

  const [loader, setLoader] = useState<boolean>(true);
  const [, setSelectedRow] = useState<BusinessPartnersListForBcBetaHistory[]>([]);
  const [rowsDataBcBeta, setRowsDataBcBeta] = useState<BusinessPartnersListForBcBetaHistory[]>(getBlankData());
  const [storeRowsDataBcBeta, setStoreRowsDataBcBeta] = useState<BusinessPartnersListForBcBetaHistory[]>([]);

  const storeAllCategories = useRef<string[]>([]);
  const storeBcBetaData = useRef<BusinessPartnersListForBcBetaHistory[]>([]);
  const [isFilterAppliedBcBeta, setIsFilterAppliedBcBeta] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [, setAppliedFilters] = useState<{ [key: string]: string }>({});

  const [openBcBetaFilter, setOpenBcBetaFilter] = useState<boolean>(false);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [mailLoading, setMailLoading] = useState(false);

  const columnDefinitionBcBeta = useMemo(
    (): ColDef<BusinessPartnersListForBcBetaHistory>[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        size: 35,
      },
      {
        header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a>{row.original.businessPartnerName}</a>
          </div>
        ),
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode,
        enableSorting: true,
        size: 130,
      },
      {
        header: "Email Initiation Date",
        accessorFn: (row) => row.emailInitiationDate,
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original.emailInitiationDate?.toString()}
              variant="outlined"
              disabled={true}
              type="text"
              size="small"
              value={moment(row.original.emailInitiationDate)?.format("YYYY-MM-DD")}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="vertical_center_align" style={{ gap: 8 }}>
            Balance Confirmation Status
          </div>
        ),
        id: "Status",
        accessorFn: (row) => row.status,
        size: 300,
        cell: ({ row }) => (
          <>
            {row.original.status ? (
              <div className="vertical_center_align gap_10">
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: BalanceConfirmationBetaColorMap[row.original.status]?.color || "#000",
                    backgroundColor: BalanceConfirmationBetaColorMap[row.original.status]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.status === BalanceConfirmationBetaStatuses.ReminderSent
                    ? row.original.balanceConfirmationStatusWithReminderCount || row.original.status
                    : row.original.status}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase(),
        size: 200,
        cell: ({ row }) => (
          <div className="category_ui">
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(optionsFilt, params) => {
                console.log(optionsFilt);
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Closing Balance date",
        accessorFn: (row) => row.closingBalanceDate,
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.closingBalanceDate?.toString()}
              variant="outlined"
              disabled={true}
              type="text"
              size="small"
              value={moment(row.original.closingBalanceDate)?.format("YYYY-MM-DD")}
            />
          </div>
        ),
      },
      {
        header: "Closing Balance Own",
        accessorFn: (row) => row.ownClosingBalance,
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.ownClosingBalance}
              returnNull={true}
              value={
                !isNaN(parseFloat(row.original.ownClosingBalance?.toString()))
                  ? parseFloat(row.original.ownClosingBalance?.toString())
                  : row.original.ownClosingBalance
              }
              // placeholder={row.original.ownClosingBalance as string}
              setValue={(value) => {
                if (row.original.ownClosingBalance === null && value === null) return;
              }}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                    {Currencies.find((cur) => cur.code === row.original.currency)?.code || DefaultCurrency.INR}
                  </InputAdornment>
                ),
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
              currency={(row.original.currency as Currency) || "INR"}
            />
          </div>
        ),
      },
      {
        header: "CB Partner",
        accessorFn: (row) => row.businessPartnerClosingBalance,
        enableSorting: true,
        size: 150,
        cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      row.original.businessPartnerClosingBalance,
                      (row.original.currency as Currency) || "INR"
                    )
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "CB difference",
        accessorFn: (row) => row.closingBalanceDifference,
        enableSorting: true,
        size: 150,
        cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.closingBalanceDifference === null
                ? null
                : formatMoney(
                    ToDineroObj(row.original.closingBalanceDifference, (row.original.currency as Currency) || "INR")
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "Balance Confirmed",
        accessorFn: (row) => row.statusOverview,
        size: 180,
      },
      {
        header: "Balance Confirm document",
        accessorFn: (row) => row.signedPdf,
        size: 260,
        cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.signedPdf ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() =>
                  downloadUploadedFile(
                    row.original.businessPartnerId,
                    BcBetaFileType.confirmationDocument,
                    row.original.newMailInitiationCount
                  )
                }
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Partner Ledger",
        accessorFn: (row) => row.ledgers,
        size: 220,
        cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.ledgers ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() =>
                  downloadUploadedFile(
                    row.original.businessPartnerId,
                    BcBetaFileType.ledgers,
                    row.original.newMailInitiationCount
                  )
                }
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Actions",
        accessorKey: "businessPartnerId",
        enableSorting: false,
        size: 160,
        sticky: "right",
        cell: ({ row }) => (
          <Stack direction="row" gap={1} alignItems="center" position="relative" top={-1}>
            {/* View Email Button */}
            <Tooltip title={"View Email"} arrow={true}>
              <IconButton
                onClick={async () => {
                  setMailLoading(true);
                  setOpenMailThread(true);
                  setMailThreads([]);
                  setBpName(row.original.businessPartnerName);
                  const { emails } = await viewEmail(
                    row.original.businessPartnerId,
                    row.original.newMailInitiationCount
                  );
                  setMailLoading(false);
                  setMailThreads(emails || []);
                }}
              >
                <Visibility sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
            {/* View Recon */}
            <Tooltip title={"View Recon"} arrow={true}>
              <IconButton
                onClick={() => {
                  const { businessPartnerId, businessPartnerName } = row.original;
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank").focus();
                }}
              >
                <Cached sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history]
  );

  useEffect(() => {
    ListAllBusinessPartnerForBcBeta();
  }, []);

  // Main ROWs API
  const ListAllBusinessPartnerForBcBeta = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForBcBetaHistoryRes>(
      "/api/BalanceConfirmationPortal/ListHistoryOfAllBusinessPartnersForBalanceConfirmation",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          // Extract the businessPartnerId values and store them in a new array
          if (!response.data?.businessPartnerList)
            response.data = { businessPartnerList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
          storeBcBetaData.current = response.data?.businessPartnerList || [];
          storeAllCategories.current = response.data?.allCategories || [];
          setRowsDataBcBeta(response.data.businessPartnerList || []);
          setStoreRowsDataBcBeta(response.data.businessPartnerList || []);
          setLoader(false);
          // if (Object.keys(appliedFilters).length) setReFilter(true);
        },
      }
    );
  };

  const getDownloadBcBetaReport = () => {
    useFetch<{ base64string: string }>(
      "/api/BalanceConfirmationPortal/DownloadExcelReportForBalanceConfirmationHistory",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          // setDownloadBcBetaReportBase64(response.data?.base64string);
          downloadBcBetaReport(response.data?.base64string);
        },
      }
    );
  };

  // Download BcBeta Rows Report
  const downloadBcBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Balance Confirmation Report.xlsx`);
  };

  // View Email Thread
  const viewEmail = (businessPartner: number, newMailInitiationCount: number) => {
    return new Promise<{ emails: MailThread[] }>((resolve, reject) => {
      useFetch<{ emailThreads: MsmeMailThread[] }>(
        "/api/BalanceConfirmationPortal/ViewEmailThreadForBalanceConfirmationHistory",
        "GET",
        {
          setApiErrorMsg,
          setShowErrorAlert,
          config: {
            params: {
              businessPartnerId: businessPartner,
              newMailInitiationCount: newMailInitiationCount,
              companyId: companyId || DEFAULT_COMPANY_ID,
              branchCode: branchCode || DEFAULT_BRANCH_CODE,
            },
          },
          thenCallBack: (response) => {
            if (response.data.emailThreads) {
              const { emailThreads } = response.data;
              const mailThreads: MailThread[] = emailThreads.map((email) => ({
                emailBody: email.body,
                fromEmailId: email.from,
                timestamp: email.emailTime?.toString(),
                attachments: [],
              }));

              resolve({ emails: mailThreads });
            }
          },
          catchCallBack: reject,
          errorCallback: reject,
        }
      );
    });
  };

  // View Rows Partner Uploaded Files
  const downloadUploadedFile = (_bpId: number, _fileType: BcBetaFileType, newMailInitiationCount: number) => {
    useFetch<{ signedUrls: string[] }>(
      "/api/BalanceConfirmationPortal/DownloadUploadedFilesForBalanceConfirmationHistory",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            businessPartnerId: _bpId,
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
            type: _fileType,
            newMailInitiationCount: newMailInitiationCount,
          },
        },
        thenCallBack: (_res) => {
          if (_res.data.signedUrls?.length > 0) {
            _res.data.signedUrls.forEach((url) => {
              const link = document.createElement("a");
              link.href = url;
              link.target = "_blank";
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
          }
        },
      }
    );
  };

  const GREEN = "#27B27C";

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {/* History Side Button */}
      <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
        {
          <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
            <Tooltip title={"Filter"} arrow={true}>
              <Avatar sx={{ background: GREEN }}>
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    onClick={() => setOpenBcBetaFilter(true)}
                    color="inherit"
                    sx={{
                      pointerEvents: "auto !important",
                    }}
                  >
                    {isFilterAppliedBcBeta ? <FilterAlt /> : <FilterAltOff />}
                  </IconButton>
                </span>
              </Avatar>
            </Tooltip>
            <Tooltip title="Download Balance Confirmation Report" arrow={true}>
              <Avatar sx={{ background: GREEN }}>
                <IconButton onClick={() => getDownloadBcBetaReport()} color="inherit">
                  <Download />
                </IconButton>
              </Avatar>
            </Tooltip>
          </div>
        }
      </div>

      {/* Title and Back Button */}
      <Box className="space_between" my={2}>
        <Typography variant="h6">Past Communications with Partners</Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: "32px",
            border: "1px solid #111",
            background: "#FFF",
          }}
          onClick={() => setOpen(false)}
        >
          Back
        </Button>
      </Box>
      <BpDashboardTable
        columns={columnDefinitionBcBeta}
        rows={rowsDataBcBeta}
        sortEnable={true}
        setSelectedRow={setSelectedRow}
        actorType="BPDashboard"
        loading={loader}
        truncateHeaders={true}
      />
      <BalanceConfirmationBetaFilter
        openBalanceConfirmationFilter={openBcBetaFilter}
        setOpenBalanceConfirmationFilter={setOpenBcBetaFilter}
        allCategories={storeAllCategories.current}
        rowsDataBalanceConfirmation={rowsDataBcBeta}
        setRowsDataBalanceConfirmation={setRowsDataBcBeta}
        storeRowsDataBalanceConfirmation={storeRowsDataBcBeta}
        setIsFilterAppliedBalanceConfirmation={setIsFilterAppliedBcBeta}
        reFilter={reFilter}
        setReFilter={setReFilter}
        setAppliedFilters={setAppliedFilters}
        historyConsumer={true}
      />
      <MailThreadDialog
        open={openMailThread}
        setOpen={setOpenMailThread}
        mailThreads={mailThreads}
        s3Attachments={[]}
        businessPartnerName={bpName}
        loading={mailLoading}
        msme={true}
        companyId={companyId}
        branchCode={branchCode}
        useCaseType={null}
        emailSubject={null}
        listAllBusinessPartnersWSR={null}
      />
    </>
  );
};

export default BalanceConfirmationHistory;
