import { FormControlLabel, Tooltip } from "@mui/material";
import React from "react";
import { Android12Switch } from "src/Components/Common/Loader";

interface PageSelectModeToggleProps {
  pageSelectMode: boolean;
  setPageSelectMode: (value: boolean) => void;
}

export const PageSelectModeToggle = ({ pageSelectMode, setPageSelectMode }: PageSelectModeToggleProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Tooltip
            title="When this toggle is enabled, use 'select all checkbox' to select/deselect all partners in the current page."
            arrow
          >
            <Android12Switch
              onChange={() => setPageSelectMode(!pageSelectMode)}
              onClick={(ev) => {
                (ev.target as HTMLInputElement).checked ? setPageSelectMode(false) : setPageSelectMode(true);
              }}
              checked={pageSelectMode}
            />
          </Tooltip>
        }
        label="Page Select Mode"
      />
    </>
  );
};
