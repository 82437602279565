import { AxiosError } from "axios";
import React, { ErrorInfo } from "react";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { LoadingPage } from "./PartnerPortal.common";

type MyProps = {
  [k: string]: any;
  children: React.ReactNode[] | React.ReactNode;
};

type MyState = {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo;
  apiErrorMsg: string;
  showErrorAlert: boolean;
};

export default class ErrorPage extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null, apiErrorMsg: "", showErrorAlert: false };
  }

  protected static getDerivedStateFromError(_error: string) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error and store in state

    this.setState((_state) => ({
      error,
      errorInfo,
    }));

    if ((error as AxiosError)?.isAxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError?.isAxiosError)
        this.setState({
          apiErrorMsg: axiosError.response?.data?.message || "",
          showErrorAlert: true,
        });

      setTimeout(() => {
        this.setState({
          apiErrorMsg: "",
          showErrorAlert: false,
        });
      }, 5000);
    }

    console.log(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      console.log("uh oh", this.state);
      // render any custom fallback UI

      return (
        <>
          <ApiErrorCatch apiErrorMsg={this.state.apiErrorMsg} showUploadErrorAlert={this.state.showErrorAlert} />
          <LoadingPage />
        </>
      );
    }

    return this.props.children;
  }
}
