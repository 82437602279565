import { Close, Mail } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, IconButton, Typography } from "@mui/material";
import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormLabel } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { EmailSendCount, MailThread, S3Attachment } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TallyOrSapCompanyId } from "./CommonComponents";
import ReplyEmail from "./ReplyEmail";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface MailThreadDialogProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  businessPartnerName: string;
  mailThreads: MailThread[];
  s3Attachments?: S3Attachment[];
  loading: boolean;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  useCaseType: "LR" | "CB";
  emailSubject: string;
  listAllBusinessPartnersWSR: () => Promise<void>;
  msme?: boolean;
}

const MailThreadDialog = (props: MailThreadDialogProps) => {
  const [showReplyBox, setShowReplyBox] = React.useState(false);
  const [cancelMail, setCancelMail] = React.useState(false);
  const [sendMail, setSendMail] = React.useState(false);

  const handleClose = () => {
    setShowReplyBox(false);
    setCancelMail(false);
    setSendMail(false);
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"} width={"95%"}>
          <Typography variant="body1">{props.businessPartnerName}</Typography>
          <Typography className="textOverflow_hidden" variant="h6" fontWeight={400} title={props.emailSubject}>
            {props.emailSubject}
          </Typography>
        </DialogTitle>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        {/* <FormLabel>Would You like to Remove the Duplicates?</FormLabel> */}
        {!props.msme && (
          <Box className="d_flex" mt={-2}>
            <Collapse in={showReplyBox}>
              <ReplyEmail
                companyId={props.companyId}
                branchCode={props.branchCode}
                base64Image={""}
                signature={""}
                sendSoleOrBulkMail={EmailSendCount.Sole}
                listAllBusinessPartnersWSR={props.listAllBusinessPartnersWSR}
                templateType={props.useCaseType}
                mailTo={props.businessPartnerName}
                cancelState={[cancelMail, setCancelMail]}
                clickState={[sendMail, setSendMail]}
              />
            </Collapse>
          </Box>
        )}

        {props.mailThreads.map((mailThread, _i) => (
          <React.Fragment key={mailThread.fromEmailId + _i}>
            {" "}
            <SingleMailThread
              mailThread={mailThread}
              s3Attachments={props.s3Attachments}
              msme={props.msme}
              companyId={props.companyId}
              branchCode={props.branchCode}
            />
            {props.mailThreads.length - 1 !== _i && <Divider sx={{ my: 2 }} />}
          </React.Fragment>
        ))}
        {props.loading && (
          <FormLabel sx={{ textAlign: "center" }}>
            <CircularProgress size={"4rem"} />
          </FormLabel>
        )}
        {!props.loading && props.mailThreads.length < 1 && (
          <FormLabel sx={{ textAlign: "center" }}>
            <em>No Mail Threads to Display</em>
          </FormLabel>
        )}
      </DialogContent>
      <DialogActions className="dialog_footer">
        {!props.msme && !showReplyBox && (
          <Button
            className="theme_btn"
            onClick={() => {
              setShowReplyBox(true);
            }}
          >
            Reply All
          </Button>
        )}
        {!props.msme && showReplyBox && (
          <Button
            className="theme_btn"
            disabled={!showReplyBox || sendMail}
            startIcon={sendMail ? <LoadingIcon loading={sendMail} /> : <Mail />}
            size="small"
            onClick={() => {
              setSendMail(true);
            }}
            sx={{ px: 1 }}
          >
            Send Email
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const getShortName = (name: string) => {
  if (!name?.includes(".")) {
    if (name.length > 12) return name.substring(0, 6) + "..." + name.substring(name.length - 6);
    else return name;
  }

  const splitName = name?.split(".");
  const extension = splitName[splitName.length - 1];
  splitName?.splice(splitName.length - 1);
  let fileName = splitName?.join("");
  if (fileName?.length > 12) {
    fileName = fileName.substring(0, 6) + "..." + fileName.substring(fileName.length - 6);
  }
  return fileName + "." + extension;
};

export const IFrame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef} style={{ display: "block", width: "100%", height: "210px" }}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

const SingleMailThread = ({
  mailThread,
  s3Attachments,
  msme,
  ...props
}: {
  mailThread: MailThread;
  s3Attachments: S3Attachment[];
  msme: boolean;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}) => {
  const downloadEmailAttachment = (fileName: string) => {
    const foundAttachment = s3Attachments?.find((attachment) => attachment.fileName === fileName);
    if (foundAttachment) {
      const link = document.createElement("a");
      link.href = foundAttachment.s3SignedUrl;
      link.download = foundAttachment.fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box className="mail_thread">
      <Box
        className="mail_header space_between"
        px={2}
        py={1}
        mb={1}
        sx={{ minHeight: 40, bgcolor: "#fafafa", borderRadius: 8 }}
      >
        <Typography>
          <FormLabel>From: </FormLabel>
          {mailThread.fromEmailId}
        </Typography>
        <FormLabel>{moment(mailThread.timestamp).format("DD-MM-YYYY hh:mm:ss A")}</FormLabel>
      </Box>
      <Box
        className="mail_body"
        sx={{ maxHeight: 250, minHeight: 100, overflow: "auto", bgcolor: "#fafafa", borderRadius: 8, p: 2 }}
      >
        {msme && (
          <IFrame referrerPolicy="no-referrer" content="abc">
            <div
              className="unsafeHtmlBody"
              dangerouslySetInnerHTML={{ __html: mailThread.emailBody }}
              style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: "#000C" }}
            />
          </IFrame>
        )}
        {!msme &&
          mailThread.emailBody.split("\n").map((item, _i) => {
            return (
              <span key={_i}>
                {item}
                <br />
              </span>
            );
          })}
      </Box>
      <Box
        className="mail_attachments mt_10 flex"
        sx={{ minHeight: 40, bgcolor: "#fafafa", borderRadius: 8, gap: 1, px: 2, py: 1 }}
      >
        <FormLabel>Attachments: </FormLabel>
        {mailThread.attachments.length < 1 && (
          <FormLabel>
            <i>None</i>
          </FormLabel>
        )}
        {mailThread.attachments.map((attachment, _i) => (
          <Chip
            title={attachment}
            key={attachment + _i}
            label={getShortName(attachment)}
            onClick={() => {
              downloadEmailAttachment(attachment);
              uiLogger(uiLoggerName.ui_DownloadLedgerViewEmail, props.companyId, props.branchCode);
            }}
            sx={{
              maxWidth: 200,
              "&, span": {
                textOverflow: "unset !important",
              },
              background: "rgba(84, 28, 76, 0.1) !important",
              "&:hover": {
                background: "rgba(84, 28, 76, 0.2) !important",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MailThreadDialog;
