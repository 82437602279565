import { Add, Close, Delete, Edit, History, LinkTwoTone, MoreVert, Settings, Tune } from "@mui/icons-material";
import { Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Menu } from "@mui/material";
import { Autocomplete, Button, Grid, IconButton, MenuItem, Stack } from "@mui/material";
import { TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Recon360Context } from "src/Components/Recon360/Recon360";
import styled from "styled-components";
import {
  amountEntryType,
  ColumnsToSanitize,
  LedgerConfigTemplate,
  LedgerConfiguration,
  ListReconSummaryAccountingSettingResponse,
  ListSanitizationRulesResponse,
} from "../../entity/recon-entity/ReconInterfaces";
import FormatDate from "../../Utils/DateUtils";
import LoadingIcon from "../Common/LoadingIcon";
import useFetch from "../Common/useFetch";
import { userContext } from "../Contexts/userContext";
import { Dialog as ReactDialog } from "../Dialog/Dialog";
import ApiErrorCatch from "./ApiSuccessErrorAlertPopup/ApiErrorCatch";
import "./ReconHome.scss";
import ColumnHeaderConfig, { rule } from "./Transformer/ColumnHeaderConfig";
import DocumentTypeConfig from "./Transformer/DocumentTypeConfig";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export type Template = {
  templateId: number;
  templateName: string;
  updatedAt?: Date | string;
};

export interface ConfigTemplateNames {
  templates: Template[];
  defaultTemplateId: number;
}

export interface ConfigTemplateRes {
  template: {
    templateId: number;
    templateName: string;
    config: LedgerConfiguration;
    columnsToSanitize?: ColumnsToSanitize[];
  };
}

const StyledComponent = styled.div`
  width: 100%;

  .table {
    border-collapse: collapse;
    table-layout: fixed;

    thead tr {
      background-color: #531c4c;
      user-select: none;
    }
    tbody tr {
      display: flex;
      align-items: center;
      min-height: 32px;
      font-family: "WorkSans";

      td {
        display: inline-block;
        font-size: 13px
        height: 32px;
      }
    }
    tr:last-child {
      border-bottom: 1px solid #dbdbdb;
    }

    th {
      font-size: 13px;
      color: white;
    }
    .th-bg {
      background-color: #391033;
    }
  }
`;

const TopHeader = ({
  headerText,
  icon,
  btnOnClick,
  pointer,
}: {
  headerText: string;
  icon: JSX.Element;
  btnOnClick?: () => void;
  pointer?: boolean;
}) => {
  return (
    <Box className="cardHeader space_between" gap={1.5}>
      <Typography fontSize={18} component={"span"}>
        {headerText}
      </Typography>
      <IconButton
        sx={{ ml: "auto", background: "rgba(160, 160, 210, 0.1)", cursor: pointer ? "pointer" : "default" }}
        onClick={btnOnClick}
        size="small"
      >
        {icon}
      </IconButton>
    </Box>
  );
};

const InformationCard = ({
  content,
  header,
  alternate,
  sx,
}: {
  content: React.ReactNode;
  header: React.ReactNode;
  alternate?: boolean;
  sx?: any;
}) => (
  <Box className={`informationCard fade_in_t ${alternate ? "alternate" : ""}`} style={sx}>
    <Box className="topContent">{content}</Box>
    <Box className="header">{header}</Box>
  </Box>
);

const ReconHome = ({ selectPartner }: { selectPartner: (partnerName: string) => void }) => {
  const { actor } = useContext(userContext);
  const { bpSelectedOptionRef, businessPartnerSelect } = useContext(Recon360Context);

  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { configListOwn, configList, defTemplateId, setDefTemplateId, ListAllConfigTemplateNamesRu } =
    useContext(Recon360Context);
  const [lastRecons, setLastRecons] = useState<{ timestamp: string; businessPartner: string }[]>([]);
  const [toleranceAmount, setToleranceAmount] = useState(0);
  const [outOfPeriod, setOutOfPeriod] = useState(false);
  const [amountEntryTerm, setAmountEntryTerm] = useState<"+ve/-ve" | "Dr/Cr">("+ve/-ve");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // Edit Template States
  const [templateSelectedId, setTemplateSelectedId] = useState("add");
  const [openEditConfig, setOpenEditConfig] = useState(false);
  const [configTemplate, setConfigTemplate] = useState<LedgerConfiguration>(null);
  const [templateName, setTemplateName] = useState("");
  const [sanitizeColumns, setSanitizeColumns] = useState<ColumnsToSanitize[]>([]);
  // End of Edit Template States

  const [openDeleteConfig, setOpenDeleteConfig] = useState(false);

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>, selectedTemplateId: string, _name: string) => {
    setTemplateSelectedId(selectedTemplateId);
    setTemplateName(_name);
    setAnchorEl(event.currentTarget);
  };
  const handleAdd = () => {
    setTemplateSelectedId("add");
    setConfigTemplate({} as LedgerConfiguration);
    setTemplateName("");
    setOpenEditConfig(true);
  };
  const handleEditMenuClose = () => {
    ListConfigTemplate(templateSelectedId);
    setAnchorEl(null);
  };
  const handleDeleteClose = () => {
    setOpenDeleteConfig(true);
    setAnchorEl(null);
  };

  const ListReconSummaryAccountingSetting = () => {
    useFetch<ListReconSummaryAccountingSettingResponse>("/api/ListReconSummaryAccountingSetting", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (response) => {
        setToleranceAmount(response.data.toleranceForAccounting);
        setOutOfPeriod(response.data.outOfPeriod);
        const termType = response.data.amountEntryType === amountEntryType.debitCredit ? "Dr/Cr" : "+ve/-ve";
        setAmountEntryTerm(termType);
      },
    });
  };

  const ListConfigTemplate = (_templateId: string) => {
    useFetch<ConfigTemplateRes>("/api/GetRuConfigTemplate", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          templateId: _templateId,
        },
      },
      thenCallBack: (response) => {
        if (response.data.template) {
          setConfigTemplate(response.data.template.config);
          setTemplateName(response.data.template.templateName);
          setSanitizeColumns(response.data.template.columnsToSanitize);
          setOpenEditConfig(true);
        }
      },
    });
  };

  const GetLastThreeRecons = () => {
    useFetch<{ reconRuns: { timestamp: string; businessPartner: string }[] }>("/api/GetLastThreeRecons", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (response) => {
        if (response.data.reconRuns.length > 0) {
          setLastRecons(response.data.reconRuns);
        }
      },
    });
  };

  const InsertDefaultTemplate = (defTemplate: number) => {
    if (!defTemplate) return;
    // eslint-disable-next-line
    useFetch<{}>("/api/InsertDefaultTemplate", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        templateId: defTemplate,
      },
      thenCallBack: (_res) => {
        const lastSelectedBp = bpSelectedOptionRef.current;
        if (lastSelectedBp) businessPartnerSelect({}, null);
        setTimeout(() => {
          if (lastSelectedBp) businessPartnerSelect({}, lastSelectedBp);
        }, 500);
      },
    });
  };

  useEffect(() => {
    ListReconSummaryAccountingSetting();
    ListAllConfigTemplateNamesRu();
    GetLastThreeRecons();
  }, []);

  const accountName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");

  return (
    <Box display="flex" flexDirection="column" gap={2} p={3} pb={0}>
      <ApiErrorCatch
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
        showUploadSuccessAlert={showSuccessAlert}
      />
      <Grid className="containerGrid fade_up_t" gap={1}>
        {/* Left */}
        <Box id="configBox" className="fade_up_t d_flex" gap={1} sx={{ flexFlow: "column" }}>
          <Card id="templateCard" className="vertical_center_align" sx={{ gap: 2, px: 2, py: 2.5 }}>
            <TopHeader headerText="Configuration Templates" icon={<Tune />} />
            <Autocomplete
              multiple={false}
              className="w_250 fade_up_t"
              size="small"
              onChange={(_e, v) => {
                setDefTemplateId((v as Template).templateId);
                InsertDefaultTemplate((v as Template).templateId);
              }}
              id="defaultConfig"
              value={configList.find((_c) => _c.templateId === defTemplateId) || { templateName: " " }}
              options={configList}
              getOptionLabel={(op) => (op as Template).templateName}
              disableClearable={true}
              groupBy={(option) => option[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Default Config"
                  label="Default Config"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                  }}
                />
              )}
              sx={{
                minWidth: 300,
                opacity: 0,
              }}
            />
            <div className="blankBox" style={{ fontSize: 2 }}>
              &nbsp;
            </div>
            <TopHeader headerText="Saved Templates" icon={<Add />} btnOnClick={() => handleAdd()} pointer={true} />
            <StyledComponent>
              <table className="d_flex table sticky" style={{ flexFlow: "column", maxHeight: 200, overflow: "hidden" }}>
                <thead>
                  <tr className="d_flex">
                    <th style={{ flexGrow: 1 }}>Template Name</th>
                    <th style={{ marginLeft: "auto", width: 160 }}>Last Edited</th>
                    <th style={{ width: 100 }}> </th>
                  </tr>
                </thead>
                <tbody className="d_flex" style={{ flexFlow: "column", maxHeight: 200, overflowY: "auto" }}>
                  {/* only Ru template */}
                  {configList.map((_c, _i) => (
                    <tr key={_c.templateName + _c.templateId + _i}>
                      <td className="textOverflow_hidden" style={{ flexGrow: 1, width: "unset" }}>
                        {_c.templateName}
                      </td>
                      <td className="textOverflow_hidden" style={{ marginLeft: "auto", width: 160 }}>
                        {FormatDate(_c.updatedAt, "hh:mm A, DD MMM YY").toString()}
                      </td>
                      <td className="textOverflow_hidden" style={{ width: 100, paddingBlock: 1.5, paddingRight: 0 }}>
                        <Button
                          size="small"
                          endIcon={<MoreVert />}
                          sx={{ fontSize: 11 }}
                          onClick={(e) => handleOptionsClick(e, _c.templateId.toString(), _c.templateName)}
                        >
                          Options
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleEditMenuClose} sx={{ fontSize: 13 }}>
                  <Edit className="mr_10" fontSize="small" /> Edit
                </MenuItem>
                <MenuItem onClick={handleDeleteClose} sx={{ fontSize: 13, color: "red" }}>
                  <Delete className="mr_10" fontSize="small" /> Delete
                </MenuItem>
              </Menu>
            </StyledComponent>
          </Card>
        </Box>
        {/* Top */}
        <Card id="quickLinks" className="fade_up_t vertical_center_align" sx={{ gap: 2, px: 2, py: 2.5 }}>
          <TopHeader headerText="Quick Link" icon={<LinkTwoTone sx={{ rotate: "-45deg" }} />} />
          <Box className="linkSegment fade_up_t">
            <Box className="circle">
              <i className="fa-solid fa-user-group" />
            </Box>
            <Box className="right">
              <Link to={`/${accountName}/partnerMaster`}>Partner Master</Link>
            </Box>
          </Box>
        </Card>
        {/* Right */}
        <Card id="information" className="fade_up_t vertical_center_align" sx={{ gap: 2, px: 2, py: 2.5 }}>
          <TopHeader headerText="Recon Configurations" icon={<Settings />} />
          <Stack direction={"row"} gap={1.5} flexWrap={"wrap"}>
            <InformationCard content={toleranceAmount} header="Tolerance" sx={{ "--delay": `1s` }} />
            <InformationCard content={outOfPeriod ? "Yes" : "No"} header="Out of Period" sx={{ "--delay": `0.75s` }} />
            <InformationCard content={amountEntryTerm} header="Terminology" sx={{ "--delay": `0.5s` }} />
          </Stack>
        </Card>
        {/* Bottom */}
        <Card id="lastRecons" className="fade_up_t vertical_center_align" sx={{ gap: 2, px: 2, py: 2.5 }}>
          <TopHeader headerText="Last 3 Reconciliations" icon={<History />} />
          <StyledComponent>
            <table className="d_flex table sticky" style={{ flexFlow: "column", maxHeight: 150, overflow: "hidden" }}>
              <thead>
                <tr className="d_flex">
                  <th style={{ flexGrow: 1 }}>Partner Name</th>
                  <th style={{ marginLeft: "auto", width: 200 }}>Last Reconciliation</th>
                </tr>
              </thead>
              <tbody className="d_flex" style={{ flexFlow: "column", maxHeight: 250, overflowY: "auto" }}>
                {lastRecons.map((rec, _i) => (
                  <tr key={rec.timestamp + rec.businessPartner + _i}>
                    <td
                      className="textOverflow_hidden_anchor"
                      style={{ flexGrow: 1, width: "unset", cursor: "pointer" }}
                      onClick={selectPartner.bind(null, rec.businessPartner)}
                    >
                      {rec.businessPartner}
                    </td>
                    <td className="textOverflow_hidden" style={{ marginLeft: "auto", width: 200 }}>
                      {FormatDate(rec.timestamp, "hh:mm A, DD MMM YY").toString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledComponent>
        </Card>
      </Grid>

      <ConfigEdit
        open={openEditConfig}
        setOpen={setOpenEditConfig}
        configListOwn={configListOwn}
        templateConfig={configTemplate}
        templateId={templateSelectedId}
        templateName={templateName}
        setTemplateName={setTemplateName}
        newTemplate={templateSelectedId === "add"}
        sanitizeColumns={sanitizeColumns}
        setApiErrorMsg={setApiErrorMsg}
        setShowErrorAlert={setShowErrorAlert}
        setShowSuccessAlert={setShowSuccessAlert}
        afterInsert={ListAllConfigTemplateNamesRu}
        afterUpdate={ListAllConfigTemplateNamesRu}
      />

      <DeleteTemplatesDialog
        open={openDeleteConfig}
        setOpen={setOpenDeleteConfig}
        templateSelected={templateSelectedId}
        templateName={templateName}
        defaultTemplateId={defTemplateId}
        afterClose={ListAllConfigTemplateNamesRu}
        setApiErrorMsg={setApiErrorMsg}
        setShowErrorAlert={setShowErrorAlert}
        setShowSuccessAlert={setShowSuccessAlert}
      />
    </Box>
  );
};

interface ConfigEditProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  configListOwn: LedgerConfigTemplate[];
  templateId: string;
  templateName: string;
  setTemplateName?: StateDispatch<string>;
  newTemplate: boolean;
  templateConfig: LedgerConfiguration;
  sanitizeColumns: ColumnsToSanitize[];
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
  afterInsert?: () => void;
  afterUpdate?: () => void;
}

const ConfigEdit = ({
  open,
  setOpen,
  configListOwn,
  templateConfig,
  templateId,
  templateName,
  setTemplateName,
  newTemplate,
  sanitizeColumns,
  setApiErrorMsg,
  setShowErrorAlert,
  setShowSuccessAlert,
  afterInsert,
  afterUpdate,
}: ConfigEditProps) => {
  const [columnHeaderConfigDialog, setColumnHeaderConfigDialog] = useState<boolean>(true);
  const [debitAmountSignReverseFlag, setDebitAmountSignReverseFlag] = useState<boolean>(false);
  const [creditAmountSignReverseFlag, setCreditAmountSignReverseFlag] = useState<boolean>(false);
  const [updateConfig, setUpdateConfig] = useState(true); // when true edits, false to add
  const [specificConfig, setSpecificConfig] = useState(false);
  const [configTemplate, setConfigTemplate] = useState<LedgerConfiguration>(null);
  const [configTemplateUpdated, setConfigTemplateUpdated] = useState<LedgerConfiguration>(null);
  const [templateNameUpdated, setTemplateNameUpdated] = useState<string>("");
  const [sanitizeCols, setSanitizeCols] = useState<ColumnsToSanitize[]>([]);
  const [allRules, setAllRules] = useState<ColumnsToSanitize[]>([]);
  const [rulesArray, setRulesArray] = useState<rule[]>([]);

  // Recon360 Context
  const { getLedgerConfigList } = useContext(Recon360Context);

  useEffect(() => {
    setConfigTemplate(templateConfig);
    setUpdateConfig(!newTemplate);
    setSanitizeCols(sanitizeColumns);
    if (newTemplate) {
      setConfigTemplate(null);
      setConfigTemplateUpdated(null);
      setTemplateNameUpdated("");
    }
    ListSanitizeRule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateConfig, newTemplate, sanitizeColumns]);

  const ListSanitizeRule = () => {
    useFetch<ListSanitizationRulesResponse>("/api/listSanitizeRule ", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,

      thenCallBack: (response) => {
        if (response.data.sanitizationRules) {
          setRulesArray(response.data.sanitizationRules);
        }
      },
    });
  };

  const InsertNewConfig = (configData: LedgerConfiguration, _templateName: string, _rules: ColumnsToSanitize[]) => {
    useFetch<ConfigTemplateRes>("/api/AddRuConfigTemplate", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        templateName: _templateName,
        config: configData,
        columnsToSanitize: _rules,
      },
      thenCallBack: (response) => {
        if (response.data.config) {
          setConfigTemplate(response.data.config);
        }
        if (afterInsert) afterInsert();

        if (getLedgerConfigList) getLedgerConfigList();
      },
    });
  };

  const UpdateConfig = (configData: LedgerConfiguration, _templateName: string, _rules: ColumnsToSanitize[]) => {
    useFetch("/api/UpdateRUConfigTemplate", "PUT", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        templateName: _templateName,
        config: configData,
        columnsToSanitize: _rules,
        templateId,
      },
      thenCallBack: () => {
        if (afterUpdate) afterUpdate();

        if (getLedgerConfigList) getLedgerConfigList();
      },
    });
  };

  return (
    <ReactDialog open={open} onClose={() => setOpen(false)} width70Per={true}>
      {columnHeaderConfigDialog ? (
        <ColumnHeaderConfig
          name="Recon Home"
          setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
          headerName={updateConfig ? configTemplate : configTemplateUpdated || ({} as LedgerConfiguration)}
          setHeaderName={setConfigTemplate}
          rules={rulesArray}
          templateName={updateConfig ? templateName : templateNameUpdated || ""}
          setTemplateName={setTemplateName}
          sanitizeCol={sanitizeCols}
          template={true}
          newconfig={updateConfig}
          setNewMapping={setUpdateConfig}
          heading="Column header mapping"
          openNextDialog={(sanitizeCol: ColumnsToSanitize[], header: LedgerConfiguration, template: string) => {
            setAllRules(sanitizeCol);
            setSanitizeCols(sanitizeCol);
            setConfigTemplateUpdated(header);
            setTemplateNameUpdated(template);
            setColumnHeaderConfigDialog(false);
          }}
          showSettingsIcon={true}
          setOpenDialog={setOpen}
          specificConfig={specificConfig}
          setSpecificConfig={setSpecificConfig}
          disableSpecificConfigCheckbox={true}
          configList={configListOwn}
          debitAmountSignReverseFlag={debitAmountSignReverseFlag}
          setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
          creditAmountSignReverseFlag={creditAmountSignReverseFlag}
          setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
          subTab={"Ledger"}
          saveRulesApi={(header) => {
            if (!updateConfig)
              InsertNewConfig(header as LedgerConfiguration, templateNameUpdated || templateName, allRules);
            else UpdateConfig(header as LedgerConfiguration, templateNameUpdated || templateName, allRules);

            setOpen(false);
            setColumnHeaderConfigDialog(true);
          }}
          editingTemplate={true}
          companyId={null}
          branchCode={null}
        />
      ) : (
        <DocumentTypeConfig
          setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
          headerName={updateConfig ? configTemplate : configTemplateUpdated || ({} as LedgerConfiguration)}
          setHeaderName={setConfigTemplate}
          setNewMapping={setUpdateConfig}
          name="Configuration File"
          heading="Document Type Key"
          backTemplate={() => {
            setColumnHeaderConfigDialog(true);
          }}
          setOpenDialog={setOpen}
          saveRulesApi={(config: LedgerConfiguration) => {
            if (!updateConfig)
              InsertNewConfig(config as LedgerConfiguration, templateNameUpdated || templateName, allRules);
            else UpdateConfig(config as LedgerConfiguration, templateNameUpdated || templateName, allRules);

            setOpen(false);
            setColumnHeaderConfigDialog(true);
          }}
          setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
          setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
          editingTemplate={true}
        />
      )}
    </ReactDialog>
  );
};

interface DeleteTemplateProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  templateSelected: string;
  templateName: string;
  defaultTemplateId: number;
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
  afterClose?: () => Promise<void>;
}

const DeleteTemplatesDialog = ({
  open,
  setOpen,
  templateSelected,
  templateName,
  defaultTemplateId,
  setApiErrorMsg,
  setShowErrorAlert,
  setShowSuccessAlert,
  afterClose,
}: DeleteTemplateProps) => {
  // Recon360 Context
  const { getLedgerConfigList, bpSelectedOptionRef, businessPartnerSelect } = useContext(Recon360Context);
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);

  const DeleteTemplate = () => {
    setIsDeletingTemplate(true);
    useFetch("/api/DeleteRuConfigTemplate", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        templateId: parseInt(templateSelected),
      },
      thenCallBack: async (_res) => {
        setIsDeletingTemplate(false);
        await afterClose();
        if (templateSelected?.toString() === defaultTemplateId?.toString()) {
          const lastSelectedBp = bpSelectedOptionRef.current;
          if (lastSelectedBp) businessPartnerSelect({}, null);
          setTimeout(() => {
            if (lastSelectedBp) businessPartnerSelect({}, lastSelectedBp);
          }, 500);
        }

        if (getLedgerConfigList) getLedgerConfigList();
      },
      catchCallBack: () => {
        setIsDeletingTemplate(false);
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} classes={{ root: "z_index_30" }}>
      <Box hidden className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Delete Config Template</DialogTitle>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>Are you sure, You want to Delete Template?</FormLabel>
        <FormLabel>
          <b>Template:</b> {templateName}
        </FormLabel>
      </DialogContent>
      <DialogActions className="">
        <Button
          autoFocus={true}
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingTemplate}
          startIcon={<LoadingIcon loading={isDeletingTemplate} />}
          autoFocus={true}
          onClick={() => {
            setOpen(false);
            DeleteTemplate();
          }}
          color="error"
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconHome;
