import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Box, Dialog, Divider, Typography } from "@mui/material";
import React from "react";
import { formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import styles from "./TasksHistoryModal.module.scss";

import arrow_right from "src/Graphics/AutomatedRecon/arrow_right.svg";

enum TicketHistoryFields {
  CreatedTicket = "Created Ticket",
  MailFollowUp = "Mail Follow Up",
  ConversationFollowUp = "Conversation Follow Up",
  DueDate = "Due Date",
}
export default function TasksHistoryModal(props) {
  return (
    <Dialog
      open={props.showTasksHistoryModal}
      onClose={() => props.setShowTasksHistoryModal(false)}
      sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}
    >
      <div className={styles.modal_container}>
        <header className={styles.modal_title}>
          <p className="fw_600 fs_18">Task History</p>
          <ClearRoundedIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setShowTasksHistoryModal(false);
            }}
          />
        </header>
        <section className={styles.modal_body}>
          {props.ticketHistoryList.map((item) => {
            let oldValue = item.oldValue;
            let newValue = item.newValue;
            if (
              item.field === TicketHistoryFields.MailFollowUp ||
              item.field === TicketHistoryFields.ConversationFollowUp
            ) {
              const oldValueArray = item.oldValue?.split(",");
              if (oldValueArray.length === 1) oldValue = "0 Times";
              else if (oldValueArray.length === 2) {
                const count = oldValueArray[0];
                const timeFormat = formatDate(oldValueArray[1]);
                oldValue = count.concat(", ", timeFormat);
              }
              const newValueArray = item.newValue?.split(",");
              if (newValueArray.length === 2) {
                const count = newValueArray[0];
                const timeFormat = formatDate(newValueArray[1]);
                newValue = count.concat(", ", timeFormat);
              }
            }

            if (item.field === TicketHistoryFields.DueDate) {
              oldValue = oldValue ? formatDate(oldValue) : "Blank";
              newValue = newValue ? formatDate(newValue) : "Blank";
            }

            return (
              <Box key={item.id}>
                <Box sx={{ paddingY: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p>
                      <span className="fs_14 fw_600">{item.userName}</span>
                      {item.field === TicketHistoryFields.CreatedTicket ? (
                        <span className="fs_14"> created the task</span>
                      ) : (
                        <>
                          <span className="fs_14"> updated the </span>
                          <span className="fs_14 fw_600">{item.field}</span>
                        </>
                      )}
                    </p>
                    <Typography variant="subtitle2" sx={{ fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>
                      {formatDateTime24Hours(item.timestamp)}{" "}
                    </Typography>
                  </Box>

                  {item.field !== TicketHistoryFields.CreatedTicket && (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginRight: 1,
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "rgba(0, 0, 0, 0.6)",
                          alignSelf: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {oldValue === null || oldValue === undefined ? "Blank" : oldValue}
                      </Typography>
                      <img src={arrow_right} alt="arrow icon" />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginLeft: 1,
                          fontWeight: "400",
                          fontSize: "12px",
                          alignSelf: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {newValue === null || newValue === undefined ? "Blank" : newValue}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Divider />
              </Box>
            );
          })}
        </section>
      </div>
    </Dialog>
  );
}
