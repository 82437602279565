import { Button, Card, Grid, Switch } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { BP, OWN, RECONCILIATION, RESET, RISKY_ALGORITHMS, SAVE } from "../../../Utils/Recon/ReconSetting/Constants";
import Loader from "../../Common/Loader";
import StaticTable from "../../ReactTable/StaticTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import "./AlgoCustomization.scss";

const AlgoCustomization = () => {
  const [loader, setLoader] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [rowsDataReconciliation, setRowsDataReconciliation] = useState([]);
  const [rowsDataReversalOwn, setRowsDataReversalOwn] = useState([]);
  const [rowsDataReversalBp, setRowsDataReversalBp] = useState([]);
  const [rowsDataInternalDocumentOwn, setRowsDataInternalDocumentOwn] = useState([]);
  const [rowsDataInternalDocumentBp, setRowsDataInternalDocumentBp] = useState([]);
  const [rowsDataSelfReversalOwn, setRowsDataSelfReversalOwn] = useState([]);
  const [rowsDataSelfReversalBp, setRowsDataSelfReversalBp] = useState([]);
  const [rowsDataDncnReversalOwn, setRowsDataDncnReversalOwn] = useState([]);
  const [rowsDataDncnReversalBp, setRowsDataDncnReversalBp] = useState([]);
  const [rowsDataClearingDocumentOwn, setRowsDataClearingDocumentOwn] = useState([]);
  const [rowsDataClearingDocumentBp, setRowsDataClearingDocumentBp] = useState([]);
  const [rowsDataManyToMany1, setRowsDataManyToMany1] = useState([]);
  const [rowsDataStairCaseApproach, setRowsDataStairCaseApproach] = useState([]);
  const [rowsDataInvoiceSubstringMatch, setRowsDataInvoiceSubstringMatch] = useState([]);
  const [reconciliationSettingDataPayload, setReconciliationSettingDataPayload] = useState(null);
  const [reversalSettingOwnDataPayload, setReversalSettingOwnDataPayload] = useState(null);
  const [reversalSettingBusinessPartnerDataPayload, setReversalSettingBusinessPartnerDataPayload] = useState(null);
  const [riskyFunctionSettingDataPayload, setRiskyFunctionSettingDataPayload] = useState(null);

  const [showLoadingIconSaveReconciliation, setShowLoadingIconSaveReconciliation] = useState<boolean>(false);
  const [showLoadingIconResetReconciliation, setShowLoadingIconResetReconciliation] = useState<boolean>(false);
  const [showLoadingIconSaveRiskyAlgorithms, setShowLoadingIconSaveRiskyAlgorithms] = useState<boolean>(false);
  const [showLoadingIconResetRiskyAlgorithms, setShowLoadingIconResetRiskyAlgorithms] = useState<boolean>(false);
  const [showLoadingIconSaveReversalSettingOwn, setShowLoadingIconSaveReversalSettingOwn] = useState<boolean>(false);
  const [showLoadingIconResetReversalSettingOwn, setShowLoadingIconResetReversalSettingOwn] = useState<boolean>(false);
  const [showLoadingIconSaveReversalSettingBusinessPartner, setShowLoadingIconSaveReversalSettingBusinessPartner] =
    useState<boolean>(false);
  const [showLoadingIconResetReversalSettingBusinessPartner, setShowLoadingIconResetReversalSettingBusinessPartner] =
    useState<boolean>(false);

  const columnDefinitionForReconciliation = [
    {
      Header: "Switching",
      accessor: "documentType",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => <div className="fw_900">{row.original.documentType}</div>,
    },
    {
      Header: "",
      accessor: "functionName",
      maxWidth: 750,
      minWidth: 750,
      width: 750,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          {row.original.showFunctionNameOnOff && (
            <Switch
              checked={row.original.functionNameOnOff}
              onChange={(e) => {
                handleChangeForReconciliation(
                  row,
                  e.target.checked,
                  rowsDataReconciliation,
                  reconciliationSettingDataPayload,
                  setRowsDataReconciliation,
                  setReconciliationSettingDataPayload
                );
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        </div>
      ),
    },
  ];

  const columnDefinitionForReversalOwn = [
    {
      Header: "Reversal entry type with",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataReversalOwn,
                reversalSettingOwnDataPayload,
                setRowsDataReversalOwn,
                setReversalSettingOwnDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForReversalBp = [
    {
      Header: "Reversal entry type with",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataReversalBp,
                reversalSettingBusinessPartnerDataPayload,
                setRowsDataReversalBp,
                setReversalSettingBusinessPartnerDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForInternalDocumentOwn = [
    {
      Header: "Internal Document entry type with",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataInternalDocumentOwn,
                reversalSettingOwnDataPayload,
                setRowsDataInternalDocumentOwn,
                setReversalSettingOwnDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForInternalDocumentBp = [
    {
      Header: "Internal Document entry type with",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataInternalDocumentBp,
                reversalSettingBusinessPartnerDataPayload,
                setRowsDataInternalDocumentBp,
                setReversalSettingBusinessPartnerDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForSelfReversalOwn = [
    {
      Header: "Self-Reversal",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataSelfReversalOwn,
                reversalSettingOwnDataPayload,
                setRowsDataSelfReversalOwn,
                setReversalSettingOwnDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForSelfReversalBp = [
    {
      Header: "Self-Reversal",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataSelfReversalBp,
                reversalSettingBusinessPartnerDataPayload,
                setRowsDataSelfReversalBp,
                setReversalSettingBusinessPartnerDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForDncnReversalOwn = [
    {
      Header: "On basis of Amount only",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataDncnReversalOwn,
                reversalSettingOwnDataPayload,
                setRowsDataDncnReversalOwn,
                setReversalSettingOwnDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForDncnReversalBp = [
    {
      Header: "On basis of Amount only",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataDncnReversalBp,
                reversalSettingBusinessPartnerDataPayload,
                setRowsDataDncnReversalBp,
                setReversalSettingBusinessPartnerDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForClearingDocumentOwn = [
    {
      Header: "On basis of clearing doc",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataClearingDocumentOwn,
                reversalSettingOwnDataPayload,
                setRowsDataClearingDocumentOwn,
                setReversalSettingOwnDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForClearingDocumentBp = [
    {
      Header: "On basis of clearing doc",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataClearingDocumentBp,
                reversalSettingBusinessPartnerDataPayload,
                setRowsDataClearingDocumentBp,
                setReversalSettingBusinessPartnerDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForManyToMany1 = [
    {
      Header: "Many Many",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataManyToMany1,
                riskyFunctionSettingDataPayload,
                setRowsDataManyToMany1,
                setRiskyFunctionSettingDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForStairCaseApproach = [
    {
      Header: "Staircase",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataStairCaseApproach,
                riskyFunctionSettingDataPayload,
                setRowsDataStairCaseApproach,
                setRiskyFunctionSettingDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];
  const columnDefinitionForInvoiceSubstringMatch = [
    {
      Header: "Numerical Substring Match",
      accessor: "documentType",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
    },
    {
      Header: "On-Off",
      accessor: "functionNameOnOff",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Switch
            checked={row.original.functionNameOnOff}
            onChange={(e) => {
              handleChangeForReconciliation(
                row,
                e.target.checked,
                rowsDataInvoiceSubstringMatch,
                riskyFunctionSettingDataPayload,
                setRowsDataInvoiceSubstringMatch,
                setRiskyFunctionSettingDataPayload
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCustomisedSetting();
    // eslint-disable-next-line
  }, []);

  const getCustomisedSetting = () => {
    try {
      Axios.get("/api/GetCustomisedSetting")
        .then((response) => {
          const reconciliationSetting = response.data.data.reconciliationSetting;
          const reversalSettingOwn = response.data.data.reversalSettingOwn;
          const reversalSettingBusinessPartner = response.data.data.reversalSettingBusinessPartner;
          const riskyFunctionSetting = response.data.data.riskyFunctionSetting;
          setReconciliationSettingDataPayload(reconciliationSetting);
          setReversalSettingOwnDataPayload(reversalSettingOwn);
          setReversalSettingBusinessPartnerDataPayload(reversalSettingBusinessPartner);
          setRiskyFunctionSettingDataPayload(riskyFunctionSetting);

          functionToSetRowsDataReconciliation(reconciliationSetting);
          functionToSetReversalSettingOwn(reversalSettingOwn);
          functionToSetReversalSettingBusinessPartner(reversalSettingBusinessPartner);
          functionToSetRowsDataManyToMany1(riskyFunctionSetting);

          setLoader(false);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetCustomisedSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetCustomisedSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const updateReconcileFunctionSetting = (data, from, type, setShowLoadingIcon) => {
    setShowLoadingIcon(true);
    try {
      Axios.post<any>("/api/UpdateReconcileFunctionSetting", {
        data,
      })
        .then((response) => {
          if (from === RESET) {
            if (type === RECONCILIATION) {
              setReconciliationSettingDataPayload(data);
              functionToSetRowsDataReconciliation(data);
            } else if (type === RISKY_ALGORITHMS) {
              setRiskyFunctionSettingDataPayload(data);
              functionToSetRowsDataManyToMany1(data);
            }
          }
          setShowLoadingIcon(false);
          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateReconcileFunctionSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIcon(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateReconcileFunctionSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const updateReversalFunctionSetting = (data, from, type) => {
    try {
      Axios.post<any>("/api/UpdateReversalFunctionSetting", {
        data,
        for: type,
      })
        .then((response) => {
          if (from === RESET) {
            if (type === OWN) {
              setReversalSettingOwnDataPayload(data);
              functionToSetReversalSettingOwn(data);
              setShowLoadingIconResetReversalSettingOwn(false);
            } else if (type === BP) {
              setReversalSettingBusinessPartnerDataPayload(data);
              functionToSetReversalSettingBusinessPartner(data);
              setShowLoadingIconResetReversalSettingBusinessPartner(false);
            }
          }
          setShowLoadingIconSaveReversalSettingOwn(false);
          setShowLoadingIconSaveReversalSettingBusinessPartner(false);
          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIconSaveReversalSettingOwn(false);
          setShowLoadingIconSaveReversalSettingBusinessPartner(false);
          setShowLoadingIconResetReversalSettingOwn(false);
          setShowLoadingIconResetReversalSettingBusinessPartner(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateReversalFunctionSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIconSaveReversalSettingOwn(false);
      setShowLoadingIconSaveReversalSettingBusinessPartner(false);
      setShowLoadingIconResetReversalSettingOwn(false);
      setShowLoadingIconResetReversalSettingBusinessPartner(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateReversalFunctionSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // Function to handle the change in the Switch
  const handleChangeForReconciliation = (row, newValue, rowsData, rowsDataPayload, setRowsData, setRowsDataPayload) => {
    // Update the specific row's functionNameOnOff value
    const updatedRowsData = rowsData.map((data) => {
      if (data === row.original) {
        return {
          ...data,
          functionNameOnOff: newValue,
        };
      }
      return data;
    });

    // Update reconciliationSetting based on the row's functionNameOnOff
    const updatedReconciliationSetting = {
      ...rowsDataPayload,
      [row.original.functionNameVal]: newValue,
    };

    // Update state
    setRowsData(updatedRowsData);
    setRowsDataPayload(updatedReconciliationSetting);
  };

  const resetReconciliation = () => {
    const reconciliationSetting = {
      dncnIdDateMatching: true,
      paymentIdDateMatching: true,
      tdsDateAmountMatching: true,
      dncnDateAmountMatching: true,
      dncnMisMatchOnSameDate: true,
      invoiceDateAmountMatching: true,
      paymentDateAmountMatching: true,
      paymentMisMatchOnSameDate: true,
      tdsMatchingInInvoiceDateAmount: true,
    };
    updateReconcileFunctionSetting(reconciliationSetting, RESET, RECONCILIATION, setShowLoadingIconResetReconciliation);
  };

  const resetRiskyAlgorithms = () => {
    const riskyFunctionSetting = {
      manyManyInDncn: true,
      manyManyInPayment: true,
    };
    updateReconcileFunctionSetting(
      riskyFunctionSetting,
      RESET,
      RISKY_ALGORITHMS,
      setShowLoadingIconResetRiskyAlgorithms
    );
  };

  const resetReversalFunctionSetting = (type) => {
    const reversalSetting = {
      tdsIntraReversal: true,
      dncnIntraReversal: true,
      invoiceDncnReversal: true,
      paymentDncnReversal: true,
      paymentIntraReversal: true,
      invoiceIntraReversal: true,
      reversalIntraReversal: true,
      internalDocIntraReversal: true,
      reversalOfTdsWithReversal: true,
      reversalOfDncnWithReversal: true,
      reversalOfTdsWithInternalDoc: true,
      reversalOfDncnWithInternalDoc: true,
      reversalOfInvoiceWithReversal: true,
      reversalOfPaymentWithReversal: true,
      reversalOfInvoiceWithInternalDoc: true,
      reversalOfPaymentWithInternalDoc: true,
      invoiceDncnReversalOnClearingDocument: true,
      paymentDncnReversalOnClearingDocument: true,
      paymentTDSnReversalOnClearingDocument: true,
      reversalOnReferenceNumber: true,
      reversalOnDocumentNumber: true,
    };
    updateReversalFunctionSetting(reversalSetting, RESET, type);
  };

  const functionToSetRowsDataReconciliation = (reconciliationSetting) => {
    setRowsDataReconciliation([
      {
        documentType: "Invoice",
        functionName: "",
        functionNameOnOff: false,
        functionNameVal: "",
        showFunctionNameOnOff: false,
      },
      {
        documentType: "",
        functionName: "Amount Based for Single vs Single",
        functionNameOnOff: reconciliationSetting.invoiceDateAmountMatching,
        functionNameVal: "invoiceDateAmountMatching",
        showFunctionNameOnOff: true,
      },
      // {
      //   documentType: "",
      //   functionName: "Invoice TDS",
      //   functionNameOnOff: reconciliationSetting.tdsMatchingInInvoiceDateAmount,
      //   functionNameVal: "tdsMatchingInInvoiceDateAmount",
      //   showFunctionNameOnOff: true,
      // },
      {
        documentType: "",
        functionName: "Amount when Grouped on Ref",
        functionNameOnOff: reconciliationSetting.invoiceRollingIdMatching,
        functionNameVal: "invoiceRollingIdMatching",
        showFunctionNameOnOff: true,
      },
      // {
      //   documentType: "",
      //   functionName: "DNCN On InvoiceNumber",
      //   functionNameOnOff: reconciliationSetting.invoiceDncnReconciliationOnInvoiceNumber,
      //   functionNameVal: "invoiceDncnReconciliationOnInvoiceNumber",
      //   showFunctionNameOnOff: true,
      // },
      // {
      //   documentType: "",
      //   functionName: "DNCN On RollingWindow",
      //   functionNameOnOff: reconciliationSetting.invoiceDncnReconciliationOnRollingWindow,
      //   functionNameVal: "invoiceDncnReconciliationOnRollingWindow",
      //   showFunctionNameOnOff: true,
      // },
      {
        documentType: "Payment",
        functionName: "",
        functionNameOnOff: false,
        functionNameVal: "",
        showFunctionNameOnOff: false,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on date",
        functionNameOnOff: reconciliationSetting.paymentDateAmountMatching,
        functionNameVal: "paymentDateAmountMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Small amount mismatch (less than 0.05%) within nearby dates",
        functionNameOnOff: reconciliationSetting.paymentMisMatchOnSameDate,
        functionNameVal: "paymentMisMatchOnSameDate",
        showFunctionNameOnOff: true,
      },
      // {
      //   documentType: "",
      //   functionName: "Month-Month",
      //   functionNameOnOff: true,
      //   showFunctionNameOnOff: true,
      // },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on ID on one side and grouping on Date on other side",
        functionNameOnOff: reconciliationSetting.paymentIdDateMatching,
        functionNameVal: "paymentIdDateMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Amount mismatch (less than 10%) on same date",
        functionNameOnOff: reconciliationSetting.paymentForcedMismatchEnabled,
        functionNameVal: "paymentForcedMismatchEnabled",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on Payment ID",
        functionNameOnOff: reconciliationSetting.paymentRollingIdMatching,
        functionNameVal: "paymentRollingIdMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "DNCN",
        functionName: "",
        functionNameOnOff: false,
        functionNameVal: "",
        showFunctionNameOnOff: false,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on date",
        functionNameOnOff: reconciliationSetting.dncnDateAmountMatching,
        functionNameVal: "dncnDateAmountMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Small amount mismatch (less than 0.05%) within nearby dates",
        functionNameOnOff: reconciliationSetting.dncnMisMatchOnSameDate,
        functionNameVal: "dncnMisMatchOnSameDate",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on ID on one side and grouping on Date on other side",
        functionNameOnOff: reconciliationSetting.dncnIdDateMatching,
        functionNameVal: "dncnIdDateMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on Payment ID",
        functionNameOnOff: reconciliationSetting.dncnRollingIdMatching,
        functionNameVal: "dncnRollingIdMatching",
        showFunctionNameOnOff: true,
      },
      {
        documentType: "TDS",
        functionName: "",
        functionNameOnOff: false,
        functionNameVal: "",
        showFunctionNameOnOff: false,
      },
      {
        documentType: "",
        functionName: "Matching on Amount after grouping on date",
        functionNameOnOff: reconciliationSetting.tdsDateAmountMatching,
        functionNameVal: "tdsDateAmountMatching",
        showFunctionNameOnOff: true,
      },
      // {
      //   documentType: "",
      //   functionName: "Mismatch on Same Date",
      //   functionNameOnOff: true,
      //   showFunctionNameOnOff: true,
      // },
    ]);
  };

  const functionToSetReversalSettingOwn = (reversalSettingOwn) => {
    setRowsDataReversalOwn([
      {
        documentType: "Invoice",
        functionNameOnOff: reversalSettingOwn.reversalOfInvoiceWithReversal,
        functionNameVal: "reversalOfInvoiceWithReversal",
      },
      {
        documentType: "Payment",
        functionNameOnOff: reversalSettingOwn.reversalOfPaymentWithReversal,
        functionNameVal: "reversalOfPaymentWithReversal",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: reversalSettingOwn.reversalOfDncnWithReversal,
        functionNameVal: "reversalOfDncnWithReversal",
      },
      {
        documentType: "TDS",
        functionNameOnOff: reversalSettingOwn.reversalOfTdsWithReversal,
        functionNameVal: "reversalOfTdsWithReversal",
      },
    ]);
    setRowsDataInternalDocumentOwn([
      {
        documentType: "Invoice",
        functionNameOnOff: reversalSettingOwn.reversalOfInvoiceWithInternalDoc,
        functionNameVal: "reversalOfInvoiceWithInternalDoc",
      },
      {
        documentType: "Payment",
        functionNameOnOff: reversalSettingOwn.reversalOfPaymentWithInternalDoc,
        functionNameVal: "reversalOfPaymentWithInternalDoc",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: reversalSettingOwn.reversalOfDncnWithInternalDoc,
        functionNameVal: "reversalOfDncnWithInternalDoc",
      },
      {
        documentType: "TDS",
        functionNameOnOff: reversalSettingOwn.reversalOfTdsWithInternalDoc,
        functionNameVal: "reversalOfTdsWithInternalDoc",
      },
    ]);
    setRowsDataSelfReversalOwn([
      {
        documentType: "Invoice with Invoice",
        functionNameOnOff: reversalSettingOwn.invoiceIntraReversal,
        functionNameVal: "invoiceIntraReversal",
      },
      {
        documentType: "Payment with Payment",
        functionNameOnOff: reversalSettingOwn.paymentIntraReversal,
        functionNameVal: "paymentIntraReversal",
      },
      {
        documentType: "DNCN with DNCN",
        functionNameOnOff: reversalSettingOwn.dncnIntraReversal,
        functionNameVal: "dncnIntraReversal",
      },
      {
        documentType: "TDS with TDS",
        functionNameOnOff: reversalSettingOwn.tdsIntraReversal,
        functionNameVal: "tdsIntraReversal",
      },
      {
        documentType: "Internal Doc with Internal Doc",
        functionNameOnOff: reversalSettingOwn.internalDocIntraReversal,
        functionNameVal: "internalDocIntraReversal",
      },
      {
        documentType: "Reversal with Reversal",
        functionNameOnOff: reversalSettingOwn.reversalIntraReversal,
        functionNameVal: "reversalIntraReversal",
      },
    ]);
    setRowsDataDncnReversalOwn([
      {
        documentType: "Invoice DNCN Reversal",
        functionNameOnOff: reversalSettingOwn.invoiceDncnReversal,
        functionNameVal: "invoiceDncnReversal",
      },
      {
        documentType: "Payment DNCN Reversal",
        functionNameOnOff: reversalSettingOwn.paymentDncnReversal,
        functionNameVal: "paymentDncnReversal",
      },
      // {
      //   documentType: "Reference Number Reversal",
      //   functionNameOnOff: reversalSettingOwn.reversalOnReferenceNumber,
      //   functionNameVal: "reversalOnReferenceNumber",
      // },
      // {
      //   documentType: "Document Number Reversal",
      //   functionNameOnOff: reversalSettingOwn.reversalOnDocumentNumber,
      //   functionNameVal: "reversalOnDocumentNumber",
      // },
    ]);
    setRowsDataClearingDocumentOwn([
      {
        documentType: "Invoice DNCN Reversal",
        functionNameOnOff: reversalSettingOwn.invoiceDncnReversalOnClearingDocument,
        functionNameVal: "invoiceDncnReversalOnClearingDocument",
      },
      {
        documentType: "Payment DNCN Reversal",
        functionNameOnOff: reversalSettingOwn.paymentDncnReversalOnClearingDocument,
        functionNameVal: "paymentDncnReversalOnClearingDocument",
      },
      {
        documentType: "Payment TDS Reversal",
        functionNameOnOff: reversalSettingOwn.paymentTDSnReversalOnClearingDocument,
        functionNameVal: "paymentTDSnReversalOnClearingDocument",
      },
    ]);
  };

  const functionToSetReversalSettingBusinessPartner = (reversalSettingBusinessPartner) => {
    setRowsDataReversalBp([
      {
        documentType: "Invoice",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfInvoiceWithReversal,
        functionNameVal: "reversalOfInvoiceWithReversal",
      },
      {
        documentType: "Payment",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfPaymentWithReversal,
        functionNameVal: "reversalOfPaymentWithReversal",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfDncnWithReversal,
        functionNameVal: "reversalOfDncnWithReversal",
      },
      {
        documentType: "TDS",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfTdsWithReversal,
        functionNameVal: "reversalOfTdsWithReversal",
      },
    ]);

    setRowsDataInternalDocumentBp([
      {
        documentType: "Invoice",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfInvoiceWithInternalDoc,
        functionNameVal: "reversalOfInvoiceWithInternalDoc",
      },
      {
        documentType: "Payment",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfPaymentWithInternalDoc,
        functionNameVal: "reversalOfPaymentWithInternalDoc",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfDncnWithInternalDoc,
        functionNameVal: "reversalOfDncnWithInternalDoc",
      },
      {
        documentType: "TDS",
        functionNameOnOff: reversalSettingBusinessPartner.reversalOfTdsWithInternalDoc,
        functionNameVal: "reversalOfTdsWithInternalDoc",
      },
    ]);

    setRowsDataSelfReversalBp([
      {
        documentType: "Invoice with Invoice",
        functionNameOnOff: reversalSettingBusinessPartner.invoiceIntraReversal,
        functionNameVal: "invoiceIntraReversal",
      },
      {
        documentType: "Payment with Payment",
        functionNameOnOff: reversalSettingBusinessPartner.paymentIntraReversal,
        functionNameVal: "paymentIntraReversal",
      },
      {
        documentType: "DNCN with DNCN",
        functionNameOnOff: reversalSettingBusinessPartner.dncnIntraReversal,
        functionNameVal: "dncnIntraReversal",
      },
      {
        documentType: "TDS with TDS",
        functionNameOnOff: reversalSettingBusinessPartner.tdsIntraReversal,
        functionNameVal: "tdsIntraReversal",
      },
      {
        documentType: "Internal Doc with Internal Doc",
        functionNameOnOff: reversalSettingBusinessPartner.internalDocIntraReversal,
        functionNameVal: "internalDocIntraReversal",
      },
      {
        documentType: "Reversal with Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.reversalIntraReversal,
        functionNameVal: "reversalIntraReversal",
      },
    ]);

    setRowsDataDncnReversalBp([
      {
        documentType: "Invoice DNCN Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.invoiceDncnReversal,
        functionNameVal: "invoiceDncnReversal",
      },
      {
        documentType: "Payment DNCN Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.paymentDncnReversal,
        functionNameVal: "paymentDncnReversal",
      },
      // {
      //   documentType: "Reference Number Reversal",
      //   functionNameOnOff: reversalSettingBusinessPartner.reversalOnReferenceNumber,
      //   functionNameVal: "reversalOnReferenceNumber",
      // },
      // {
      //   documentType: "Document Number Reversal",
      //   functionNameOnOff: reversalSettingBusinessPartner.reversalOnDocumentNumber,
      //   functionNameVal: "reversalOnDocumentNumber",
      // },
    ]);
    setRowsDataClearingDocumentBp([
      {
        documentType: "Invoice DNCN Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.invoiceDncnReversalOnClearingDocument,
        functionNameVal: "invoiceDncnReversalOnClearingDocument",
      },
      {
        documentType: "Payment DNCN Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.paymentDncnReversalOnClearingDocument,
        functionNameVal: "paymentDncnReversalOnClearingDocument",
      },
      {
        documentType: "Payment TDS Reversal",
        functionNameOnOff: reversalSettingBusinessPartner.paymentTDSnReversalOnClearingDocument,
        functionNameVal: "paymentTDSnReversalOnClearingDocument",
      },
    ]);
  };

  const functionToSetRowsDataManyToMany1 = (riskyFunctionSetting) => {
    setRowsDataManyToMany1([
      {
        documentType: "Payment",
        functionNameOnOff: riskyFunctionSetting.manyManyInPayment,
        functionNameVal: "manyManyInPayment",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: riskyFunctionSetting.manyManyInDncn,
        functionNameVal: "manyManyInDncn",
      },
    ]);
    setRowsDataStairCaseApproach([
      {
        documentType: "Payment",
        functionNameOnOff: riskyFunctionSetting.paymentStairCaseApproach,
        functionNameVal: "paymentStairCaseApproach",
      },
      {
        documentType: "DNCN",
        functionNameOnOff: riskyFunctionSetting.dncnStairCaseApproach,
        functionNameVal: "dncnStairCaseApproach",
      },
      {
        documentType: "TDS",
        functionNameOnOff: riskyFunctionSetting.tdsStairCaseApproach,
        functionNameVal: "tdsStairCaseApproach",
      },
    ]);
    setRowsDataInvoiceSubstringMatch([
      {
        documentType: "Invoice (On Invoice Number)",
        functionNameOnOff: riskyFunctionSetting.invoiceSubstringMatchingEnabled,
        functionNameVal: "invoiceSubstringMatchingEnabled",
      },
    ]);
  };

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <Grid>
            <Card className="mb_20">
              <Grid className="m_40">
                <Grid className="mb_20">
                  <Grid container={true} className="">
                    <Grid item={true} md={6} className="algo_section_heading">
                      Reconciliation{" "}
                    </Grid>
                    <Grid md={6} className="center_align">
                      <Button
                        className="theme_outline_btn"
                        onClick={() => {
                          resetReconciliation();
                        }}
                        disabled={showLoadingIconResetReconciliation}
                      >
                        <LoadingIcon loading={showLoadingIconResetReconciliation} />
                        Reset to Default
                      </Button>
                      <Button
                        className="theme_btn ml_20"
                        onClick={() => {
                          updateReconcileFunctionSetting(
                            reconciliationSettingDataPayload,
                            SAVE,
                            RECONCILIATION,
                            setShowLoadingIconSaveReconciliation
                          );
                        }}
                        disabled={showLoadingIconSaveReconciliation}
                      >
                        <LoadingIcon loading={showLoadingIconSaveReconciliation} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="center_align">
                  <StaticTable
                    columns={columnDefinitionForReconciliation}
                    rows={rowsDataReconciliation}
                    sortEnable={false}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card className="mb_20">
              <Grid className="m_40">
                <Grid className="mb_20">
                  <Grid container={true} className="mt_40 mb_20">
                    <Grid item={true} md={4} className="algo_section_heading">
                      Reversal on amount only
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <span className="center_align_ver_horiz fw_600">Own</span>
                    <Button
                      className="theme_outline_btn ml_20"
                      onClick={() => {
                        setShowLoadingIconResetReversalSettingOwn(true);
                        resetReversalFunctionSetting(OWN);
                      }}
                      disabled={showLoadingIconResetReversalSettingOwn}
                    >
                      <LoadingIcon loading={showLoadingIconResetReversalSettingOwn} />
                      Reset to Default
                    </Button>

                    <Button
                      className="theme_btn ml_20"
                      onClick={() => {
                        setShowLoadingIconSaveReversalSettingOwn(true);
                        updateReversalFunctionSetting(reversalSettingOwnDataPayload, SAVE, OWN);
                      }}
                      disabled={showLoadingIconSaveReversalSettingOwn}
                    >
                      <LoadingIcon loading={showLoadingIconSaveReversalSettingOwn} />
                      Save
                    </Button>
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <span className="center_align_ver_horiz fw_600">Bp</span>
                    <Button
                      className="theme_outline_btn ml_20"
                      onClick={() => {
                        setShowLoadingIconResetReversalSettingBusinessPartner(true);
                        resetReversalFunctionSetting(BP);
                      }}
                      disabled={showLoadingIconResetReversalSettingBusinessPartner}
                    >
                      <LoadingIcon loading={showLoadingIconResetReversalSettingBusinessPartner} />
                      Reset to Default
                    </Button>

                    <Button
                      className="theme_btn ml_20"
                      onClick={() => {
                        setShowLoadingIconSaveReversalSettingBusinessPartner(true);
                        updateReversalFunctionSetting(reversalSettingBusinessPartnerDataPayload, SAVE, BP);
                      }}
                      disabled={showLoadingIconSaveReversalSettingBusinessPartner}
                    >
                      <LoadingIcon loading={showLoadingIconSaveReversalSettingBusinessPartner} />
                      Save
                    </Button>
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForReversalOwn}
                      rows={rowsDataReversalOwn}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable columns={columnDefinitionForReversalBp} rows={rowsDataReversalBp} sortEnable={false} />
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForInternalDocumentOwn}
                      rows={rowsDataInternalDocumentOwn}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForInternalDocumentBp}
                      rows={rowsDataInternalDocumentBp}
                      sortEnable={false}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForSelfReversalOwn}
                      rows={rowsDataSelfReversalOwn}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForSelfReversalBp}
                      rows={rowsDataSelfReversalBp}
                      sortEnable={false}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForDncnReversalOwn}
                      rows={rowsDataDncnReversalOwn}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForDncnReversalBp}
                      rows={rowsDataDncnReversalBp}
                      sortEnable={false}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForClearingDocumentOwn}
                      rows={rowsDataClearingDocumentOwn}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForClearingDocumentBp}
                      rows={rowsDataClearingDocumentBp}
                      sortEnable={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Card className="mb_20">
              <Grid className="m_40">
                <Grid className="mb_20">
                  <Grid container={true} className="mt_40">
                    <Grid item={true} md={6} className="algo_section_heading">
                      Risky Algorithms{" "}
                    </Grid>
                    <Grid md={6} className="center_align">
                      <Button
                        className="theme_outline_btn"
                        onClick={() => {
                          resetRiskyAlgorithms();
                        }}
                        disabled={showLoadingIconResetRiskyAlgorithms}
                      >
                        <LoadingIcon loading={showLoadingIconResetRiskyAlgorithms} />
                        Reset to Default
                      </Button>
                      <Button
                        className="theme_btn ml_20"
                        onClick={() => {
                          updateReconcileFunctionSetting(
                            riskyFunctionSettingDataPayload,
                            SAVE,
                            RISKY_ALGORITHMS,
                            setShowLoadingIconSaveRiskyAlgorithms
                          );
                        }}
                        disabled={showLoadingIconSaveRiskyAlgorithms}
                      >
                        <LoadingIcon loading={showLoadingIconSaveRiskyAlgorithms} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container={true} className="mb_20">
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForManyToMany1}
                      rows={rowsDataManyToMany1}
                      sortEnable={false}
                    />
                  </Grid>
                  <Grid item={true} md={6} className="center_align">
                    <StaticTable
                      columns={columnDefinitionForStairCaseApproach}
                      rows={rowsDataStairCaseApproach}
                      sortEnable={false}
                    />
                  </Grid>
                </Grid>
                <Grid className="center_align">
                  <StaticTable
                    columns={columnDefinitionForInvoiceSubstringMatch}
                    rows={rowsDataInvoiceSubstringMatch}
                    sortEnable={false}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};

export default AlgoCustomization;
