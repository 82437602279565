import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesChecklistAndNotes } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import Editor from "../../PartnerCommunication/Editor";
import "./Checklist.scss";
import DeleteChecklist from "./DeleteChecklist";

const AddChecklist = ({
  openAddChecklistModal,
  setOpenAddChecklistModal,
  setOpenChecklistModal,
  selectedBusinessPartnerName,
  checklistTitle,
  setChecklistTitle,
  checklistCategory,
  setChecklistCategory,
  checklistDynamicContent,
  setChecklistDynamicContent,
  isViewChecklistRef,
  addChecklist,
  deleteChecklist,
  addChecklistLoader,
  companyId,
  branchCode,
  businessPartnerSelectedRef,
}) => {
  const [openDeleteChecklist, setOpenDeleteChecklist] = useState(false);
  const selectedEmailTemplateContent: any = { emailBody: checklistDynamicContent };

  const maxTitleLength = 100;

  const handleChangeCategory = (e) => {
    setChecklistCategory(e.target.value);
  };

  const handleClose = () => {
    setOpenChecklistModal(true);
    setOpenAddChecklistModal(false);
    isViewChecklistRef.current = false;
  };
  return (
    <div>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openAddChecklistModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            height: "85vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isViewChecklistRef.current ? "Checklist Preview" : "Add Checklist"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 2 }}>
          <Grid sx={{ mb: 2 }}>
            <Typography className="add_checklist_title">Title*</Typography>
            <Grid className="d_flex">
              <TextField
                className="update-custom-border-radius"
                id="outlined-controlled"
                size="small"
                placeholder="Write anything"
                fullWidth
                value={checklistTitle}
                disabled={isViewChecklistRef.current}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length <= 100) {
                    setChecklistTitle(event.target.value);
                  }
                }}
              />
              {isViewChecklistRef.current && (
                <Button
                  style={{ padding: "6px 16px" }}
                  className="ml_16"
                  variant="outlined"
                  size="medium"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setOpenDeleteChecklist(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
            {!isViewChecklistRef.current && (
              <Typography className="add_checklist_title right_align">
                {checklistTitle.length}/{maxTitleLength}
              </Typography>
            )}
          </Grid>
          {!isViewChecklistRef.current && (
            <Grid sx={{ mb: 2 }}>
              <Typography className="add_checklist_title">Category*</Typography>
              <Grid>
                <Select
                  size="small"
                  className="update-custom-border-radius"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={checklistCategory}
                  onChange={(e) => {
                    handleChangeCategory(e);
                  }}
                  style={{ width: "300px", borderRadius: "4px" }}
                  renderValue={(value) => {
                    if (value === "PARTNER") {
                      return (
                        <span>
                          BP Specific (<span>{selectedBusinessPartnerName}</span>)
                        </span>
                      );
                    }
                    if (value === "RU") {
                      return "General";
                    }
                    return "Select Category";
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  <MenuItem value={"RU"}>General</MenuItem>
                  <MenuItem value={"PARTNER"}>
                    BP Specific (
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "200px", // Adjust based on remaining space within MenuItem
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        verticalAlign: "middle",
                      }}
                    >
                      {selectedBusinessPartnerName}
                    </span>
                    )
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          )}
          <Grid>
            <Editor
              dynamicContent={checklistDynamicContent}
              setDynamicContent={setChecklistDynamicContent}
              selectedTemplateName={""}
              selectedEmailTemplateContent={selectedEmailTemplateContent}
            />
          </Grid>
        </DialogContent>
        {!isViewChecklistRef.current && (
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button
              className={checklistTitle?.trim() === "" || !checklistCategory ? "grey_btn" : "green_btn"}
              disabled={checklistTitle?.trim() === "" || !checklistCategory || addChecklistLoader}
              style={{ width: "143px" }}
              startIcon={<LoadingIcon loading={addChecklistLoader} />}
              onClick={() => {
                //   handleClose();
                addChecklist();
                uiLogger(
                  uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_ADD_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_ADD_CLICK.message,
                    businessPartnerId: businessPartnerSelectedRef.current,
                  }
                );
              }}
            >
              Add
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {openDeleteChecklist && (
        <DeleteChecklist
          openDeleteChecklist={openDeleteChecklist}
          setOpenDeleteChecklist={setOpenDeleteChecklist}
          deleteChecklist={deleteChecklist}
        />
      )}
    </div>
  );
};

export default AddChecklist;
