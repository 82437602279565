import { Button, Card, CardContent, FormControl, Grid, InputAdornment } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import MonetaryInput from "../../Common/MonetaryInput";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const ReconRuleConfigurations = () => {
  const [showUploadErrorAlert, setShowUploadErrorAlert] = useState<boolean>(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const [toleranceForReconciliation, setToleranceForReconciliation] = useState<number>(0);

  useEffect(() => {
    getToleranceAmountForRecon();
  }, []);

  const getToleranceAmountForRecon = () => {
    try {
      Axios.get<any>("/api/GetAccountingToleranceForReconciliation")
        .then((response) => {
          if (response.data?.toleranceLimitForReconciliation) {
            setToleranceForReconciliation(response.data?.toleranceLimitForReconciliation);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} GetAccountingToleranceForReconciliation`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setLoader(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} GetAccountingToleranceForReconciliation`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const updateToleranceAmountForRecon = () => {
    setShowLoadingIcon(true);
    try {
      Axios.put<any>("/api/UpdateAccountingToleranceForReconciliation", {
        toleranceLimitForReconciliation: toleranceForReconciliation,
      })
        .then((response) => {
          setShowLoadingIcon(false);
          setApiErrorMsg(response.data.message);
          setShowUploadSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} UpdateAccountingToleranceForReconciliation`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIcon(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} UpdateAccountingToleranceForReconciliation`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <div>
      <ApiErrorCatch
        showUploadSuccessAlert={showUploadSuccessAlert}
        showUploadErrorAlert={showUploadErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid className="mb_15">
          <Card>
            <CardContent>
              <div className="d_flex">
                <div style={{ width: "90%" }}>
                  <div className="vertical_center_align">
                    <div className="fs_20 fw_600">Set the tolerance amount, used while matching the entries: </div>
                    <div className="ml_60">
                      <FormControl>
                        <MonetaryInput
                          value={toleranceForReconciliation ? toleranceForReconciliation.toLocaleString("en-IN") : ""}
                          setValue={(value) => setToleranceForReconciliation(value)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          }}
                          label="Tolerance Amount"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="vertical_center_align">
                  <Button
                    disabled={showLoadingIcon}
                    variant="contained"
                    onClick={() => updateToleranceAmountForRecon()}
                    className="theme_btn"
                  >
                    <LoadingIcon loading={showLoadingIcon} />
                    Save
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default ReconRuleConfigurations;
