import { Button, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import axios from "axios";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import "./Admin.scss";
import { DefaultResponse, ListLedgerResponse } from "./AdminInterfaces";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const SapData = () => {
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [actorId, setActorId] = useState<number>(null);
  const [businessPartnerCode, setBusinessPartnerCode] = useState<number>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);

  const columnDefinition = React.useMemo(
    () => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        minWidth: 50,
        maxWidth: 50,
        width: 50,
      },
      {
        Header: "LineId",
        accessor: "lineId",
        Sort: true,
        minWidth: 90,
        maxWidth: 90,
        width: 90,
      },
      {
        Header: "OwnId",
        accessor: "ownId",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "BusinessPartnerId",
        accessor: "businessPartnerId",
        Sort: true,
        minWidth: 180,
        maxWidth: 180,
        width: 180,
      },

      {
        Header: "DocumentNumber",
        accessor: "documentNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentType",
        accessor: "documentType",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentDate",
        accessor: "documentDate",
        Sort: true,
        minWidth: 350,
        maxWidth: 350,
        width: 350,
      },
      {
        Header: "PostingDate",
        accessor: "postingDate",
        Sort: true,
        minWidth: 350,
        maxWidth: 350,
        width: 350,
      },
      {
        Header: "Amount",
        accessor: "amount.amount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Reference",
        accessor: "reference",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
    ],
    []
  );

  const getListLedger = async () => {
    try {
      await axios
        .post<ListLedgerResponse>("/api/ListLedger", {
          ownId: actorId,
          businessPartnerCode,
        })
        .then((response) => {
          setRowsData(response.data.ledgers);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListLedger`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListLedger`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const downloadSapLedgerData = async () => {
    try {
      await axios
        .post<ListLedgerResponse>("/api/ListLedger", {
          ownId: actorId,
          businessPartnerCode,
        })
        .then((response) => {
          setRowsData(response.data.ledgers);
          const worksheet1 = XLSX.utils.json_to_sheet(response.data.ledgers);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet1, "Sap Ledger data.");
          XLSX.writeFile(workbook, `Sap Ledger data.xlsx`);
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListLedger`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListLedger`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const deleteSelectedRow = async () => {
    try {
      await axios
        .delete<DefaultResponse>("/api/DeleteSapData", {
          data: {
            ledgers: selectedRow,
          },
        })
        .then((response) => {
          getListLedger();
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DeleteSapData`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DeleteSapData`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Sap Data")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid style={{ paddingBottom: "40px" }}>
        <Grid className="center_align">
          <Card style={{ padding: "20px 40px", alignItems: "center" }} className="center_align">
            <TextField
              id="outlined-number"
              label="Own Id"
              type="number"
              value={actorId}
              onChange={(e: any) => setActorId(e.target.value)}
            />
            <TextField
              id="outlined-number"
              label="BusinessPartner code"
              type="number"
              style={{ margin: "0 40px" }}
              value={businessPartnerCode}
              onChange={(e: any) => setBusinessPartnerCode(e.target.value)}
            />
            <Button
              variant="contained"
              className="theme_btn"
              style={{
                width: "100px",
                height: "40px",
                margin: "auto 0",
              }}
              onClick={() => getListLedger()}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              className="theme_btn tallyVoucher_submit_btn"
              onClick={() => downloadSapLedgerData()}
            >
              Download
            </Button>
          </Card>
        </Grid>

        {rowsData.length > 0 ? (
          <Grid>
            <Grid
              className="center_align"
              style={{
                paddingRight: "0px",
                paddingLeft: "0px",
                marginTop: "25px",
                width: "100%",
              }}
            >
              <Table
                columns={columnDefinition}
                rows={rowsData}
                sortEnable={true}
                setSelectedRow={setSelectedRow}
                actorType="admin"
                showPagination={true}
              />
            </Grid>
            <Grid className="center_align">
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  margin: "30px",
                }}
                onClick={() => deleteSelectedRow()}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </LoggedInSkeleton>
  );
};

export default SapData;
