import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import LoadingIcon from "../Common/LoadingIcon";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { DownloadCBTemplateResponse, UpdateCBinBulkResponse } from "./AdminInterfaces";

const CBUpdate = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [userName, setUserName] = useState<string>(null);
  const [errUserName, setErrUserName] = useState<boolean>(false);
  const [filePayload, setFilePayload] = useState<any>(null);
  const [errFilePayload, setErrFilePayload] = useState<boolean>(false);
  const [showDownloadBtn, setShowDownloadBtn] = useState<boolean>(false);
  const [cbUpdateBase64, setCbUpdateBase64] = useState<string>(null);
  const [showLoadingIconApplyBtn, setShowLoadingIconApplyBtn] = useState(false);

  const updateCB = async () => {
    if (!userName) {
      setErrUserName(true);
    }
    if (filePayload === null) {
      setErrFilePayload(true);
    }
    if (userName !== null && filePayload !== null) {
      const bodyFormData = new FormData();
      bodyFormData.append("accountId", userName);
      bodyFormData.append("file", filePayload);
      try {
        await Axios.post<UpdateCBinBulkResponse>("/api/UpdateClosingBalanceInBulk", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            setCbUpdateBase64(response.data.workbookBufferBase64);
            setShowDownloadBtn(true);
            setShowSuccessAlert(true);
            setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceInBulk`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceInBulk`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    }
  };

  const downloadCBupdate = () => {
    const excelData = Buffer.from(cbUpdateBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "CBupdate.xlsx");
  };

  const getCBupdateExcelTemplate = () => {
    setShowLoadingIconApplyBtn(true);
    try {
      Axios.get<DownloadCBTemplateResponse>("/api/DownloadUploadClosingBalanceTemplate", {
        params: {
          accountId: userName,
        },
      })
        .then((response) => {
          setShowLoadingIconApplyBtn(false);
          const excelData = Buffer.from(response.data.templateWorkbook, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, "CBupdate template.xlsx");
        })
        .catch((error: any) => {
          setShowLoadingIconApplyBtn(false);

          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DownloadUploadClosingBalanceTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIconApplyBtn(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DownloadUploadClosingBalanceTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const downloadCBupdateTemplate = () => {
    getCBupdateExcelTemplate();
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("CB Update")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid className="center_align">
        <Card>
          <CardContent>
            <Grid className="vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Account Id :</label>
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  type="number"
                  required={true}
                  fullWidth={true}
                  label="Account Id"
                  name="userName"
                  error={errUserName}
                  helperText={errUserName ? "please enter Account Id" : ""}
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrUserName(false);
                    } else {
                      setErrUserName(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid className="flex_end mt_30">
              <Button
                startIcon={<LoadingIcon loading={showLoadingIconApplyBtn} />}
                disabled={showLoadingIconApplyBtn}
                onClick={() => {
                  downloadCBupdateTemplate();
                }}
                className="theme_btn"
                style={{ width: "200px" }}
              >
                Download Format
              </Button>
            </Grid>
            <Grid className=" vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Upload File :</label>
              </Grid>

              <Grid>
                <Button variant="contained" component="label" className="theme_btn" style={{ width: "200px" }}>
                  Upload File
                  <input
                    hidden={true}
                    accept=".xlsx"
                    multiple={true}
                    required={true}
                    type="file"
                    onChange={(e) => {
                      setErrFilePayload(false);
                      setFilePayload(e.target.files[0]);
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            {errFilePayload ? (
              <Grid className="center_align mt_10">
                <small style={{ color: "red" }}>please upload file</small>
              </Grid>
            ) : null}
            {filePayload ? (
              <Grid className="center_align mt_10">
                <small>{filePayload?.name}</small>
              </Grid>
            ) : null}
            <Grid className="center_align mt_30">
              <Button
                className="theme_outline_btn"
                style={{ width: "200px", border: "2px solid" }}
                onClick={() => {
                  updateCB();
                }}
              >
                Submit
              </Button>
            </Grid>
            {showDownloadBtn ? (
              <Grid className="center_align mt_30">
                <Button
                  className="theme_outline_btn"
                  style={{ width: "200px", border: "2px solid" }}
                  onClick={() => {
                    downloadCBupdate();
                  }}
                >
                  Download
                </Button>
              </Grid>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default CBUpdate;
