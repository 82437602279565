import { Button, Card, CardContent, Grid, TextField, Tooltip } from "@mui/material";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { AddUnpaidInvoicesInBulkResponse, DefaultResponse } from "./AdminInterfaces";

const UnpaidInvoice = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [accountID, setAccountID] = useState<string>("");
  const [errAccountID, setErrAccountID] = useState<boolean>(false);
  const [filePayload, setFilePayload] = useState<File[]>(null);
  const [errFilePayload, setErrFilePayload] = useState<boolean>(false);
  // const [_unpaidInvoiceTemplateBase64, setUnpaidInvoiceTemplateBase64] = useState<string>(null);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);
  const [showLoadingIconSubmit, setShowLoadingIconSubmit] = useState<boolean>(false);

  useEffect(() => {
    // getUnpaidInvoiceTemplate();
  }, []);

  const uploadUnpaidInvoice = () => {
    if (accountID.trim() === "") {
      setErrAccountID(true);
    }
    if (filePayload === null) {
      setErrFilePayload(true);
    }
    if (accountID.trim() !== "" && filePayload !== null) {
      const reader = new FileReader();
      let data = null;
      reader.readAsDataURL(filePayload[0]);
      reader.onloadend = (event) => {
        data = event.target.result;
        try {
          setShowLoadingIconSubmit(true);
          Axios.post<AddUnpaidInvoicesInBulkResponse>("/api/AddUnpaidInvoicesInBulk", {
            accountId: isNaN(parseInt(accountID)) ? null : parseInt(accountID),
            unpaidInvoicesDataBase64: data
              .toString()
              .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
          })
            .then((response) => {
              setShowLoadingIconSubmit(false);
              setShowSuccessAlert(true);
              setApiErrorMsg(response.data.message);
              apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
            })
            .catch((error) => {
              setShowLoadingIconSubmit(false);
              if (error?.response?.data?.message !== undefined) {
                const dataObj = error.response.data as ErrorResponse;
                setShowErrorAlert(true);
                setApiErrorMsg(dataObj.message);
                apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
              } else {
                setShowErrorAlert(true);
                setApiErrorMsg(`${defaultErrorMsg}AddUnpaidInvoicesInBulk`);
                apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
              }
            });
        } catch (error: any) {
          setShowLoadingIconSubmit(false);
          setShowErrorAlert(true);
          setApiErrorMsg(`${defaultErrorMsg}AddUnpaidInvoicesInBulk`);
          apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
        }
      };
    }
  };

  const getUnpaidInvoiceTemplate = () => {
    try {
      Axios.get<DefaultResponse>("/api/GetUnpaidInvoicesBulkUploadExcelTemplate", {
        params: {
          accountId: isNaN(parseInt(accountID)) ? null : parseInt(accountID),
        },
      })
        .then((response) => {
          // setUnpaidInvoiceTemplateBase64(response.data.response);
          const excelData = Buffer.from(response.data.response, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, "Unpaid invoice template.xlsx");
        })
        .catch((error: any) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetUnpaidInvoicesBulkUploadExcelTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetUnpaidInvoicesBulkUploadExcelTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // const downloadUnpaidInvoiceTemplate = () => {};

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Unpaid Invoice")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid className="center_align">
        <Card>
          <CardContent>
            <Grid className="vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Account ID :</label>
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  required={true}
                  fullWidth={true}
                  label="Account ID"
                  name="accountID"
                  error={errAccountID}
                  helperText={errAccountID ? "please enter Account ID" : ""}
                  value={accountID}
                  onChange={(e) => {
                    setAccountID(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrAccountID(false);
                    } else {
                      setErrAccountID(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid className="flex_end mt_30">
              <Button
                onClick={() => {
                  getUnpaidInvoiceTemplate();
                }}
                className="theme_btn"
                sx={{ width: 200 }}
              >
                Download Format
              </Button>
            </Grid>
            <Grid className=" vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Upload File :</label>
              </Grid>

              <Grid>
                <Button variant="contained" component="label" className="theme_btn" style={{ width: "200px" }}>
                  {showLoadingIconUpload ? null : (
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                  )}
                  {showLoadingIconUpload ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  Upload File
                  <input
                    key={filePayload ? filePayload.length : 0} // Add key prop to trigger onChange event for the same file selection
                    hidden={true}
                    accept=".xlsx"
                    multiple={true}
                    required={true}
                    type="file"
                    onChange={(e) => {
                      setErrFilePayload(false);
                      setShowLoadingIconUpload(true);
                      const selectedFiles: any = Array.from(e.target.files);
                      // Clear input value to trigger onChange event for the same file selection
                      e.target.value = null;
                      // Clear previously selected files
                      setFilePayload([]);
                      // Assign the new files to the filePayload state variable
                      setFilePayload(selectedFiles);
                      setShowLoadingIconUpload(false);
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            {errFilePayload ? (
              <Grid className="center_align mt_10">
                <small style={{ color: "red" }}>please upload file</small>
              </Grid>
            ) : null}
            {filePayload ? (
              <Grid className="center_align mt_10">
                <Tooltip
                  title={filePayload?.map((fileItem: any, fileInd: number) => {
                    return <div key={`${fileInd}-file`}>{fileItem?.name}</div>;
                  })}
                >
                  <small>
                    File name:{" "}
                    {filePayload?.[0]?.name?.substring(0, 16) + (filePayload?.[0]?.name?.length > 15 ? "..." : "")}
                    {filePayload?.length > 1 && <span> + {filePayload?.length - 1}</span>}
                  </small>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid className="center_align mt_30">
              <Button
                className="theme_outline_btn"
                style={{ width: "200px", border: "2px solid" }}
                onClick={() => {
                  uploadUnpaidInvoice();
                }}
              >
                {showLoadingIconSubmit ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Submit
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default UnpaidInvoice;
