import { Autocomplete, Button, Card, CardContent, TextField } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ClientType } from "src/entity/models/FrontendActor";
import { ErrorResponse } from "src/entity/recon-entity/ReconInterfaces";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const AccountSetting = () => {
  const [selectedClientType, setSelectedClientType] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const handleChange = (val) => {
    setSelectedClientType(val);
  };

  useEffect(() => {
    getClientType();
  }, []);
  const getClientType = () => {
    try {
      Axios.get<any>("/api/GetClientType ")
        .then((response) => {
          setSelectedClientType(response.data.clientType);
        })
        .catch((error) => {
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetClientType`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetClientType`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const updateClientType = () => {
    setShowLoadingIcon(true);
    try {
      Axios.post<any>("/api/UpdateClientType ", {
        clientType: selectedClientType,
      })
        .then((response) => {
          setShowLoadingIcon(false);
          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateClientType`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIcon(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateClientType`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  return (
    <div>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Card className="w_fit_content">
        <CardContent>
          <div className="vertical_center_align">
            <div>
              <h6 className="client_type_text">Set Client Type:</h6>
            </div>
            <div className="ml_40 mr_16">
              <Autocomplete
                onChange={(_evt, value) => handleChange(value)}
                style={{ width: "240px" }}
                className="update-custom-border-radius"
                value={selectedClientType}
                disableClearable
                options={Object.values(ClientType)}
                getOptionLabel={(option) => option}
                sx={{ width: 240 }}
                renderInput={(params) => <TextField {...params} label="Select Client Type" />}
              />
            </div>
            <div>
              <Button
                startIcon={<LoadingIcon loading={showLoadingIcon} />}
                className={selectedClientType === null ? "grey_btn" : "green_btn"}
                disabled={selectedClientType === null || showLoadingIcon}
                onClick={() => {
                  updateClientType();
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountSetting;
