import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useState } from "react";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import useFetch from "src/Components/Common/useFetch";
import ApiErrorCatch from "src/Components/Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { a11yProps, TabPanel } from "src/Components/Recon360/ReconSettings/ReconSettings";
import ReconDownloadReports from "./ReconDownloadReports";

const ReconAnalytics = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <LoggedInSkeleton topBarButtons={[]}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Download Reports" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="Template Tracking" {...a11yProps(1)} sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} key={0}>
            <ReconDownloadReports
              setShowSuccessAlert={setShowSuccessAlert}
              setApiErrorMsg={setApiErrorMsg}
              setShowErrorAlert={setShowErrorAlert}
            />
          </TabPanel>
          <TabPanel value={value} index={1} key={1}>
            <RuTemplateFeedback setApiErrorMsg={setApiErrorMsg} setShowErrorAlert={setShowErrorAlert} />
          </TabPanel>
        </Box>
      </div>
    </LoggedInSkeleton>
  );
};

export const RuTemplateFeedback = ({ setApiErrorMsg, setShowErrorAlert }) => {
  const [loading, setLoading] = useState(false);

  const DownloadTemplateTracking = () => {
    setLoading(true);
    useFetch<{ workBookBase64: string }>("/api/GetRuTemplateTracking", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (res) => {
        const excelData = Buffer.from(res.data.workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: res.headers["content-type"] || fileType });
        saveAs(blob, "TemplateTrackingOutput.xlsx");
        setLoading(false);
      },
    });
  };

  return (
    <Grid className="center_align fade_in">
      <Button
        variant="contained"
        className="theme_btn"
        sx={{
          m: "30px",
        }}
        onClick={() => DownloadTemplateTracking()}
        disabled={loading}
      >
        {loading ? (
          <span className="file-icon">
            <i className="fas fa-circle-notch fa-spin" />
          </span>
        ) : null}
        Download
      </Button>
    </Grid>
  );
};

export default ReconAnalytics;
