import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import uiLogger from "src/Utils/UiLogger";
import { uiLoggerName } from "../../../Utils/Recon/UiLogger/Constants";

interface StatusResetModalProps {
  isOpenResetModal: boolean;
  showLoadingIcon: boolean;
  handleCloseResetModal: () => void;
  handleReset: () => void;
}

const StatusResetWarningModal = (props: StatusResetModalProps) => {
  return (
    <div>
      <Dialog open={props.isOpenResetModal} aria-labelledby="StatusResetWarningModal">
        <DialogTitle>Are you sure you want to reset?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer">
          <div className="space_between">
            <Button variant="outlined" onClick={props.handleCloseResetModal} className="theme_outline_btn mr_10">
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={props.showLoadingIcon}
              className="theme_btn"
              onClick={() => {
                props.handleReset();
                uiLogger(uiLoggerName.ui_ResetStatus);
              }}
              sx={{ marginRight: 1 }}
            >
              <LoadingIcon loading={props.showLoadingIcon} />
              Reset
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusResetWarningModal;
