import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { NdButton } from "../../PartnerCommunication/PartnerPortal/CommonComponents";
import { StateDispatch } from "../../Recon360";

interface DeselectInvalidTasksModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  invalidTasksCount: number;
  totalTasksCount: number;
  deselectTasksModalTitle: string;
  deselectTasksModalInvalidTaskType: string;
}
export default function DeselectInvalidTasksModal({
  open,
  setOpen,
  invalidTasksCount,
  totalTasksCount,
  deselectTasksModalTitle,
  deselectTasksModalInvalidTaskType,
}: DeselectInvalidTasksModalProps) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>
        Unable to {deselectTasksModalTitle} {totalTasksCount} Tasks
      </DialogTitle>
      <DialogContent>
        <Alert severity="error">
          {invalidTasksCount} out of {totalTasksCount} tasks are already {deselectTasksModalInvalidTaskType}. De-select
          them to perform this action
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <NdButton
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          OKAY
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
