import { Box, Button, Chip, Grid, Menu, MenuItem } from "@mui/material";
import { DialogContent, FormLabel } from "@mui/material";
// import SendMailDialog from "@mui/material/Dialog";
import Axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { EmailSendCount, ErrorResponse, PDFTemplate } from "../../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../../Contexts/userContext";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import Editor from "./Editor";
import "./SendEmailTemplate.scss";

import { ChevronRight, Delete } from "@mui/icons-material";
import useFetch from "../../Common/useFetch";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "./CommonComponents";
import { PartnerCommsContext } from "./PartnerCommunicationContext";

export type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface ReplyEmailProps<T extends boolean> {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  base64Image: string;
  signature: string;
  sendSoleOrBulkMail: EmailSendCount.Sole;
  listAllBusinessPartnersWSR: () => Promise<void>;
  templateType: "LR" | "CB";
  mailTo?: string;
  files?: File[];
  cancelState: ReturnType<typeof useState<boolean | T>>;
  clickState: ReturnType<typeof useState<boolean | T>>;
}

const ReplyEmail = <T extends boolean>(props: ReplyEmailProps<T>) => {
  const { actor } = useContext(userContext);
  const [dynamicContent, setDynamicContent] = useState<string>("");
  const [files, setFiles] = useState<File[]>(props.files || []);
  const [pdfFiles, setPdfFiles] = useState<PDFTemplate[]>([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");

  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement>(null);
  const [cancelMail, setCancelMail] = props.cancelState;
  const [clickSendMail, setClickSendMail] = props.clickState;

  const openMenu = Boolean(anchorEl);
  const openSubMenu = Boolean(anchorMenuEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorMenuEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);
  const handleCloseSubMenu = () => setAnchorMenuEl(null);

  // Email States BEGIN
  const { emailBusinessPartnerId, emailDisplayName, fromEmailAddress, pdfTemplates } = useContext(PartnerCommsContext);
  const contextData = useContext(PartnerCommsContext);
  // Email States END

  useEffect(() => {
    if (cancelMail) {
      handleCloseMenu();
      setCancelMail(false);
    } else if (clickSendMail) {
      sendEmail();
    }
  }, [cancelMail, clickSendMail]);

  const handleClose = () => {
    setSelectedTemplateName("");
    setFiles([]);
    setPdfFiles([]);
  };

  const sendEmail = () => {
    let nonEmptyBody = "";

    if (!dynamicContent || dynamicContent === "<p><br /></p>") nonEmptyBody = null;
    else nonEmptyBody = dynamicContent;

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", nonEmptyBody);
    bodyFormData.append("emailSubject", null);
    // bodyFormData.append("templateName", "");
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", props.branchCode || DEFAULT_BRANCH_CODE);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (pdfFiles !== null && pdfFiles?.length > 0) {
      bodyFormData.append("pdfTemplates", pdfFiles.map((file) => file.templateId).join(", "));
    }

    console.log(contextData);

    try {
      Axios.post("/api/partnerCommunication/SendMail", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          setClickSendMail(false);
          handleClose();

          props.listAllBusinessPartnersWSR();

          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error SendMail", error?.response);
          setClickSendMail(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}SendMail`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error SendMail", error?.response);
      setClickSendMail(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}SendMail`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getPDFTemplateBody = (_templateId: number) => {
    return new Promise<{ templateBody: string }>((resolve, reject) => {
      useFetch<{ templateBody: string }>("/api/partnerCommunication/GetPdfTemplateBody", "POST", {
        setApiErrorMsg,
        setShowErrorAlert,
        setShowSuccessAlert,
        data: {
          templateId: _templateId,
          templateType: props.templateType,
        },
        thenCallBack: (_res) => {
          if (_res.data.templateBody) {
            resolve({ templateBody: _res.data.templateBody });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const getShortName = (name: string) => {
    if (!name.includes(".")) {
      if (name.length > 12) return name.substring(0, 6) + "..." + name.substring(name.length - 6);
      else return name;
    }

    const splittedName = name.split(".");
    const extension = splittedName[splittedName.length - 1];
    splittedName.splice(splittedName.length - 1);
    let fileName = splittedName.join("");
    if (fileName.length > 12) {
      fileName = fileName.substring(0, 6) + "..." + fileName.substring(fileName.length - 6);
    }
    return fileName + "." + extension;
  };

  return (
    <>
      <ApiErrorCatch
        apiErrorMsg={apiErrorMsg}
        showUploadErrorAlert={showErrorAlert}
        showUploadSuccessAlert={showSuccessAlert}
      />
      <DialogContent sx={{ py: 0, px: 0 }}>
        <div className="email_text">
          <Box>
            <p className="Email_Field">
              <FormLabel className="w_100">From : </FormLabel>
              {fromEmailAddress}
            </p>
            <p className="Email_Field">
              <FormLabel className="w_100">Display Name : </FormLabel>
              {emailDisplayName}
            </p>
            <div style={{ display: "grid", gridTemplateColumns: "30px 96%", padding: "3px", alignItems: "center" }}>
              <FormLabel className="w_100">To : </FormLabel>{" "}
              <Box display={"flex"} sx={{ whiteSpace: "nowrap", width: "inherit", gap: 0.5 }}>
                <span className="Email_Field">
                  {props.sendSoleOrBulkMail === EmailSendCount.Sole && props.mailTo ? props.mailTo : ""}
                </span>
              </Box>
            </div>
            <div className="mt_5" style={{ marginTop: 5 }}>
              <FormLabel>+ CC</FormLabel>
              <FormLabel className="ml_10">+ BCC</FormLabel>
            </div>
          </Box>

          <div>
            <Editor
              dynamicContent={dynamicContent}
              setDynamicContent={setDynamicContent}
              selectedTemplateName={selectedTemplateName}
              selectedEmailTemplateContent={null}
            />
            {/* <NewEditor /> */}
          </div>
          <div className="mt_10 pl_10" style={{ whiteSpace: "pre-wrap" }}>
            {props.signature !== null && props.signature !== undefined && props.signature !== "" ? (
              <span>{props.signature}</span>
            ) : (
              <>
                <p>
                  <FormLabel className="w_100">Thanks,</FormLabel>
                </p>
                <p>{actor?.name}</p>
                {/* <p>{props.emailDisplayName}</p> */}
              </>
            )}
          </div>
          <div className="Signature_Box">
            {props.base64Image && <img src={props.base64Image} style={{ width: "180px", height: "80px" }} />}
          </div>
          <div className="Footer_Content">
            <Box display={"flex"} gap={1} sx={{ overflowX: "auto" }}>
              {files !== null &&
                files.length > 0 &&
                files.map((_file, fileInd) => (
                  <Chip
                    title={files[fileInd].name}
                    key={files[fileInd].name}
                    label={getShortName(files[fileInd].name)}
                    onDelete={() => {
                      getShortName(files[fileInd].name);
                      files.splice(fileInd, 1);
                      setFiles([...files]);
                    }}
                    deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                    sx={{
                      maxWidth: 200,
                      "&, span": {
                        textOverflow: "unset !important",
                      },
                      background: "rgba(84, 28, 76, 0.1) !important",
                      "&:hover": {
                        background: "rgba(84, 28, 76, 0.2) !important",
                      },
                    }}
                  />
                ))}
              {pdfFiles?.length > 0 &&
                pdfFiles.map((_file, fileInd) => (
                  <Chip
                    title={_file.templateName}
                    key={_file.templateName + fileInd}
                    label={getShortName(_file.templateName)}
                    onDelete={() => {
                      getShortName(_file.templateName);
                      pdfFiles.splice(fileInd, 1);
                      setPdfFiles([...pdfFiles]);
                    }}
                    deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                    sx={{
                      maxWidth: 200,
                      "&, span": {
                        textOverflow: "unset !important",
                      },
                      background: "rgba(84, 28, 76, 0.1) !important",
                      "&:hover": {
                        background: "rgba(84, 28, 76, 0.2) !important",
                      },
                    }}
                  />
                ))}
            </Box>
            <Grid item={true} xs={12} sm={12} className="d_flex">
              {(props.sendSoleOrBulkMail === EmailSendCount.Sole ||
                props.sendSoleOrBulkMail === EmailSendCount.Bulk) && (
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <div>
                      <Button
                        variant="contained"
                        component="label"
                        className="theme_btn"
                        style={{ width: "100%" }}
                        size="small"
                        onClick={handleClick}
                      >
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        Attachment
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        sx={{ "&>*": { color: "#555}" } }}
                        keepMounted={true}
                      >
                        <MenuItem
                          onClick={() => {
                            if (inputRef.current) inputRef.current?.click();
                          }}
                        >
                          <span>Upload Local File</span>
                          <i
                            className="fa-solid fa-upload"
                            style={{ position: "absolute", right: 16, color: "#aaa" }}
                          />
                          <input
                            ref={inputRef}
                            hidden={true}
                            multiple={true}
                            type="file"
                            onChange={(e) => {
                              console.log(files, e.target.files);
                              setFiles([...files, ...Array.from(e.target.files)]);
                              handleCloseMenu();
                            }}
                          />
                        </MenuItem>
                        <MenuItem onClick={handleMenuClick}>
                          <span>Select PDF Template</span>
                          <ChevronRight sx={{ position: "relative", left: 4, color: "#aaa" }} />
                        </MenuItem>
                      </Menu>
                      <Menu
                        anchorEl={anchorMenuEl}
                        open={openSubMenu}
                        onClose={handleCloseSubMenu}
                        sx={{ "&>*": { color: "#555}" } }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {pdfTemplates.map((template, _i) => (
                          <MenuItem
                            key={template.templateName + _i}
                            onClick={async () => {
                              handleCloseMenu();
                              handleCloseSubMenu();
                              await getPDFTemplateBody(template.templateId);
                              if (!pdfFiles.includes(template)) setPdfFiles([...pdfFiles, template]);
                            }}
                          >
                            <span>{template.templateName}</span>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Grid>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </DialogContent>
      {/* </SendMailDialog> */}
    </>
  );
};

export default ReplyEmail;
