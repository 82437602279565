import axios, { AxiosError } from "axios";
import { ListAllActorData } from "src/Components/Admin/AdminInterfaces";

export const getAllActorsList = async () => {
  try {
    const response = await axios.get<{ data: ListAllActorData[] }>("/api/listAllActorDetails");
    return response.data.data;
  } catch (error) {
    const axiosError = error as AxiosError; // Type assertion
    throw new Error(axiosError.response?.data?.message || "An error occurred");
  }
};
