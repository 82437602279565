import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useState } from "react";
import useFetch2 from "src/Components/Common/useFetch";
import { TicketDetails } from "src/entity/recon-entity/ReconInterfaces";
import { formatDate } from "src/Utils/DateUtils";
import { Recon360Context, StateDispatch } from "../Recon360";
import { TICKET_PRIORITIES } from "./CreateTicket";
import UpdateBulkTasksConfirmationModal from "./UpdateBulkTasksConfirmationModal";
import { BackendTicketStatuses } from "./ViewTickets";

interface UpdateBulkTasksModalProps {
  showUpdateBulkTasksModal: boolean;
  setShowUpdateBulkTasksModal: StateDispatch<boolean>;
  selectedTasks: TicketDetails[];
  allUsers: { name: string; id: number }[];
  allStatuses: string[];
  fetchTickets: any;
  setShowBulkTasksModal: StateDispatch<boolean>;
  setClearRows: StateDispatch<boolean>;
}

export default function UpdateBulkTasksModal({
  showUpdateBulkTasksModal,
  setShowUpdateBulkTasksModal,
  selectedTasks,
  allUsers,
  allStatuses,
  fetchTickets,
  setShowBulkTasksModal,
  setClearRows,
}: UpdateBulkTasksModalProps) {
  const defaultMaker = { id: selectedTasks[0].makerId, name: selectedTasks[0].maker };
  let isMakerSameForAllTasks = true;
  const defaultChecker = { id: selectedTasks[0].checkerId, name: selectedTasks[0].checker };
  let isCheckerSameForAllTasks = true;
  const defaultApprover = {
    id: selectedTasks[0].approverId ? selectedTasks[0].approverId : null,
    name: selectedTasks[0].approver ? selectedTasks[0].approver : null,
  };
  let isApproverSameForAllTasks = true;
  const defaultStatus = selectedTasks[0].status;
  let isStatusSameForAllTasks = true;
  const defaultPriority = selectedTasks[0].priority;
  let isPrioritySameForAllTasks = true;
  const defaultDueDate = selectedTasks[0].dueDate ? new Date(selectedTasks[0].dueDate) : null;
  let isDueDateSameForAllTasks = true;
  const defaultRemarks = selectedTasks[0].remarks;
  let isRemarksSameForAllTasks = true;

  for (let i = 1; i < selectedTasks.length; i++) {
    const currentTask = selectedTasks.at(i);
    if (currentTask.makerId !== defaultMaker.id) isMakerSameForAllTasks = false;
    if (currentTask.checkerId !== defaultChecker.id) isCheckerSameForAllTasks = false;
    if (currentTask.approverId !== defaultApprover.id) isApproverSameForAllTasks = false;
    if (currentTask.status !== defaultStatus) isStatusSameForAllTasks = false;
    if (currentTask.priority !== defaultPriority) isPrioritySameForAllTasks = false;
    if (
      currentTask.dueDate !== null && defaultDueDate !== null
        ? new Date(currentTask.dueDate).getTime() !== defaultDueDate.getTime()
        : currentTask.dueDate !== null || defaultDueDate !== null
    )
      isDueDateSameForAllTasks = false;
    if (currentTask.remarks !== defaultRemarks) isRemarksSameForAllTasks = false;
  }

  const { setShowUploadErrorAlert, setShowUploadSuccessAlert, setApiErrorMsg } = useContext(Recon360Context);
  const [allowUpdatingMaker, setAllowUpdatingMaker] = useState(false);
  const [allowUpdatingChecker, setAllowUpdatingChecker] = useState(false);
  const [allowUpdatingApprover, setAllowUpdatingApprover] = useState(false);
  const [allowUpdatingStatus, setAllowUpdatingStatus] = useState(false);
  const [allowUpdatingPriority, setAllowUpdatingPriority] = useState(false);
  const [allowUpdatingDueDate, setAllowUpdatingDueDate] = useState(false);
  const [allowUpdatingRemarks, setAllowUpdatingRemarks] = useState(false);

  const [currentMaker, setCurrentMaker] = useState(isMakerSameForAllTasks ? defaultMaker : { id: null, name: null });
  const [currentChecker, setCurrentChecker] = useState(
    isCheckerSameForAllTasks ? defaultChecker : { id: null, name: null }
  );
  const [currentApprover, setCurrentApprover] = useState(
    isApproverSameForAllTasks ? defaultApprover : { id: null, name: null }
  );
  const [currentStatus, setCurrentStatus] = useState(isStatusSameForAllTasks ? defaultStatus : null);
  const [currentPriority, setCurrentPriority] = useState(isPrioritySameForAllTasks ? defaultPriority : null);
  const [currentDate, setCurrentDate] = useState(isDueDateSameForAllTasks ? defaultDueDate : null);
  const [currentRemarks, setCurrentRemarks] = useState(isRemarksSameForAllTasks ? defaultRemarks : null);

  const [dueDateErrorText, setDueDateErrorText] = useState("");
  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isUpdatingBulkTasks, setIsUpdatingBulkTasks] = useState(false);
  const selectedTasksIds = selectedTasks.map((task) => task.id);

  const updateBulkTasks = () => {
    setIsUpdatingBulkTasks(true);

    const payloadObj = fieldsToUpdate.reduce((obj, item) => {
      // Assign the value to the corresponding key
      obj[item.fieldId] = item.value;
      return obj;
    }, {});

    useFetch2("/api/tickets/UpdateBulkTicket", "POST", {
      setApiErrorMsg,
      setShowErrorAlert: setShowUploadErrorAlert,
      setShowSuccessAlert: setShowUploadSuccessAlert,
      data: {
        taskIds: selectedTasksIds,
        ...payloadObj,
      },
      thenCallBack: () => {
        setIsUpdatingBulkTasks(false);
        setShowBulkTasksModal(false);
        setShowUpdateBulkTasksModal(false);
        fetchTickets();
        setClearRows(true);
      },
      catchCallBack: () => {
        setShowBulkTasksModal(false);
        setShowUpdateBulkTasksModal(false);
        setIsUpdatingBulkTasks(false);
      },
    });
  };
  return (
    <>
      <Dialog open={showUpdateBulkTasksModal} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
          <Typography variant="h6">Update Bulk Tasks</Typography>
          <IconButton onClick={() => setShowUpdateBulkTasksModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Stack sx={{ padding: 2, gap: 2 }}>
          <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 1 }}>
            {selectedTasks.length} Tasks selected
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingMaker} onChange={(e) => setAllowUpdatingMaker(e.target.checked)} />
            <Autocomplete
              defaultValue={isMakerSameForAllTasks ? defaultMaker : null}
              disabled={!allowUpdatingMaker}
              onChange={(_, newValue) => {
                setCurrentMaker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Maker" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingChecker} onChange={(e) => setAllowUpdatingChecker(e.target.checked)} />
            <Autocomplete
              defaultValue={isCheckerSameForAllTasks ? defaultChecker : null}
              disabled={!allowUpdatingChecker}
              onChange={(_, newValue) => {
                setCurrentChecker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Checker" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingApprover} onChange={(e) => setAllowUpdatingApprover(e.target.checked)} />
            <Autocomplete
              defaultValue={isApproverSameForAllTasks ? (defaultApprover.id ? defaultApprover : null) : null}
              disabled={!allowUpdatingApprover}
              onChange={(_, newValue) => {
                setCurrentApprover(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Approver" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingStatus} onChange={(e) => setAllowUpdatingStatus(e.target.checked)} />
            <Autocomplete
              defaultValue={isStatusSameForAllTasks ? defaultStatus : null}
              disabled={!allowUpdatingStatus}
              onChange={(_, newValue) => {
                setCurrentStatus(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allStatuses.filter((status) => {
                if (
                  status === BackendTicketStatuses.Saved ||
                  status === BackendTicketStatuses.Cleared ||
                  status === BackendTicketStatuses.Discarded
                )
                  return false;
                return true;
              })}
              renderOption={(props, item) => (
                <li {...props} key={item}>
                  <ListItemText>{item}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Status" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingPriority} onChange={(e) => setAllowUpdatingPriority(e.target.checked)} />
            <Autocomplete
              defaultValue={isPrioritySameForAllTasks ? defaultPriority : null}
              disabled={!allowUpdatingPriority}
              onChange={(_, newValue) => {
                setCurrentPriority(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={TICKET_PRIORITIES}
              renderOption={(props, item) => (
                <li {...props} key={item}>
                  <ListItemText>{item}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Priority" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingDueDate} onChange={(e) => setAllowUpdatingDueDate(e.target.checked)} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disabled={!allowUpdatingDueDate}
                sx={{ "& fieldset": { borderRadius: "4px" } }}
                label="Due Date"
                value={currentDate}
                onError={(error) => {
                  if (error) setDueDateErrorText(error);
                  else setDueDateErrorText("");
                }}
                onChange={(date: Date) => setCurrentDate(date)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    helperText: dueDateErrorText && "Invalid Date",
                    sx: { width: "100%" },
                    size: "small",

                    InputProps: {
                      style: { borderRadius: "4px" },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingRemarks} onChange={(e) => setAllowUpdatingRemarks(e.target.checked)} />
            <TextField
              defaultValue={isRemarksSameForAllTasks ? defaultRemarks : null}
              disabled={!allowUpdatingRemarks}
              InputProps={{
                style: {
                  borderRadius: "4px",
                },
              }}
              sx={{ width: "100%" }}
              size="small"
              label="Remarks"
              onChange={(e) => {
                const remarks = e.target?.value.trim();
                setCurrentRemarks(remarks.length > 0 ? remarks : null);
              }}
              multiline
              maxRows={4}
            />
          </Box>
        </Stack>
        <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
          <Button
            disabled={
              (!allowUpdatingMaker &&
                !allowUpdatingChecker &&
                !allowUpdatingApprover &&
                !allowUpdatingStatus &&
                !allowUpdatingPriority &&
                !allowUpdatingDueDate &&
                !allowUpdatingRemarks) ||
              (allowUpdatingMaker && currentMaker.id === null) ||
              (allowUpdatingChecker && currentChecker.id === null) ||
              (allowUpdatingStatus && currentStatus === null) ||
              (allowUpdatingPriority && currentPriority === null) ||
              dueDateErrorText.length > 0
            }
            variant="contained"
            sx={{
              background: "#27B27C",
              padding: "8px 22px",
              color: "#FFF",
              borderRadius: "4px !important",
              fontWeight: 500,
              fontSize: "15px",
              "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
            }}
            onClick={() => {
              const fieldsToUpdateArray = [];
              if (allowUpdatingMaker) {
                fieldsToUpdateArray.push({
                  field: "Maker",
                  fieldId: "makerId",
                  name: currentMaker.name,
                  value: currentMaker.id,
                });
              }
              if (allowUpdatingChecker) {
                fieldsToUpdateArray.push({
                  field: "Checker",
                  fieldId: "checkerId",
                  name: currentChecker.name,
                  value: currentChecker.id,
                });
              }
              if (allowUpdatingApprover) {
                fieldsToUpdateArray.push({
                  field: "Approver",
                  fieldId: "approverId",
                  name: currentApprover.name,
                  value: currentApprover.id,
                });
              }
              if (allowUpdatingStatus) {
                fieldsToUpdateArray.push({
                  field: "Status",
                  fieldId: "status",
                  name: currentStatus,
                  value: currentStatus,
                });
              }
              if (allowUpdatingPriority) {
                fieldsToUpdateArray.push({
                  field: "Priority",
                  fieldId: "priority",
                  name: currentPriority,
                  value: currentPriority,
                });
              }
              if (allowUpdatingDueDate) {
                fieldsToUpdateArray.push({
                  field: "Due Date",
                  fieldId: "dueDate",
                  name: currentDate ? formatDate(currentDate) : null,
                  value: currentDate,
                });
              }
              if (allowUpdatingRemarks) {
                fieldsToUpdateArray.push({
                  field: "Remarks",
                  fieldId: "remarks",
                  name: currentRemarks,
                  value: currentRemarks,
                });
              }

              setFieldsToUpdate(fieldsToUpdateArray);
              setShowConfirmationModal(true);
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>
      <UpdateBulkTasksConfirmationModal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        title={`Are you sure you want to Update ${selectedTasks.length} Tasks?`}
        content={
          <>
            {`This action will update following for ${selectedTasks.length} tasks:`}
            {fieldsToUpdate.map((item, idx) => {
              return <p key={item.field}>{`${idx + 1}. ${item.field} : ${item.name}`}</p>;
            })}
          </>
        }
        updateBulkTasks={updateBulkTasks}
        isLoading={isUpdatingBulkTasks}
      />
    </>
  );
}
