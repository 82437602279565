import { Close, Download } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useMemo } from "react";
import { BulkMailStatusResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../../Contexts/userContext";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";

interface BulkMailReportDialogProps {
  open: boolean;
  setOpen: any;
  emailResponseMsg: string;
  mailFailedUsers: BulkMailStatusResponse["listOfFailedUsers"];
  workbook: BulkMailStatusResponse["failedEmailsWorkbook"];
}

const base64ToBlob = (base64str: string, fileType?: string) => {
  const excelData = Buffer.from(base64str, "base64");
  const _fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const blob = new Blob([excelData], { type: fileType || _fileType });
  return blob;
};

const BulkMailReportDialog = (props: BulkMailReportDialogProps) => {
  const { actor } = useContext(userContext);
  const columnDefsErrorReport = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: "Partner Name",
        accessorKey: "businessPartnerName",
        size: 300,
      },
      {
        header: "Error Reason",
        accessorKey: "reason",
        size: 510,
      },
    ],
    []
  );

  const rawRows = props?.mailFailedUsers || [];

  const processedRows: {
    partnerName: string;
    reasons: string[];
  }[] = [];

  rawRows.forEach((row) => {
    row?.businessPartnerNames.forEach((_name) => {
      const _found = processedRows.find((bpAndError) => bpAndError.partnerName === _name);
      if (_found) _found.reasons.push(row?.reason);
      else
        processedRows.push({
          partnerName: _name,
          reasons: [row?.reason],
        });
    });
  });

  const finalRows = processedRows.map((row) => ({
    businessPartnerName: row.partnerName,
    reason: row.reasons.join(", "),
  }));

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Bulk Mail Status Report</DialogTitle>
        <IconButton
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>{props.emailResponseMsg}</FormLabel>
        <FormLabel>Kindly Resolve the Errors and try again.</FormLabel>

        <BpDashboardTable columns={columnDefsErrorReport} rows={finalRows} />
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          className="theme_btn"
          startIcon={<Download />}
          onClick={() => {
            if (props.workbook) {
              const blob = base64ToBlob(props.workbook);
              if (blob) saveAs(blob, actor?.name + " - BulkMailErrorReport.xlsx");
            }
          }}
        >
          Download Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkMailReportDialog;
