import { AttachFile, Delete, DownloadRounded, ErrorOutline, Visibility } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useFetch2 as useFetch } from "src/Components/Common/useFetch";
import FormatDate from "src/Utils/DateUtils";
import { uiLoggerLrPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { IFrame } from "../MailThreadDialog";
import { LoadingPage } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import { getShortName } from "./CommonComponents";

export type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export interface MailBoxProps {
  mailSubject: string;
  mailBody: string;
  mailFrom: string;
  mailDate: string;
}

export const MailBox: React.FC<MailBoxProps> = ({ mailSubject, mailBody, mailFrom, mailDate }) => {
  return (
    <Box
      className={$.BR_fix + " card_mail"}
      sx={{
        border: "1px solid #D7D7D7",
        background: "#fafafa",
      }}
    >
      <Box px={2} py={1}>
        <Typography variant="subtitle1" fontWeight={700}>
          Subject: {mailSubject}
        </Typography>
      </Box>
      <Divider />
      <Stack sx={{ p: 2, gap: 1 }}>
        <Box className={$.BR_fix + " mail_from space_between"} p={1} sx={{ bgcolor: "#fff" }}>
          <Box className="d_flex" sx={{ gap: 1, alignItems: "center" }}>
            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
              From:
            </Typography>
            <Typography variant="body2">{mailFrom}</Typography>
          </Box>
          <Typography variant="body2">{FormatDate(mailDate, "DD-MM-YYYY, h:mmA").toString()}</Typography>
        </Box>
        <Box className={$.BR_fix + " mail_body"} sx={{ bgcolor: "#fff", minHeight: 185, p: 1 }}>
          <Typography variant="body2">
            <IFrame referrerPolicy="no-referrer" content="">
              <div
                className="unsafeHtmlBody"
                dangerouslySetInnerHTML={{ __html: mailBody }}
                style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: "#000C" }}
              />
            </IFrame>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export type UploadFileType = "pdf" | "excel";

export type UploadedLedgerData = {
  isIssueRaisedInLedger: boolean;
  issueMessage: string;
  uploadedLedgers: string[];
};

export type BasicFile = {
  name: string;
  size?: number;
  type?: string;
};

export const CommonUploaderBox = ({
  setFileNames,
  viewMode,
  viewModeLedgerNames,
}: {
  setFileNames?: StateDispatch<string[]>;
  viewMode?: boolean;
  viewModeLedgerNames?: string[];
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const fileUploader = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<BasicFile[]>(
    viewModeLedgerNames?.length > 0 ? viewModeLedgerNames?.map((name) => ({ name })) : []
  );
  // const [filesModified, setFilesModified] = useState(false);
  const [issueRaisedInLedger, setIssueRaisedInLedger] = useState(false);
  const [issueMessage, setIssueMessage] = useState("");

  // console.log("CommonUploaderBox", { files, viewModeLedgerNames });

  const titleBody = {
    pdf: {
      title: "Upload Ledger",
      body: "Upload the Ledger in excel(.xlsx) format",
    },
  };

  useEffect(() => {
    if (setFileNames) setFileNames(files.map((file) => file.name));
  }, [files]);

  useEffect(() => {
    GetUploadedLedgers();
  }, []);

  const GetUploadedLedgers = () =>
    useFetch<{ details: UploadedLedgerData }>("/api/ledgerRequestPortal/GetUploadedLedgers", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { details } = res.data;

        if (details?.uploadedLedgers) {
          setFiles(details?.uploadedLedgers.map((fileName) => ({ name: fileName })));
        }
        if (details?.isIssueRaisedInLedger) {
          setIssueRaisedInLedger(true);
          setIssueMessage(issueMessage);
        }
      },
    });

  const UploadLedger = (files: File[]) => {
    const bodyFormData = new FormData();

    bodyFormData.append("data", encryptedData);

    for (const item of files) {
      bodyFormData.append("file", item);
    }

    useFetch<{ uploadedLedgers: string[] }>("/api/ledgerRequestPortal/UploadLedger", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: bodyFormData,
      thenCallBack: (_res) => {
        setFiles(
          _res.data?.uploadedLedgers?.length > -1 ? _res.data.uploadedLedgers?.map((name) => ({ name })) : files
        );
        // clear issue in ledger, if re-uploaded ledger
        if (issueRaisedInLedger) {
          setIssueRaisedInLedger(false);
          setIssueMessage("");
        }
      },
    });
  };

  const ViewUploadedLedger = (fileName: string) => {
    const foundFile = files.find((f) => f.name === fileName);

    useFetch<{ signedUrl?: string }>("/api/ledgerRequestPortal/ViewUploadedLedger", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      config: {
        params: {
          data: encryptedData,
          fileName: fileName,
          contentType: foundFile?.type,
        },
      },
      thenCallBack: (_res) => {
        const link = document.createElement("a");
        link.href = _res.data.signedUrl;
        link.download = fileName;
        link.target = "_blank";
        link.referrerPolicy = "no-referrer";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  const DeleteUploadedLedger = (fileName: string) => {
    useFetch("/api/ledgerRequestPortal/DeleteUploadedLedger", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        data: encryptedData,
        fileName: fileName,
      },
      thenCallBack: (_res) => {
        setFiles(files.filter((f) => f.name !== fileName));
        if (files.length < 2 && issueRaisedInLedger) {
          setIssueRaisedInLedger(false);
          setIssueMessage("");
        }
      },
    });
  };

  return (
    <>
      <ApiErrorCatch
        apiErrorMsg={apiErrorMsg}
        showUploadErrorAlert={showErrorAlert}
        showUploadSuccessAlert={showSuccessAlert}
      />
      <Suspense fallback={<LoadingPage noHeader={true} />}>
        {/* Uploader Box */}
        <Box className={$.uploader_box + " " + (issueRaisedInLedger ? $.error : "")}>
          <Box className="vertical_center_align" p={2} gap={1}>
            <Box className="left" mr={"auto"}>
              <Typography variant="subtitle1" fontWeight={700} color={"#000"}>
                {titleBody["pdf"].title}
              </Typography>
              <Typography variant="body2">{titleBody["pdf"].body}</Typography>
              {issueRaisedInLedger && (
                <Box className="d_flex" gap={"12px"} mt={2}>
                  <ErrorOutline color="error" />
                  <div className="issue_box">
                    <Typography color="error" fontWeight={500}>
                      Issue Reported
                    </Typography>
                    <Typography className="" fontSize={14}>
                      {issueMessage}
                    </Typography>
                  </div>
                </Box>
              )}
            </Box>
            {!viewMode && (
              <Button
                startIcon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                onClick={() => {
                  fileUploader.current && fileUploader.current.click();
                  uiLoggerPortal(uiLoggerLrPortal.ui_PpLrUploadClick, encryptedData);
                }}
                sx={{ minWidth: 95 }}
              >
                Upload
              </Button>
            )}
          </Box>
          {files.length > 0 &&
            files.map((file, index) => (
              <CommonFileViewDeleteBox
                key={file.name + index}
                fileName={file.name}
                viewFn={ViewUploadedLedger}
                delFn={DeleteUploadedLedger}
                viewMode={viewMode}
              />
            ))}
        </Box>
        <input
          ref={fileUploader}
          name="ConfirmDocUploader"
          type="file"
          multiple={true}
          onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
          onChange={(_e) => {
            const _files = Array.from(_e.target.files);
            UploadLedger(_files);
          }}
          hidden
          style={{ visibility: "hidden" }}
          accept=".xlsx,.xls,.pdf"
        />
      </Suspense>
    </>
  );
};

export const CommonFileViewDeleteBox = ({
  fileName,
  viewFn,
  delFn: deleteFn,
  viewMode,
}: {
  fileName: string;
  viewFn: (fileName: string) => void;
  delFn?: (fileName: string) => void;
  viewMode?: boolean;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [openDelDialog] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    if (openDelDialog) setDisabled(false);
  }, [openDelDialog]);

  const fileTypePdf = getShortName(fileName)[1].toLowerCase() === "pdf";

  return (
    <>
      <Box
        className="d_flex"
        sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3", ":last-child": { borderRadius: "0 0 4px 4px" } }}
        p={2}
        gap={1}
      >
        <Chip
          sx={{ mr: "auto", borderRadius: "8px" }}
          variant="outlined"
          label={
            <div className="vertical_center_align">
              <AttachFile fontSize="small" />
              <Typography>{fileName}</Typography>
            </div>
          }
        />
        <Button
          className={$.BR_fix}
          variant="outlined"
          startIcon={fileTypePdf ? <Visibility /> : <DownloadRounded />}
          onClick={() => {
            viewFn(fileName);
            uiLoggerPortal(uiLoggerLrPortal.ui_PpLrViewClick, encryptedData);
          }}
        >
          {fileTypePdf ? `View` : `Download`}
        </Button>
        {!viewMode && (
          <Button
            className={$.BR_fix}
            variant="outlined"
            startIcon={<Delete />}
            color="error"
            onClick={() => {
              deleteFn(fileName);
              uiLoggerPortal(uiLoggerLrPortal.ui_PpLrDeleteClick, encryptedData);
            }}
          >
            Delete
          </Button>
        )}
      </Box>
    </>
  );
};
