import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

const LendingTopBanner = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Box
        className="d_flex center_align"
        sx={{
          background: "linear-gradient(180deg, #000 0%, #200D42 18.52%, #4F21A1 56.09%, #A46EDB 100.53%)",
          minHeight: 56,
          position: "relative",
        }}
        hidden={!open}
        visibility={open ? "visible" : "hidden"}
      >
        <Box className="internal_div space_between vertical_center_align" minWidth={900}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            Boost your business with our smart working capital solutions - swift, simple, and tailored just for you!
          </Typography>
          <a href="https://forms.gle/HErhQe1rxsyCgsnV7" target="_blank" rel="noreferrer" style={{ cursor: "pointer" }}>
            <button
              type="button"
              style={{
                display: "inline-flex",
                padding: "var(--1, 8px) 20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "var(--borderRadius, 4px)",
                borderRadius: "6px",
                border: "2px solid #B7B7B7",
                background: "#FFF",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Roboto",
                cursor: "pointer",
                fontSize: 12,
              }}
            >
              🎉 View Offerings
            </button>
          </a>
        </Box>
        <IconButton onClick={() => setOpen(false)} sx={{ position: "absolute", right: 12 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask
              id="mask0_2685_4220"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2685_4220)">
              <path
                d="M8.4 17L7 15.6L10.6 12L7 8.42499L8.4 7.02499L12 10.625L15.575 7.02499L16.975 8.42499L13.375 12L16.975 15.6L15.575 17L12 13.4L8.4 17Z"
                fill="white"
                fillOpacity="0.6"
              />
            </g>
          </svg>
        </IconButton>
      </Box>
    </>
  );
};

export default LendingTopBanner;
