import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { ErrorResponse } from "src/entity/recon-entity/ReconInterfaces";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

/**
 *
 * @interface DefaultRes
 * @key response?: string
 * @key message?: string
 */
interface DefaultRes {
  response?: string;
  message?: string;
  [key: string]: any;
}

/**
 * PURE Function *USE FETCH* to handle Axios Fetch operations with Alerts.
 * Supports GET & POST Methods Currently
 *
 * @export
 * @template T
 * @param {string} uri
 * @param {("GET" | "POST")} [method="GET"]
 * @param {{
 *     setApiErrorMsg: Dispatch<SetStateAction<string>>;
 *     setShowErrorAlert: Dispatch<SetStateAction<boolean>>;
 *     setShowSuccessAlert?: Dispatch<SetStateAction<boolean>>;
 *     config?: {};
 *     logResponse?: boolean;
 *     thenCallBack?: (response: AxiosResponse<T>) => void;
 *     catchCallBack?: (error: AxiosError<T>) => void;
 *     errorCallback?: (error: any) => void;
 *   }} prop
 */

/**
 * @deprecated
 * method deprecated and superseded by method useFetch2
 */

export function useFetch<T extends DefaultRes>(
  uri: string,
  method: "GET" | "POST" | "PUT" = "GET",
  prop: {
    setApiErrorMsg: Dispatch<SetStateAction<string>>;
    setShowErrorAlert: Dispatch<SetStateAction<boolean>>;
    setShowSuccessAlert?: Dispatch<SetStateAction<boolean>>;
    config?: Record<string, unknown> | AxiosRequestConfig;
    logResponse?: boolean;
    thenCallBack?: (response: AxiosResponse<T & DefaultRes>) => void;
    catchCallBack?: (error: AxiosError<T & DefaultRes>) => void;
    errorCallback?: (error: any) => void;
  }
) {
  const MethodMap = {
    GET: Axios.get,
    POST: Axios.post,
    PUT: Axios.put,
  };
  try {
    MethodMap[method]<T & DefaultRes>(`${uri}`, prop.config)
      .then((response) => {
        if (prop.logResponse) console.log("response", response);

        if (prop.thenCallBack) prop.thenCallBack(response);

        if (prop.setShowSuccessAlert) {
          prop.setShowSuccessAlert(true);
          prop.setApiErrorMsg(response.data.message || response.data.response);
          apiSuccessErrorAlertSetTimeout(prop.setShowSuccessAlert, prop.setApiErrorMsg);
        }
      })
      .catch((error: AxiosError<T & any>) => {
        console.log(`error ${uri}`, error?.response || error);

        if (prop.catchCallBack) prop.catchCallBack(error);

        if (error?.response?.data?.message !== undefined) {
          const dataObj = error.response.data as ErrorResponse;
          prop.setShowErrorAlert(true);
          prop.setApiErrorMsg(dataObj.message);
          apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
        } else {
          prop.setShowErrorAlert(true);
          prop.setApiErrorMsg(`${defaultErrorMsg} ${uri}`);
          apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
        }
      });
  } catch (error: any) {
    console.log(`error ${uri}`, error?.response || error);

    if (prop.errorCallback) prop.errorCallback(error);

    prop.setShowErrorAlert(true);
    prop.setApiErrorMsg(`${defaultErrorMsg} ${uri}`);
    apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
  }
}

type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface PropertyBase<T extends DefaultRes> {
  readonly method?: FetchMethod;
  setApiErrorMsg: Dispatch<SetStateAction<string>>;
  setShowErrorAlert: Dispatch<SetStateAction<boolean>>;
  setShowSuccessAlert?: Dispatch<SetStateAction<boolean>>;
  logResponse?: boolean;
  thenCallBack?: (response: AxiosResponse<T & DefaultRes>) => void;
  catchCallBack?: (error: AxiosError<T & DefaultRes>) => void;
  errorCallback?: (error: any) => void;
}

type RecordType = {
  [key: string]: unknown;
  params?: never;
};

export interface NonDataMethods<T, M extends FetchMethod> extends PropertyBase<T> {
  readonly method?: M;
  config?: AxiosRequestConfig;
}
export interface DataMethods<T, M extends FetchMethod> extends PropertyBase<T> {
  readonly method?: M;
  config?: Omit<AxiosRequestConfig, "params">;
  data?: RecordType | FormData | URLSearchParams;
}

interface UseFetch {
  <R>(uri: string, method: "GET", props: NonDataMethods<R, "GET">): Promise<void>;
  <R>(uri: string, method: "DELETE", props: NonDataMethods<R, "DELETE">): Promise<void>;

  <R>(uri: string, method: "PUT", props: DataMethods<R, "PUT">): Promise<void>;
  <R>(uri: string, method: "POST", props: DataMethods<R, "POST">): Promise<void>;
}

export const useFetch2: UseFetch = async <T extends DefaultRes>(
  uri: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  prop: {
    setApiErrorMsg: Dispatch<SetStateAction<string>>;
    setShowErrorAlert: Dispatch<SetStateAction<boolean>>;
    setShowSuccessAlert?: Dispatch<SetStateAction<boolean>>;
    data?: Record<string, unknown> | FormData | URLSearchParams;
    config?: Exclude<AxiosRequestConfig, "params">;
    logResponse?: boolean;
    thenCallBack?: (response: AxiosResponse<T & DefaultRes>) => void;
    catchCallBack?: (error: AxiosError<T & DefaultRes>) => void;
    errorCallback?: (error: any) => void;
  }
) => {
  const MethodMap = {
    GET: Axios.get,
    POST: Axios.post,
    PUT: Axios.put,
    DELETE: Axios.delete,
  };
  try {
    let promise: Promise<AxiosResponse<T & DefaultRes>>;
    if (method === "POST" || method === "PUT") {
      promise = MethodMap[method]<T & DefaultRes>(uri, prop.data, prop.config);
    } else {
      promise = MethodMap[method]<T & DefaultRes>(uri, prop.config);
    }
    return promise
      .then((response) => {
        if (prop.logResponse) console.log("response", response);

        if (prop.thenCallBack) prop.thenCallBack(response);

        if (prop.setShowSuccessAlert) {
          prop.setShowSuccessAlert(true);
          prop.setApiErrorMsg(response.data.message || response.data.response);
          apiSuccessErrorAlertSetTimeout(prop.setShowSuccessAlert, prop.setApiErrorMsg);
        }
      })
      .catch((error: AxiosError<T & any>) => {
        console.log(`error ${uri}`, error?.response || error);

        if (prop.catchCallBack) prop.catchCallBack(error);

        if (error?.response?.data?.message !== undefined) {
          const dataObj = error.response.data as ErrorResponse;
          prop.setShowErrorAlert(true);
          prop.setApiErrorMsg(dataObj.message);
          apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
        } else {
          prop.setShowErrorAlert(true);
          prop.setApiErrorMsg(`${defaultErrorMsg} ${uri}`);
          apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
        }
      });
  } catch (error: any) {
    console.log(`error ${uri}`, error?.response || error);

    if (prop.errorCallback) prop.errorCallback(error);

    prop.setShowErrorAlert(true);
    prop.setApiErrorMsg(`${defaultErrorMsg} ${uri}`);
    apiSuccessErrorAlertSetTimeout(prop.setShowErrorAlert, prop.setApiErrorMsg);
  }
};

export default useFetch2;
