export enum uiLoggerName {
  ui_OwnConfigOpen = "ui-OwnConfigOpen",
  ui_OwnConfigClose = "ui-OwnConfigClose",
  ui_BpConfigOpen = "ui-BpConfigOpen",
  ui_BpConfigClose = "ui-BpConfigClose",
  ui_OwnSanitizerOpen = "ui-OwnSanitizerOpen",
  ui_OwnSanitizerClose = "ui-OwnSanitizerClose",
  ui_BpSanitizerOpen = "ui-BpSanitizerOpen",
  ui_BpSanitizerClose = "ui-BpSanitizerClose",
  ui_OwnAutoConfigOpen = "ui-OwnAutoConfigOpen",
  ui_OwnAutoConfigClose = "ui-OwnAutoConfigClose",
  ui_BpAutoConfigOpen = "ui-BpAutoConfigOpen",
  ui_BpAutoConfigClose = "ui-BpAutoConfigClose",
  ui_BpSelected = "ui-BpSelected",
  ui_ReconcileLedgerButton = "ui-ReconcileLedgerButton",
  ui_DownloadResultsButtonClicked = "ui-DownloadResultsButtonClicked",
  ui_UploadOwnLedger = "ui-UploadOwnLedger",
  ui_UploadPartnerLedger = "ui-UploadPartnerLedger",
  ui_ManualReconOpen = "ui-ManualReconOpen",
  ui_ManualReconClose = "ui-ManualReconClose",

  // Partner Comms events
  ui_PartnerCommunicationSelected = "ui-PartnerCommunicationSelected",
  ui_BCSelected = "ui-BCSelected",
  ui_LRSelected = "ui-LRSelected",
  ui_RefreshStatus = "ui-RefreshStatus",
  ui_ClickedOnSetting = "ui-ClickedOnSetting",
  ui_ResetStatus = "ui-ResetStatus",
  ui_AutoResetStatus_Yes = "ui-AutoResetStatus-Yes",
  ui_AutoResetStatus_No = "ui-AutoResetStatus-No",
  ui_SendReminderSettingChange_Yes = "ui-SendReminderSettingChange_Yes",
  ui_SendReminderSettingChange_No = "ui-SendReminderSettingChange_No",
  ui_ClickedOnFilter = "ui-ClickedOnFilter",
  ui_FilterApplied = "ui-FilterApplied",
  ui_clickedOnBulkEmail = "ui-ClickedOnBulkEmail",
  ui_BulkEmailSent = "ui-BulkEmailSent",
  ui_TemplateCreatedOnMain = "ui-TemplateCreatedOnMain",
  ui_TemplateCreatedOnEmailModal = "ui-TemplateCreatedOnEmailModal",
  ui_DownloadReport = "ui-DownloadReport",
  ui_SingleEmailSent = "ui-SingleEmailSent",
  ui_UpdatedEmailTemplate = "ui-UpdatedEmailTemplate",
  ui_ViewEmailClicked = "ui-ViewEmailClicked",
  ui_DownloadLedgerStatus = "ui-DownloadLedgerStatus",
  ui_DownloadLedgerIcon = "ui-DownloadLedgerIcon",
  ui_DownloadLedgerViewEmail = "ui-DownloadLedgerViewEmail",
  ui_ClosingBalanceRefresh = "ui-ClosingBalanceRefresh",
  // end of Partner Comms events
}

export const uiLoggerNamesRecon = {
  UI_PROD_LOGIN_CLICK: {
    functionName: "UI-Prod-Login-Click",
    message: "Prod Login",
  },
  UI_STAGE_LOGIN_CLICK: {
    functionName: "UI-Prod-Login-Click",
    message: "Stage Login",
  },
  UI_DEV_LOGIN_CLICK: {
    functionName: "UI-Prod-Login-Click",
    message: "Dev Login",
  },
  UI_WF_DB_SHOW_CLICK: {
    functionName: "UI-WF-DB-Show-Click",
    message: "Shows the home dashboard",
  },
  UI_RL_UPLOAD_OWN_LEDGER_CLICK: {
    functionName: "UI-RL-UploadOwnLedger-Click",
    message: "Upload own side ledger",
  },
  UI_RL_OWN_CONFIG_CLICK: {
    functionName: "UI-RL-Own-Config-Click",
    message: "Own side manual config",
  },
  UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_CLICK: {
    functionName: "UI-RL-Own-Config-SaveAsTemplate-Click",
    message: "Clicking on Save As Template in own side",
  },
  UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK: {
    functionName: "UI-RL-Own-Config-SaveAsTemplate-SaveNew-Click",
    message: "Actually saving template in own side",
  },
  UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_UPDATE_EXISTING_CLICK: {
    functionName: "UI-RL-Own-Config-SaveAsTemplate-UpdateExisting-Click",
    message: "Updating template in own side",
  },
  UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_CLICK: {
    functionName: "UI-RL-Own-Config-TDS/TCSApplicable-Click",
    message: "Clicking on TDS/TCS applicable",
  },
  UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK: {
    functionName: "UI-RL-Own-Config-TDS/TCSApplicable-Update-Click",
    message: "Changing TDS/TCS percentage",
  },
  UI_RL_OWN_CONFIG_SANITIZER_CLICK: {
    functionName: "UI-RL-Own-Config-sanitizer-Click",
    message: "Using sanitizer own side",
  },
  UI_RL_OWN_CONFIG_SANITIZER_APPLY_CLICK: {
    functionName: "UI-RL-Own-Config-sanitizer-Apply-Click",
    message: "Applying sanitizer own side",
  },
  UI_RL_OWN_CONFIG_SANITIZER_CLOSE_CLICK: {
    functionName: "UI-RL-Own-Config-sanitizer-Close-Click",
    message: "Closing sanitizer window without applying",
  },
  UI_RL_OWN_CONFIG_NEXT_ADD_CLICK: {
    functionName: "UI-RL-Own-Config-Next-Add-Click",
    message: "Adding entry type in own side",
  },
  UI_RL_OWN_CONFIG_SAVE_CLICK: {
    functionName: "UI-RL-Own-Config-Save-Click",
    message: "Saving own side manual config",
  },
  UI_RL_UPLOAD_PARTNER_LEDGER_CLICK: {
    functionName: "UI-RL-UploadPartnerLedger-Click",
    message: "Upload partner side ledger",
  },
  UI_RL_PARTNER_CONFIG_CLICK: {
    functionName: "UI-RL-Partner-Config-Click",
    message: "Partner side manual config",
  },
  UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_CLICK: {
    functionName: "UI-RL-Partner-Config-SaveAsTemplate-Click",
    message: "Saving template in partner side",
  },
  UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK: {
    functionName: "UI-RL-Partner-Config-SaveAsTemplate-SaveNew-Click",
    message: "Actually saving template in partner side",
  },
  UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_UPDATE_EXISTING_CLICK: {
    functionName: "UI-RL-Partner-Config-SaveAsTemplate-UpdateExisting-Click",
    message: "Updating template in partner side",
  },
  UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_CLICK: {
    functionName: "UI-RL-Partner-Config-TDS/TCSApplicable-Click",
    message: "Clicking on TDS/TCS applicable",
  },
  UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_UPDATE_CLICK: {
    functionName: "UI-RL-Partner-Config-TDS/TCSApplicable-Update-Click",
    message: "Changing TDS/TCS percentage",
  },
  UI_RL_PARTNER_CONFIG_SANITIZER_CLICK: {
    functionName: "UI-RL-Partner-Config-sanitizer-Click",
    message: "Using sanitizer partner side",
  },
  UI_RL_PARTNER_CONFIG_SANITIZER_APPLY_CLICK: {
    functionName: "UI-RL-Partner-Config-sanitizer-Apply-Click",
    message: "Applying sanitizer partner side",
  },
  UI_RL_PARTNER_CONFIG_SANITIZER_CLOSE_CLICK: {
    functionName: "UI-RL-Partner-Config-sanitizer-Close-Click",
    message: "Closing sanitizer window without applying",
  },
  UI_RL_PARTNER_CONFIG_NEXT_ADD_CLICK: {
    functionName: "UI-RL-Partner-Config-Next-Add-Click",
    message: "Adding entry type in partner side",
  },
  UI_RL_PARTNER_CONFIG_SAVE_CLICK: {
    functionName: "UI-RL-Partner-Config-Save-Click",
    message: "Saving partner side manual config",
  },
  UI_RL_SET_RECON_PERIOD_CLICK: {
    functionName: "UI-RL-SetReconPeriod-Click",
    message: "Clicking to set Recon period",
  },
  UI_RL_SET_RECON_PERIOD_CONFIRM_CLICK: {
    functionName: "UI-RL-SetReconPeriod-Confirm-Click",
    message: "Setting Recon period",
  },
  UI_RL_RECONCILE_LEDGERS_CLICK: {
    functionName: "UI-RL-ReconcileLedgers-Click",
    message: "Reconciles ledgers",
  },
  UI_RL_DOWNLOAD_RESULTS_CLICK: {
    functionName: "UI-RL-DownloadResults-Click",
    message: "Download Results",
  },
  UI_RL_RECONCILE_MANUALLY_CLICK: {
    functionName: "UI-RL-ReconcileManually-Click",
    message: "Clicking on Reconcile Manually button",
  },
  UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_REFRESH_SUGGESTION_CLICK: {
    functionName: "UI-RL-ReconcileManually-SuggestedMatches-RefreshSuggestion-Click",
    message: "Using Refresh Suggestion feature",
  },
  UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_CLICK: {
    functionName: "UI-RL-ReconcileManually-SuggestedMatches-ViewMore-Click",
    message: "Viewing suggested matches",
  },
  UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_RECONCILE_CLICK: {
    functionName: "UI-RL-ReconcileManually-SuggestedMatches-ViewMore-Reconcile-Click",
    message: "Reconciling via suggested matches",
  },
  UI_RL_RECONCILE_MANUALLY_CHANGE_RECON_STATUS_APPLY_CLICK: {
    functionName: "UI-RL-ReconcileManually-ChangeReconStatus-Apply-Click",
    message: "Changing recon status manually",
  },
  UI_RL_RECONCILE_MANUALLY_RECONCILE_CLICK: {
    functionName: "UI-RL-ReconcileManually-Reconcile-Click",
    message: "Reconciling manually",
  },
  UI_RL_RECONCILE_MANUALLY_UNRECONCILE_CLICK: {
    functionName: "UI-RL-ReconcileManually-Unreconcile-Click",
    message: "Unreconciling manually",
  },
  UI_RL_RECONCILE_MANUALLY_BACK_CLICK: {
    functionName: "UI-RL-ReconcileManually-Back-Click",
    message: "Going back to the recon page",
  },
  UI_RL_RECONCILE_MANUALLY_CHANGES_DONE_ARROW_CLICK: {
    functionName: "UI-RL-ReconcileManually-ChangesDoneArrow-Click",
    message: "Seeing Changes done",
  },
  UI_RL_GENERATE_GAP_SUMMARY_CLICK: {
    functionName: "UI-RL-GenerateGapSummary-Click",
    message: "Generate Gap Summary",
  },
  UI_RL_SEND_RESULTS_TO_PARTNER_CLICK: {
    functionName: "UI-RL-SendRsultsToPartner-Click",
    message: "Send Results to Partner",
  },
  UI_RL_MISSED_COMBINATION_SAVE_CLICK: {
    functionName: "UI-RL-MissedCombination-Save-Click",
    message: "Update TDS and GST from missed combination and Save",
  },
  UI_RL_MISSED_COMBINATION_SAVE_AND_RECONCILE_CLICK: {
    functionName: "UI-RL-MissedCombination-SaveAndReconcile-Click",
    message: "Update TDS and GST from missed combination and Save and Reconcile Again",
  },
};

export const fileTypeLogger = {
  pdf: "PDF",
  excel: "Excel",
};

export const uiLoggerNamesWorkflow = {
  UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK: {
    functionName: "UI-WF-DB-DownloadTaskReport-Click",
    message: "Download the report of all partners",
  },
  UI_WF_DB_FILTER_CLICK: { functionName: "UI-WF-DB-Filter-Click", message: "Using filter button" },
  UI_WF_DB_FILTER_APPLY_CLICK: { functionName: "UI-WF-DB-Filter-Apply-Click", message: "Applying filter" },
  UI_WF_DB_PARTNER_SELECT_CLICK: {
    functionName: "UI-WF-DB-PartnerSelect-Click",
    message: "Selecting partner via hyperlink",
  },
  UI_WF_CREATE_TASK_CLICK: { functionName: "UI-WF-CreateTask-Click", message: "Create task" },
  UI_WF_DB_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-BothSideLedgerPending-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-PartnerLedgerPending-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_OWN_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-OwnLedgerPending-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_ISSUE_IN_LEDGER_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-IssueInLedger-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_YET_TO_START_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-YetToStart-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_RECON_IN_PROGRESS_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-ReconInProgress-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_MAKER_PASSED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-MakerPassed-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_CHECKER_REJECTED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-CheckerRejected-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_CHECKER_PASSED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-CheckerPassed-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_SENT_TO_APPROVER_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-SentToApprover-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_APPROVER_REJECTED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-ApproverRejected-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_APPROVER_PASSED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-ApproverPassed-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_TECHNICAL_ISSUE_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-TechnicalIssue-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_SAVE_TICKET_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-SaveTicket-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_DISCARD_TICKET_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-DiscardTicket-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_DB_RECON_STATUS_DROPPED_CLICK: {
    functionName: "UI-WF-DB-ReconStatus-Dropped-Click",
    message: "Recon status change from dashboard table",
  },
  UI_WF_IT_SAVE_REMARKS_CLICK: { functionName: "UI-WF-IT-SaveRemarks-Click", message: "Saving in Remarks" },
  UI_WF_IT_ADD_FOLLOW_UP_MAIL_CLICK: {
    functionName: "UI-WF-IT-AddFollowUp-Mail-Click",
    message: "Using follow up option for Mail",
  },
  UI_WF_IT_ADD_FOLLOW_UP_CONVERSATION_CLICK: {
    functionName: "UI-WF-IT-AddFollowUp-Conversation-Click",
    message: "Using follow up option for Conversation",
  },
  UI_WF_IT_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-BothSideLedgerPending-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-PartnerLedgerPending-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_OWN_LEDGER_PENDING_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-OwnLedgerPending-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_ISSUE_IN_LEDGER_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-IssueInLedger-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_YET_TO_START_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-YetToStart-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_RECON_IN_PROGRESS_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-ReconInProgress-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_MAKER_PASSED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-MakerPassed-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_CHECKER_REJECTED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-CheckerRejected-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_CHECKER_PASSED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-CheckerPassed-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_SENT_TO_APPROVER_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-SentToApprover-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_APPROVER_REJECTED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-ApproverRejected-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_APPROVER_PASSED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-ApproverPassed-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_TECHNICAL_ISSUE_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-TechnicalIssue-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_SAVE_TICKET_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-SaveTicket-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_DISCARD_TICKET_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-DiscardTicket-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_IT_RECON_STATUS_DROPPED_CLICK: {
    functionName: "UI-WF-IT-ReconStatus-Dropped-Click",
    message: "Recon status change from inside ticket",
  },
  UI_WF_DB_BULKTASKS_ADDTASKS_DOWNLOADTEMPLATE_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-AddTasks-DownloadTemplate-Click",
    message: "Template downloaded for bulk tasks creation",
  },
  UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-AddTasks-UploadTemplate-Click",
    message: "Template uploaded for bulk tasks creation",
  },
  UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CONFIRM_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-AddTasks-UploadTemplate-Confirm-Click",
    message: "Template uploaded for bulk tasks creation confirm button clicked",
  },
  UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_DELETE_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-Addtasks-UploadTemplate-Delete-Click",
    message: "Template deleted for bulk task creation",
  },
  UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-UpdateTasks-Update-Click",
    message: "Tasks updated in bulk",
  },
  UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CANCEL_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-UpdateTasks-Update-Cancel-Click",
    message: "Tasks not updated",
  },
  UI_WF_DB_BULKTASKS_SAVETASKS_SAVE_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-SaveTasks-Save-Click",
    message: "Tasks saved in bulk",
  },
  UI_WF_DB_BULKTASKS_SAVETASKS_CANCEL_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-SaveTasks-Cancel-Click",
    message: "Tasks not saved in bulk",
  },
  UI_WF_DB_BULKTASKS_DISCARDTASKS_DISCARD_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-DiscardTasks-Discard-Click",
    message: "Discarded tasks in bulk",
  },
  UI_WF_DB_BULKTASKS_DISCARDTASKS_CANCEL_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-DiscardTasks-Cancel-Click",
    message: "Tasks not discarded in bulk",
  },
  UI_WF_DB_BULKTASKS_CLEARTASKS_CLEAR_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-ClearTasks-Clear-Click",
    message: "Cleared tasks in bulk",
  },
  UI_WF_DB_BULKTASKS_CLEARTASKS_CANCEL_CLICK: {
    functionName: "UI-WF-DB-BulkTasks-ClearTasks-Cancel-Click",
    message: "Tasks not cleared in bulk",
  },
};
export enum uiLoggerBcPortal {
  ui_PpBcConfirmClick = "UI-PP-BC-CONFIRM-CLICK",
  ui_PpBcNotConfirmClick = "UI-PP-BC-NOTCONFIRM-CLICK",
  ui_PpBcCfDownloadClick = "UI-PP-BC-CF-DOWNLOAD-CLICK",
  ui_PpBcCfSignedUploadClick = "UI-PP-BC-CF-SIGNED-UPLOAD-CLICK",
  ui_PpBcCfSignedConfirmationUploadClick = "UI-PP-BC-CF-SIGNED-CONFIRMATION-UPLOAD-CLICK",
  ui_PpBcCfSignedViewClick = "UI-PP-BC-CF-SIGNED-VIEW-CLICK",
  ui_PpBcCfSignedDeleteClick = "UI-PP-BC-CF-SIGNED-DELETE-CLICK",
  ui_PpBcCfLedgerUploadClick = "UI-PP-BC-CF-LEDGER-UPLOAD-CLICK",
  ui_PpBcCfLedgerConfirmationUploadClick = "UI-PP-BC-CF-LEDGER-CONFIRMATION-UPLOAD-CLICK",
  ui_PpBcCfLedgerViewClick = "UI-PP-BC-CF-LEDGER-VIEW-CLICK",
  ui_PpBcCfLedgerDeleteClick = "UI-PP-BC-CF-LEDGER-DELETE-CLICK",
  ui_PpBcCfBackClick = "UI-PP-BC-CF-BACK-CLICK",
  ui_PpBcCfSubmitClick = "UI-PP-BC-CF-SUBMIT-CLICK",
  ui_PpBcCfRaiseIssueClick = "UI-PP-BC-CF-RAISE-ISSUE-CLICK",
  ui_PpBcCfRaiseIssueSubmitClick = "UI-PP-BC-CF-RAISE-ISSUE-SUBMIT-CLICK",
  ui_PpBcNcfBalanceClick = "UI-PP-BC-NCF-BALANCE-CLICK",
  ui_PpBcNcfDownloadClick = "UI-PP-BC-NCF-DOWNLOAD-CLICK",
  ui_PpBcNcfSignedUploadClick = "UI-PP-BC-NCF-SIGNED-UPLOAD-CLICK",
  ui_PpBcNcfSignedConfirmationUploadClick = "UI-PP-BC-NCF-SIGNED-CONFIRMATION-UPLOAD-CLICK",
  ui_PpBcNcfSignedViewClick = "UI-PP-BC-NCF-SIGNED-VIEW-CLICK",
  ui_PpBcNcfSignedDeleteClick = "UI-PP-BC-NCF-SIGNED-DELETE-CLICK",
  ui_PpBcNcfLedgerUploadClick = "UI-PP-BC-NCF-LEDGER-UPLOAD-CLICK",
  ui_PpBcNcfLedgerConfirmationUploadClick = "UI-PP-BC-NCF-LEDGER-CONFIRMATION-UPLOAD-CLICK",
  ui_PpBcNcfLedgerViewClick = "UI-PP-BC-NCF-LEDGER-VIEW-CLICK",
  ui_PpBcNcfLedgerDeleteClick = "UI-PP-BC-NCF-LEDGER-DELETE-CLICK",
  ui_PpBcNcfBackClick = "UI-PP-BC-NCF-BACK-CLICK",
  ui_PpBcNcfSubmitClick = "UI-PP-BC-NCF-SUBMIT",
  ui_PpBcNcfRaiseIssueClick = "UI-PP-BC-NCF-RAISE-ISSUE",
  ui_PpBcNcfRaiseIssueSubmitClick = "UI-PP-BC-NCF-RAISE-ISSUE-SUBMIT",
  ui_PpBcPostSubmitRaiseRequestClick = "UI-PP-BC-POST-SUBMIT-RAISE-REQUEST",
  ui_PpBcPostSubmitRaiseRequestSubmitClick = "UI-PP-BC-POST-SUBMIT-RAISE-REQUEST-SUBMIT",
}

export enum uiLoggerLrPortal {
  ui_PpLrUploadClick = "UI-PP-LR-UPLOAD-CLICK",
  ui_PpLrViewClick = "UI-PP-LR-VIEW-CLICK",
  ui_PpLrDeleteClick = "UI-PP-LR-DELETE-CLICK",
  ui_PpLrRaiseIssueClick = "UI-PP-LR-RAISE-ISSUE-CLICK",
  ui_PpLrRaiseIssueSubmitClick = "UI-PP-LR-RAISE-ISSUE-SUBMIT-CLICK",
  ui_PpLrSubmitClick = "UI-PP-LR-SUBMIT-CLICK",
  ui_PpLrPostSubmitRaiseRequestClick = "UI-PP-LR-POST-SUBMIT-RAISE-REQUEST-CLICK",
  ui_PpLrPostSubmitRaiseRequestSubmitClick = "UI-PP-LR-POST-SUBMIT-RAISE-REQUEST-SUBMIT-CLICK",
}
export const uiLoggerNamesCBBreakdown = {
  UI_RL_OWN_CB_DETAILS_CLICK: {
    functionName: "UI-RL-Own-CB-Details-Click",
    message: "Own Side CB Details",
  },
  UI_RL_OWN_CB_DETAILS_SAVE_CLICK: {
    functionName: "UI-RL-Own-CB-Details-Save-Click",
    message: "Own Side CB Details Save Button",
  },
  UI_RL_OWN_CB_DETAILS_KNOW_MORE_CLICK: {
    functionName: "UI-RL-Own-CB-Details-KnowMore-Click",
    message: "Own Side CB Details Know More Button",
  },
  UI_RL_OWN_CB_DETAILS_KNOW_MORE_COPY_CLICK: {
    functionName: "UI-RL-Own-CB-Details-KnowMore-Copy-Click",
    message: "Own Side CB Details Know More Button Copy Not read rows",
  },
  UI_RL_BP_CB_DETAILS_CLICK: {
    functionName: "UI-RL-BP-CB-Details-Click",
    message: "BP Side CB Details",
  },
  UI_RL_BP_CB_DETAILS_SAVE_CLICK: {
    functionName: "UI-RL-BP-CB-Details-Save-Click",
    message: "BP Side CB Details Save Button",
  },
  UI_RL_BP_CB_DETAILS_KNOW_MORE_CLICK: {
    functionName: "UI-RL-BP-CB-Details-KnowMore-Click",
    message: "BP Side CB Details Know More Button",
  },
  UI_RL_BP_CB_DETAILS_KNOW_MORE_COPY_CLICK: {
    functionName: "UI-RL-BP-CB-Details-KnowMore-Copy-Click",
    message: "BP Side CB Details Know More Button Copy Not read rows",
  },
};

export const uiLoggerNamesCustomGapDescription = {
  UI_RL_ReconSummaryCalculation_CustomGapDescription_Save_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-Save-Click",
    message: "Saving Custom Gap Descriptions",
  },

  UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-EditPencil-Click",
    message: "Editing previously set custom gap description",
  },

  UI_RL_ReconSummaryCalculation_CustomGapDescription_Delete_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-Delete-Click",
    message: "Deleting previously set custom gap description",
  },

  UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-Add-Click",
    message: "Adding new Custom Gap Description",
  },

  UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Update_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-Add-Update-Click",
    message: "Updating new added Custom Gap Description after addition through add button",
  },

  UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Update_Click: {
    functionName: "UI-RL-ReconSummaryCalculation-CustomGapDescription-EditPencil-Update-Click",
    message: "Updating Custom Gap Description after editing through edit (pencil) button",
  },
};
export const uiLoggerNamesChecklistAndNotes = {
  UI_RE_NOTES_CLICK: {
    functionName: "UI-RE-NOTES-CLICK",
    message: "Opened Notes",
  },
  UI_RE_NOTES_SEARCH_CLICK: {
    functionName: "UI-RE-NOTES-SEARCH-CLICK",
    message: "Searched Notes",
  },
  UI_RE_NOTES_ADD_CLICK: {
    functionName: "UI-RE-NOTES-ADD-CLICK",
    message: "Added a Note",
  },
  UI_RE_NOTES_DELETE_CLICK: {
    functionName: "UI-RE-NOTES-DELETE-CLICK",
    message: "Deleted a Note",
  },
  UI_RE_NOTES_PREVIEW_CLICK: {
    functionName: "UI-RE-NOTES-PREVIEW-CLICK",
    message: "Previewed a Note",
  },
  UI_RE_CHECKLIST_CLICK: {
    functionName: "UI-RE-CHECKLIST-CLICK",
    message: "Opened Checklist",
  },
  UI_RE_CHECKLIST_ADD_CLICK: {
    functionName: "UI-RE-CHECKLIST-ADD-CLICK",
    message: "Added an item",
  },
  UI_RE_CHECKLIST_MARK_CLICK: {
    functionName: "UI-RE-CHECKLIST-MARK-CLICK",
    message: "Marked an item",
  },
  UI_RE_CHECKLIST_UNMARK_CLICK: {
    functionName: "UI-RE-CHECKLIST-UNMARK-CLICK",
    message: "Unmarked an item",
  },
  UI_RE_CHECKLIST_PREVIEW_CLICK: {
    functionName: "UI-RE-CHECKLIST-PREVIEW-CLICK",
    message: "Previewed an item",
  },
  UI_RE_CHECKLIST_DELETE_CLICK: {
    functionName: "UI-RE-CHECKLIST-DELETE-CLICK",
    message: "Deleted an item",
  },
};
