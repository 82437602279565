import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { Currency } from "dinero.js";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { useFetch2 } from "src/Components/Common/useFetch";
import FormatDate from "src/Utils/DateUtils";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { uiLoggerBcPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import ApiErrorCatch from "../../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import LendingTopBanner from "../Common/LendingTopBanner";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import RaiseIssueSection from "../Common/RaiseIssueSection";
import { fetchData } from "../LedgerRequestPortal/CommonComponents";
import { MailBox, MailBoxProps } from "../LedgerRequestPortal/LedgerRequestPortalCommon";
import { NdAsyncButton } from "../PartnerPortal/CommonComponents";
import ErrorPage from "../PartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import { BcCustomisationDetails, MailBoxPropsBc, StateDispatch } from "./BalanceConfirmationPortalCommon";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";
import { ConfirmBalanceDialog } from "./BalanceConfirmDetailsDialog";
import DetailsPage from "./DetailsPage";
import FinalPage from "./FinalPage";
import { RaiseRequestDialog } from "./FinalPage";

export type PageStatus = "NotFilled" | "DetailsFilled" | "FinalPage";

const BalanceConfirmationPortal = () => {
  const [nameAndLogo, setNameAndLogo] = useState({
    name: "",
    logo: "",
    businessPartnerName: "",
    companyName: "",
    branchName: "",
  });

  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");
  const initialRaiseIssueDialogState = params.get("raiseIssue") === "1" ? true : false;

  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert] = useState<boolean>(false);

  const { confirmStatus, setConfirmStatus, pageStatus, setPageStatus, editing } = useContext(BcPortalContext);

  // const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");
  // const [confirmStatus, setConfirmStatus] = useState<boolean>(null);
  const [closingBalanceDate, setClosingBalanceDate] = useState<string>(null);
  const [closingBalance, setClosingBalance] = useState<string>(null);
  const [currency, setCurrency] = useState<string>("INR");

  const [emailData, setEmailData] = useState<MailBoxProps>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [customization, setCustomization] = useState<{
    balanceConfirmed: BcCustomisationDetails;
    balanceNotConfirmed: BcCustomisationDetails;
  }>({} as any);

  const [openDialog, setOpenDialog] = useState(initialRaiseIssueDialogState);
  const [openPortalAccessDialog, setOpenPortalAccessDialog] = useState(editing ? false : true);

  const GetPartnerNameAndLogo = () =>
    fetchData<{
      name: string; // ruName
      logo: string;
      businessPartnerName: string;
      companyName: string;
      branchName: string;
    }>("/api/BalanceConfirmationPortal/GetPartnerNameAndLogo", setApiErrorMsg, setShowErrorAlert);
  const promisedNameAndLogo = GetPartnerNameAndLogo();

  const GetBcPortalStatus = () =>
    fetchData<{ portalStatus: PageStatus; isBalanceConfirmed: boolean }>(
      "/api/BalanceConfirmationPortal/GetBCPortalStatus",
      setApiErrorMsg,
      setShowErrorAlert
    );
  const promisedPortalStatus = GetBcPortalStatus();

  const GetEmailDetails = () =>
    fetchData<{ emailDetails: MailBoxPropsBc; closingBalance: number; closingBalanceDate: Date; currency: string }>(
      "/api/BalanceConfirmationPortal/GetEmailAndBalanceConfirmationDetails",
      setApiErrorMsg,
      setShowErrorAlert
    );
  const promisedEmailDetails = GetEmailDetails();

  const GetCustomisationDetails = () =>
    useFetch2<{ balanceConfirmed: BcCustomisationDetails; balanceNotConfirmed: BcCustomisationDetails }>(
      "/api/BalanceConfirmationPortal/GetCustomisationDetails",
      "GET",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          clarity.setTag("BalanceConfirmationPortal", `Start`);
          window.clarity("event", "BalanceConfirmationPortal-Start");
          const defaultCustomisation: BcCustomisationDetails = {
            isPdfRequired: null,
            isLedgerRequired: null,
          };

          setCustomization({
            balanceConfirmed: res.data?.balanceConfirmed || defaultCustomisation,
            balanceNotConfirmed: res.data?.balanceNotConfirmed || defaultCustomisation,
          });
        },
      }
    );

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    GetCustomisationDetails();
  }, []);

  const BalanceConfirmationPortalMain = () => {
    let status: PageStatus;
    let isBalanceConfirmed: boolean;
    let name: string;
    let logo: string;
    let businessPartnerName: string;
    let companyName: string;
    let branchName: string;
    let emailDetails: MailBoxPropsBc;
    let closingBalance: number;
    let closingBalanceDate: Date;
    let currency: string;

    if (!dataLoaded) {
      console.log({ dataLoaded });

      ({ name, logo, businessPartnerName, companyName, branchName } = promisedNameAndLogo.read());

      ({ portalStatus: status, isBalanceConfirmed } = promisedPortalStatus.read());
      ({ emailDetails, closingBalance, closingBalanceDate, currency } = promisedEmailDetails.read());
    }

    useEffect(() => {
      if (name && status && emailDetails) setDataLoaded(true);
      // console.log("setting from index");

      if (name || logo || businessPartnerName || companyName || branchName) {
        setNameAndLogo({ name, logo, businessPartnerName, companyName, branchName });
        clarity.setTag("RU", `${name}`);
        clarity.setTag("RU-BP", `${name}-${businessPartnerName}`);
      }

      if (!dataLoaded) setPageStatus(status || "NotFilled");

      if (typeof isBalanceConfirmed === "boolean") {
        if (isBalanceConfirmed) setConfirmStatus(true);
        else setConfirmStatus(false);
      }

      if (emailDetails) {
        setEmailData({
          mailBody: emailDetails.body,
          mailSubject: emailDetails.subject,
          mailDate: emailDetails.time,
          mailFrom: emailDetails.from,
        });
      }
      if (Number.isFinite(closingBalance)) setClosingBalance(closingBalance.toString());
      if (closingBalanceDate) setClosingBalanceDate(closingBalanceDate.toString());
      if (currency) setCurrency(currency);
    }, [status, emailDetails, closingBalance, closingBalanceDate, currency]);

    return <span id="data-await" hidden></span>;
  };

  const headerTextBuilder = [nameAndLogo.name, nameAndLogo?.companyName || "", nameAndLogo?.branchName || ""];
  const headerTextCompanyInfo = headerTextBuilder.filter((x) => x).join(", ");

  const openDetailsDialog =
    pageStatus === "DetailsFilled" &&
    (confirmStatus === true
      ? customization.balanceConfirmed?.isPdfRequired === false
      : customization.balanceNotConfirmed?.isPdfRequired === false);

  console.log({ pageStatus, confirmStatus, openDetailsDialog, customization });

  return (
    <>
      <ErrorPage>
        <ApiErrorCatch
          apiErrorMsg={apiErrorMsg}
          showUploadErrorAlert={showErrorAlert}
          showUploadSuccessAlert={showSuccessAlert}
        />
        <Suspense fallback={<LoadingPage />}>
          {pageStatus === "FinalPage" && <LendingTopBanner />}
          <NavBar companyName={nameAndLogo.name} companyLogo={nameAndLogo.logo} />
          <Divider className={$.borderColor} />
          <Header headerText={`Confirmation of balance as on ${closingBalanceDate} for ${headerTextCompanyInfo}`} />
          <Divider className={$.borderColor} />
          <BalanceConfirmationPortalMain />
          {(pageStatus === "NotFilled" && emailData) ||
          (pageStatus !== "FinalPage" && openDetailsDialog && emailData) ? (
            <InitialPage
              emailData={emailData}
              closingBalance={closingBalance}
              currency={currency}
              closingBalanceDate={closingBalanceDate}
              bpName={nameAndLogo.businessPartnerName}
              ruName={nameAndLogo.name}
              cbDate={closingBalanceDate}
              ruNameAndCompany={headerTextCompanyInfo}
              customization={customization}
              openDetailsDialog={openDetailsDialog}
              openRaiseIssueDialog={openDialog}
              setOpenRaiseIssueDialog={setOpenDialog}
              openPortalAccessDialog={openPortalAccessDialog}
              setOpenPortalAccessDialog={setOpenPortalAccessDialog}
            />
          ) : pageStatus === "DetailsFilled" && !openDetailsDialog ? (
            <DetailsPage
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              bpName={nameAndLogo.businessPartnerName}
              ruName={nameAndLogo.name}
              customization={confirmStatus ? customization.balanceConfirmed : customization.balanceNotConfirmed}
              openPortalAccessDialog={openPortalAccessDialog}
              setOpenPortalAccessDialog={setOpenPortalAccessDialog}
            />
          ) : pageStatus === "FinalPage" ? (
            <FinalPage ruName={nameAndLogo.name} openDialog={openDialog} setOpenDialog={setOpenDialog} />
          ) : null}
        </Suspense>
      </ErrorPage>
    </>
  );
};

interface InitialPageProps {
  emailData: MailBoxProps;
  closingBalance: string;
  closingBalanceDate: string;
  currency: string;
  bpName: string;
  ruName: string;
  cbDate: string;
  ruNameAndCompany: string;
  customization: { balanceConfirmed: BcCustomisationDetails; balanceNotConfirmed: BcCustomisationDetails };
  openDetailsDialog?: boolean;
  openRaiseIssueDialog: boolean;
  setOpenRaiseIssueDialog: StateDispatch<boolean>;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}

const InitialPage = ({
  emailData,
  closingBalance,
  closingBalanceDate,
  currency,
  bpName,
  ruName,
  cbDate,
  ruNameAndCompany,
  customization,
  openDetailsDialog,
  openRaiseIssueDialog,
  setOpenRaiseIssueDialog,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: InitialPageProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(openDetailsDialog || false);
  const [updatingBCStatus, setUpdatingBCStatus] = useState(false);

  const { confirmStatus, setConfirmStatus, setPageStatus } = useContext(BcPortalContext);

  const UpdateBalanceConfirmationStatus = (confirmed: boolean) =>
    new Promise<boolean>((resolve, reject) =>
      useFetch2("/api/BalanceConfirmationPortal/UpdateBalanceConfirmationStatus", "POST", {
        setApiErrorMsg,
        setShowErrorAlert,
        data: {
          data: encryptedData,
          isBalanceConfirmed: confirmed,
        },
        thenCallBack: (_res) => {
          setUpdatingBCStatus(false);
          setConfirmStatus(confirmed);
          resolve(confirmed);
        },
        catchCallBack: () => {
          setUpdatingBCStatus(false);
          reject();
        },
        errorCallback: reject,
      })
    );

  return (
    <>
      <ApiErrorCatch apiErrorMsg={apiErrorMsg} showUploadErrorAlert={showErrorAlert} />
      <Box className="main_container" p={3}>
        <Stack
          className={$.BR_fix + " fade_in first_page_box"}
          p={3}
          maxWidth={900}
          mx={"auto"}
          gap={5}
          border={"1px solid #d7d7d7"}
          sx={{ overflow: "auto", maxHeight: "63vh" }}
        >
          <Stack className="cb_box" gap={2}>
            <Typography variant="h6">
              Closing Balance for {bpName} in {ruName}’s ledger
            </Typography>
            <Box className="d_flex" gap={2}>
              <Box
                className={$.BR_fix + " vertical_center_align cb_date_card"}
                bgcolor="#eee8ed"
                px={"12px"}
                gap={3}
                py={1}
              >
                <Box>
                  <Typography variant="overline">Closing Balance</Typography>
                  <Box className="vertical_center_align" gap={1}>
                    <Chip
                      label={Number(closingBalance) > 0 ? "Receivable" : "Payable"}
                      color="default"
                      size="small"
                      variant="filled"
                      sx={{ "& > *": { fontFamily: "inherit" } }}
                    />
                    <Typography variant="subtitle1" fontWeight={600}>
                      {closingBalance === null
                        ? null
                        : formatMoney(ToDineroObj(Number(closingBalance), currency as Currency))}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="overline">as on</Typography>
                <Box>
                  <Typography variant="overline">Closing Balance Date</Typography>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {FormatDate(closingBalanceDate).toString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
          <Stack className="mail_box" gap={2}>
            <Typography variant="h6" fontWeight={600}>
              Reference of the email sent to you by {emailData?.mailFrom}
            </Typography>
            <MailBox
              mailSubject={emailData.mailSubject}
              mailBody={emailData.mailBody}
              mailFrom={emailData.mailFrom}
              mailDate={emailData.mailDate}
            />
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <NdAsyncButton
              variant="contained"
              size="large"
              sx={{ minWidth: 145 }}
              onClick={async () => {
                await UpdateBalanceConfirmationStatus(true);
                if (customization?.balanceConfirmed?.isPdfRequired) setPageStatus("DetailsFilled");
                else setOpenDialog(true);
                uiLoggerPortal(uiLoggerBcPortal.ui_PpBcConfirmClick, encryptedData);
              }}
              disabled={updatingBCStatus}
            >
              Confirm
            </NdAsyncButton>
            <NdAsyncButton
              variant="contained"
              size="large"
              sx={{ minWidth: 145 }}
              onClick={async () => {
                await UpdateBalanceConfirmationStatus(false);
                if (customization?.balanceNotConfirmed?.isPdfRequired) setPageStatus("DetailsFilled");
                else setOpenDialog(true);
                uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNotConfirmClick, encryptedData);
              }}
              disabled={updatingBCStatus}
            >
              <LoadingIcon loading={updatingBCStatus} />
              Not Confirm
            </NdAsyncButton>
          </Stack>
          <RaiseIssueSection setOpenDialog={setOpenRaiseIssueDialog} />
        </Stack>
      </Box>
      <ConfirmBalanceDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        cbDate={cbDate}
        ruNameAndCompany={ruNameAndCompany}
        ruName={ruName}
        bpName={bpName}
        customization={confirmStatus ? customization.balanceConfirmed : customization.balanceNotConfirmed}
        UpdateBalanceConfirmationStatus={UpdateBalanceConfirmationStatus}
      />
      <RaiseRequestDialog openDialog={openRaiseIssueDialog} setOpenDialog={setOpenRaiseIssueDialog} />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
    </>
  );
};

export default BalanceConfirmationPortal;
