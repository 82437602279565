import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { NdAsyncButton } from "../PartnerPortal/CommonComponents";
import $ from "../PartnerPortal/PartnerPortal.module.scss";

export const ApproveResponseDialog = ({ open, setOpen, partnerCount, approveAction }) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Have you reviewed submitted response of {partnerCount ? partnerCount : ""} partners?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info">
            Response submitted will be freezed and can't be edited. Make sure you have reviewed the partner's response.
          </Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await approveAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
            buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ResetStatusDialog = ({ open, setOpen, partnerCount, partnerName, resetAction }) => {
  const [waiting, setWaiting] = React.useState(false);

  return (
    <>
      <Dialog open={open} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <DialogTitle textTransform={"uppercase"} className="textOverflow_hidden d_flex">
          Have you reviewed{"  "}
          <h2
            style={{ font: "inherit", maxWidth: 400, display: "inline-block", paddingLeft: 6 }}
            className="textOverflow_hidden"
          >
            {partnerName ? partnerName : `all of ${partnerCount} partner`}
          </h2>
          's response?
        </DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning" sx={{ "*": { fontFamily: "inherit" } }}>
            This action will reset the communication status of the selected partner and cannot be reverted
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            Cancel
          </Button>
          <NdAsyncButton
            variant="contained"
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await resetAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="warning"
            buttonComponent={Button}
          >
            Reset
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
