/* eslint-disable react-hooks/rules-of-hooks */
import { RestartAlt } from "@mui/icons-material";
import { Autocomplete, Box, Button, MenuItem, Radio, RadioGroup, Select, Tab, Tabs, TextField } from "@mui/material";
import { Card, CardContent, FormControl, FormControlLabel, Grid, InputLabel } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import uiLogger from "src/Utils/UiLogger";
import { BusinessPartnersListForLedgerRequest, UseCaseType } from "../../../entity/recon-entity/ReconInterfaces";
import { uiLoggerName } from "../../../Utils/Recon/UiLogger/Constants";
import { useFetch2 } from "../../Common/useFetch";
import { userContext } from "../../Contexts/userContext";
import Table, { ColumnDefs } from "../../ReactTable/Table";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "./CommonComponents";
import CreateEmailTemplate from "./CreateEmailTemplate";
import FeedbackDialog from "./FeedbackDialog";
import { LedgerRequestContext } from "./LedgerRequest";
import { PartnerCommsContext } from "./PartnerCommunicationContext";
import StatusResetWarningModal from "./StatusResetWarningModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      ledgerRequestFrequency: " ",
      ledgerRequestDay: " ",
    });
  }
  return dataArray;
};

interface Props {
  optionsLedgerRequestFrequency: string[];
  frequencyTableSettings: any;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  listUserEmailTemplate: (name: string) => void;
}
const LedgerRequestSettings = (props: Props) => {
  const { actor } = useContext(userContext);

  const { storeRowsDataLedgerRequest, setStoreRowsDataLedgerRequest, listAllBusinessPartnersWSR } =
    useContext(LedgerRequestContext);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [loading, setLoading] = useState(true);
  const [resetStatusAuto, setResetStatusAuto] = useState(false);
  const [isOpenResetModal, setIsOpenResetModal] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);

  const [rowsDataLedgerRequestSetting, setRowsDataLedgerRequestSetting] = useState<any[]>(getBlankData());

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const columnDefinitionLedgerRequestSetting = useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Business Partner",
        id: "Business Partner",
        accessor: (row) => row.businessPartnerName?.toLowerCase(),
        Cell: ({ row }) => <div>{row.original.businessPartnerName}</div>,
        maxWidth: 350,
        minWidth: 350,
        width: 350,
      },
      {
        Header: "Ledger request frequency",
        accessor: "ledgerRequestFrequency",
        Sort: false,
        maxWidth: 350,
        minWidth: 300,
        width: 300,
        Cell: ({ row }) => (
          <p className="category_ui">
            <Autocomplete
              onChange={(e, value) => {
                console.log(e);
                let ledgerRequestDay: string = null;
                if (value === "Once a month") {
                  ledgerRequestDay = "1st of every month";
                } else if (value === "Twice a month") {
                  ledgerRequestDay = "1st and 15th of every month";
                } else if (value === "Once a quarter") {
                  ledgerRequestDay = "1th day of the quarter";
                }

                updateLedgerRequestSettings(row?.original?.businessPartnerId, value, ledgerRequestDay);
              }}
              id="partnerCommunicationSelect"
              className="w_250"
              options={props.optionsLedgerRequestFrequency}
              size="small"
              getOptionLabel={(option) => option}
              defaultValue={row.original.ledgerRequestFrequency}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </p>
        ),
      },
      {
        Header: "Ledger request day",
        accessor: "ledgerRequestDay",
        Sort: false,
        maxWidth: 350,
        minWidth: 350,
        width: 350,
        Cell: ({ row }) => (
          <p className="category_ui">
            <Autocomplete
              onChange={(e, value) => {
                console.log(e);
                updateLedgerRequestSettings(
                  row?.original?.businessPartnerId,
                  row?.original?.ledgerRequestFrequency,
                  value
                );
              }}
              id="partnerCommunicationSelect"
              className="w_250"
              options={
                row.original.ledgerRequestFrequency
                  ? props.frequencyTableSettings?.find((item) => item.frequency === row.original.ledgerRequestFrequency)
                      ?.days || []
                  : []
              }
              size="small"
              getOptionLabel={(option) => option}
              defaultValue={row.original.ledgerRequestDay}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </p>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history, props.optionsLedgerRequestFrequency, rowsDataLedgerRequestSetting]
  );

  useEffect(() => {
    listPartnerCommsStatusResetMethod();
  }, []);

  useEffect(() => {
    if (resetStatusAuto) ListAllLedgerRequestSettings();
  }, [resetStatusAuto]);

  const listPartnerCommsStatusResetMethod = () => {
    const thenCallBack = (response: any) => {
      if (response.data?.autoReset) setResetStatusAuto(true);
      else setResetStatusAuto(false);
    };
    useFetch2("/api/partnerCommunication/ListEmailAutoResetSetting", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack,
    });
  };

  const updatePartnerCommsStatusResetMethod = (status: boolean) => {
    useFetch2("/api/partnerCommunication/UpdateEmailAutoResetSetting", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        autoReset: status,
      },
    });
  };

  const ListAllLedgerRequestSettings = async () => {
    const thenCallBack = (response) => {
      setRowsDataLedgerRequestSetting(response.data.businessPartnersList);
      setLoading(false);
    };

    useFetch2("/api/partnerCommunication/ListAllLedgerRequestSettings", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack,
    });
  };

  const updateLedgerRequestSettings = async (id: number, freq: string, ledgerRequestDay: string) => {
    useFetch2("/api/partnerCommunication/UpdateLedgerRequestSettings", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      thenCallBack: () => ListAllLedgerRequestSettings(),
      data: {
        businessPartnerId: id,
        ledgerRequestFrequency: freq,
        ledgerRequestDay,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
    });
  };

  const handleCloseResetModal = () => {
    setIsOpenResetModal(false);
  };

  const resetStatesPartnerCommunication = () => {
    setShowLoadingIcon(true);

    const businessPartnerIds = storeRowsDataLedgerRequest?.map((entry) => entry?.businessPartnerId);

    useFetch2<{ businessPartnersList: BusinessPartnersListForLedgerRequest[] }>(
      "/api/partnerCommunication/ResetStatesPartnerCommunication",
      "POST",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        setShowSuccessAlert,
        thenCallBack: (response) => {
          const businessPartnersList = response.data?.businessPartnersList || [];
          const updatedData = storeRowsDataLedgerRequest?.map((data) => {
            const matchingPartner = businessPartnersList?.find(
              (partner) => partner.businessPartnerId === data.businessPartnerId
            );
            if (matchingPartner) {
              return {
                ...data,
                ledgerRequestStatus: matchingPartner.ledgerRequestStatus,
                downloadLedgerBase64: [],
              };
            }
            return data;
          });
          setStoreRowsDataLedgerRequest(updatedData);
          listAllBusinessPartnersWSR();
          setShowLoadingIcon(false);
          handleCloseResetModal();
          setOpenFeedback(true);
        },
        data: {
          businessPartnerIds,
          useCaseType: UseCaseType.ledgerRequest,
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      }
    );
  };

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Reset Status" sx={{ textTransform: "none" }} />
          <Tab label="Send Reminder" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid className="mb_15">
          <Card sx={{ maxWidth: "1040px", mx: "auto" }}>
            <CardContent sx={{ p: "16px !important" }}>
              <div className="d_flex">
                <div style={{ width: "90%" }} className="vertical_center_align">
                  <div className="vertical_center_align space_between" style={{ width: "100%" }}>
                    <div className="fs_20"> Reset Status Automatically as per defined Frequency: </div>
                    <div className="ml_60">
                      <FormControl>
                        <RadioGroup
                          row={true}
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={resetStatusAuto}
                          onChange={(e) => {
                            if (e.target.value === "true") {
                              setResetStatusAuto(true);
                              updatePartnerCommsStatusResetMethod(true);
                              uiLogger(uiLoggerName.ui_AutoResetStatus_Yes, props.companyId, props.branchCode);
                            } else {
                              setResetStatusAuto(false);
                              updatePartnerCommsStatusResetMethod(false);
                              uiLogger(uiLoggerName.ui_AutoResetStatus_No, props.companyId, props.branchCode);
                            }
                          }}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Yes" />
                          <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {!resetStatusAuto && (
          <div className="center_align">
            <Button
              variant="contained"
              className=""
              onClick={() => {
                setIsOpenResetModal(true);
                uiLogger(uiLoggerName.ui_ResetStatus, props.companyId, props.branchCode);
              }}
              startIcon={<RestartAlt />}
              color="warning"
            >
              Reset status
            </Button>
          </div>
        )}
        {resetStatusAuto && (
          <div className="center_align" style={{ height: "60vh" }}>
            <Table
              columns={columnDefinitionLedgerRequestSetting}
              rows={rowsDataLedgerRequestSetting}
              sortEnable={true}
              showPagination={true}
              loading={loading}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SendReminderTab
          companyId={props.companyId}
          branchCode={props.branchCode}
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          listUserEmailTemplate={props.listUserEmailTemplate}
        />
      </TabPanel>
      <StatusResetWarningModal
        isOpenResetModal={isOpenResetModal}
        handleCloseResetModal={handleCloseResetModal}
        handleReset={resetStatesPartnerCommunication}
        showLoadingIcon={showLoadingIcon}
      />
      <FeedbackDialog
        open={openFeedback}
        setOpen={setOpenFeedback}
        templateType={UseCaseType.ledgerRequest}
        companyId={props.companyId}
        branchCode={props.branchCode}
        setApiErrorMsg={setApiErrorMsg}
        setShowErrorAlert={setShowErrorAlert}
        setShowSuccessAlert={setShowSuccessAlert}
      />
    </>
  );
};

interface TabProps {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  listUserEmailTemplate: (name: string) => void;
}
const SendReminderTab = ({
  companyId,
  branchCode,
  setShowSuccessAlert,
  setShowErrorAlert,
  setApiErrorMsg,
  listUserEmailTemplate,
}: TabProps) => {
  const [reminderAuto, setReminderAuto] = useState(false);
  const [reminderDays, setReminderDays] = useState<number>(0);
  const [reminderCount, setReminderCount] = useState<number>(0);
  const [templateID, setTemplateID] = useState(0);
  const [templateName, setTemplateName] = useState<string>("");
  const [valueChanged, setValueChanged] = useState(false);
  const { emailTemplates, setOpenCreateEmailTemplate, setHideSendEmailTemplate } = useContext(PartnerCommsContext);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [formStateValid, setFormStateValid] = useState(false);

  useEffect(() => {
    ListEmailAutoSendReminderSetting();
    listUserEmailTemplate("");
    ListEmailReminderSettings();
    ListAutoSendReminderTemplate();
    // eslint-disable-next-line
  }, []);

  // ListEmailAutoSendReminderSetting
  const ListEmailAutoSendReminderSetting = async () => {
    useFetch2<{ autoSend: boolean; response: string }>(
      "/api/partnerCommunication/ListEmailAutoSendReminderSetting",
      "POST",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        thenCallBack: (response) => setReminderAuto(response.data.autoSend || false),
        data: {
          ownCompanyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          useCaseType: UseCaseType.ledgerRequest,
        },
      }
    );
  };

  const UpdateAutoReminderSettings = async () => {
    setShowLoadingIcon(true);
    useFetch2<{ response: string }>("/api/partnerCommunication/UpdateAutoReminderSettings", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      data: {
        autoSendStatus: reminderAuto,
        numberOfReminders: reminderCount,
        reminderDays,
        templateId: templateID,
        templateName,
        ownCompanyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        useCaseType: UseCaseType.ledgerRequest,
      },
      thenCallBack: (res) => {
        console.log(res);
        setShowLoadingIcon(false);
      },
      catchCallBack: (err) => {
        console.log(err);
        setShowLoadingIcon(false);
      },
      errorCallback: (err) => {
        console.log(err);
        setShowLoadingIcon(false);
      },
    });
  };

  const ListEmailReminderSettings = async () => {
    useFetch2<{ reminderSettings: { reminderDays: number; numberOfReminders: number } }>(
      "/api/partnerCommunication/ListEmailReminderSettings",
      "POST",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        thenCallBack: ({ data: { reminderSettings } }) => {
          setReminderDays(reminderSettings?.reminderDays);
          setReminderCount(reminderSettings?.numberOfReminders);
        },
        data: {
          ownCompanyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          useCaseType: UseCaseType.ledgerRequest,
        },
      }
    );
  };

  const ListAutoSendReminderTemplate = async () => {
    useFetch2<{ templateName: string; templateId: number }>(
      "/api/partnerCommunication/ListAutoSendReminderTemplate",
      "POST",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        thenCallBack: (param) => {
          // setTemplateID(param.data.templateId);
          const template = emailTemplates.find((_template) => _template.templateName === param.data.templateName);
          setTemplateID(template?.templateId);
          setTemplateName(param.data.templateName);
        },
        data: {
          ownCompanyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          useCaseType: UseCaseType.ledgerRequest,
        },
      }
    );
  };

  const checkFormStateValid = () => {
    if (reminderAuto) {
      if (reminderCount && reminderDays && templateName) setFormStateValid(true);
      else setFormStateValid(false);
    } else setFormStateValid(true);
  };

  useEffect(() => {
    checkFormStateValid();
  }, [reminderAuto, reminderCount, reminderDays, templateName]);

  return (
    <Grid className="mb_15">
      <Card sx={{ maxWidth: "1040px", mx: "auto" }}>
        <CardContent sx={{ p: "16px !important" }}>
          <div className="d_flex">
            <div style={{ width: "90%" }}>
              <div className="vertical_center_align space_between">
                <div className="fs_20"> Send Reminder automatically: </div>
                <div className="ml_60">
                  <FormControl>
                    <RadioGroup
                      row={true}
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reminderAuto}
                      onChange={(e) => {
                        if (e.target.value === "true") {
                          setReminderAuto(true);
                          // UpdateEmailAutoSendReminderSetting(true);
                        } else {
                          setReminderAuto(false);
                          // UpdateEmailAutoSendReminderSetting(false);
                        }
                      }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="fs_20 fw_600">If the partner is not responding:</div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> After how many days the reminder should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Days"
                        type="number"
                        size="small"
                        value={reminderDays ?? ""}
                        onChange={(e) => {
                          const days = parseInt(e.target.value as string, 10);
                          if (reminderCount !== days) setValueChanged(true);
                          setReminderDays(days);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderDays)) setReminderDays(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> How many times the reminders should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Reminders"
                        type="number"
                        size="small"
                        value={reminderCount ?? ""}
                        onChange={(e) => {
                          const count = parseInt(e.target.value as string, 10);
                          if (reminderCount !== count) setValueChanged(true);
                          setReminderCount(count);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderCount)) setReminderCount(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="vertical_center_align space_between">
                  <div className="fs_20"> Select reminder template: </div>
                  <div className="ml_60">
                    <FormControl sx={{ minWidth: 195 }} size="small">
                      <InputLabel id="select-helper-label">Select Template</InputLabel>
                      <Select
                        labelId="select-helper-label"
                        label="Select Template"
                        value={templateName}
                        onChange={(e) => {
                          if (templateName !== e.target.value && e.target.value) setValueChanged(true);
                          if (e.target.value) {
                            const templateName = e.target.value;
                            const template = emailTemplates.find(
                              (_template) => _template.templateName === templateName
                            );
                            setTemplateID(template?.templateId);
                            setTemplateName(template?.templateName);
                          }
                        }}
                        onBlur={() => {
                          if (valueChanged) {
                            // if (templateID) UpdateAutoSendReminderTemplate();
                            setValueChanged(false);
                          }
                        }}
                        displayEmpty={true}
                      >
                        {emailTemplates.map((template) => (
                          <MenuItem key={template.templateId} value={template.templateName} title="">
                            {template.templateName}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value=""
                          onClick={() => {
                            setOpenCreateEmailTemplate(true);
                            setHideSendEmailTemplate(true);
                          }}
                        >
                          <em>Add New Template</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <Box position={"relative"} top={40} left={"50%"}>
        <Button
          className="theme_btn"
          variant="contained"
          size="small"
          startIcon={!showLoadingIcon && <i className="fas fa-save" />}
          sx={{
            px: 3,
            py: 0.25,
          }}
          onClick={() => {
            UpdateAutoReminderSettings();
            uiLogger(
              reminderAuto
                ? uiLoggerName.ui_SendReminderSettingChange_Yes
                : uiLoggerName.ui_SendReminderSettingChange_No
            );
          }}
          disabled={!formStateValid || showLoadingIcon}
        >
          {showLoadingIcon ? <LoadingIcon loading={showLoadingIcon} /> : null}
          Save
        </Button>
      </Box>
      <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
        companyId={companyId}
        branchCode={branchCode}
        setShowErrorAlert={setShowErrorAlert}
        setApiErrorMsg={setApiErrorMsg}
        setShowSuccessAlert={setShowSuccessAlert}
        listUserEmailTemplate={listUserEmailTemplate}
        templateType={UseCaseType.ledgerRequest}
      />
    </Grid>
  );
};

export default LedgerRequestSettings;
