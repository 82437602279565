import { ChevronRight } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import moment from "moment";
import React, { useContext } from "react";
import { clarity } from "react-microsoft-clarity";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { RECON360_SUB_TAB } from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import download_icon from "../../../../Graphics/AutomatedRecon/download_icon.svg";
import recon_icon from "../../../../Graphics/AutomatedRecon/recon_icon.svg";
import SummaryTable from "../../../ReactTable/SummaryTable";
import { Recon360Context } from "../../Recon360";
import styles from "./ReconciliationStatement.module.scss";

export default function ReconciliationStatement({
  showExpandedSummary,
  toggleExpandSummary,
  dateOfLastRecon,
  isExpandedSummary,
  renderRowSubComponent,
  generatingSummary,
  reconSummaryDetailColDefImported,
  summaryTypeSelected,
  waitingForOutputFile,
  ledgerComparisonSummaryData,
  hasReconciled,
}) {
  const {
    companyId,
    branchCode,
    businessPartnerSelectedRef,
    downloadExcelFromApi,
    showDownloadResultsLoadingIcon,
    getManuallyChangedEntries,
    subTab,
    disableUploadBothSide,
  } = useContext(Recon360Context);
  return (
    <>
      {showExpandedSummary && (
        <Grid xs={12}>
          <div onClick={toggleExpandSummary} className="vertical_center_align space_between recon_segment">
            <span style={{ fontSize: "inherit" }}>
              Reconciliation Statement :{" "}
              <span className="recon_statement_as_on fw_400 fs_14">
                {dateOfLastRecon && `As on ${moment(dateOfLastRecon)?.format("DD-MM-YY hh:mmA")}`}
              </span>
            </span>
            <ChevronRight
              className="up_down_arrow"
              sx={{ rotate: isExpandedSummary ? "90deg" : "-90deg", transition: "all 0.2s ease" }}
            />
          </div>
        </Grid>
      )}

      <>
        <div className="recon360 account_table_column" id="table-1">
          {summaryTypeSelected === "legacy" && subTab === "Ledger" ? (
            <Collapse className="" in={isExpandedSummary} timeout="auto">
              {waitingForOutputFile ? (
                <div className="mt_25 mb_25 center_align w_100_per">
                  <Button
                    variant="contained"
                    disabled={true}
                    className="fw_700 fs_16"
                    sx={{
                      background: "#27B27C",
                      padding: "16px 22px",
                      color: "#FFF",
                      borderRadius: "10px",
                      "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
                    }}
                  >
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                    Preparing Results
                  </Button>
                </div>
              ) : (
                // }
                // {!waitingForOutputFile && (
                <div className={styles.actions_section}>
                  <Button
                    variant="contained"
                    className="fw_700 fs_16"
                    startIcon={
                      showDownloadResultsLoadingIcon ? (
                        <LoadingIcon loading={showDownloadResultsLoadingIcon} />
                      ) : (
                        <img src={download_icon} alt="download" />
                      )
                    }
                    onClick={() => {
                      clarity.setTag("Reconciliation Download", `Click`);
                      downloadExcelFromApi("fromDownloadBtn");
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_DOWNLOAD_RESULTS_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_DOWNLOAD_RESULTS_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    }}
                    sx={{
                      background: "#27B27C",
                      padding: "16px 22px",
                      color: "#FFF",
                      borderRadius: "10px",
                      "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
                    }}
                    disabled={showDownloadResultsLoadingIcon || disableUploadBothSide}
                  >
                    {generatingSummary ? "Preparing Results" : "DOWNLOAD RESULTS"}
                  </Button>
                  <Button
                    className="fw_700 fs_16"
                    startIcon={<img src={recon_icon} alt="recon"></img>}
                    variant="outlined"
                    onClick={() => {
                      getManuallyChangedEntries();
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    }}
                    sx={{
                      background: "#fff",
                      padding: "16px 22px",
                      color: "#6B6B6B",
                      borderRadius: "10px",
                      borderColor: "#6B6B6B",
                      "&:hover, &.Mui-focusVisible": { borderColor: "#6B6B6B" },
                    }}
                    disabled={disableUploadBothSide}
                  >
                    RECONCILE MANUALLY
                  </Button>
                </div>
              )}
              {ledgerComparisonSummaryData === null ? (
                ""
              ) : (
                <div className="fade_in">
                  <SummaryTable
                    id="summaryTable"
                    getCellProps={(cellInfo: any) => ({
                      style: {
                        backgroundColor:
                          cellInfo.value === "Reconciled"
                            ? "#82FB7F"
                            : cellInfo.value === "Mismatched Entry"
                            ? "#FDE485"
                            : cellInfo.value === "Missed Entry" ||
                              cellInfo.value === "Extra Entry with Suggested Match" ||
                              cellInfo.value === "Extra Entry" ||
                              cellInfo.value === "Missed Entry with Suggested Match"
                            ? "#FD8C85"
                            : "",
                      },
                    })}
                    columns={reconSummaryDetailColDefImported}
                    rows={ledgerComparisonSummaryData}
                    sortEnable={false}
                    renderRowSubComponent={renderRowSubComponent}
                    // openDetailsMismatched={openDetailsMismatched}
                    className="center_table"
                  />
                </div>
              )}
            </Collapse>
          ) : (
            ""
          )}
        </div>
      </>

      {waitingForOutputFile && summaryTypeSelected !== "legacy" && (
        //  props.subTab !== "Ledger" &&
        <div className="mt_40 center_align w_100_per">
          <Button
            variant="contained"
            disabled={true}
            className="fw_700 fs_16"
            sx={{
              background: "#27B27C",
              padding: "16px 22px",
              color: "#FFF",
              borderRadius: "10px",
              "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
            }}
          >
            <span className="file-icon">
              <i className="fas fa-circle-notch fa-spin" />
            </span>
            Preparing Results
          </Button>
        </div>
      )}
      {!waitingForOutputFile &&
        businessPartnerSelectedRef.current &&
        hasReconciled &&
        (summaryTypeSelected === "Ledger + Gaps" || summaryTypeSelected === "Both" || subTab === "Payment Advice") && (
          <div className={styles.actions_section}>
            <Button
              variant="contained"
              onClick={() => downloadExcelFromApi("fromDownloadBtn")}
              disabled={showDownloadResultsLoadingIcon || disableUploadBothSide}
              className="fw_700 fs_16"
              startIcon={
                showDownloadResultsLoadingIcon ? (
                  <LoadingIcon loading={showDownloadResultsLoadingIcon} />
                ) : (
                  <img src={download_icon} alt="download"></img>
                )
              }
              sx={{
                background: "#27B27C",
                padding: "16px 22px",
                color: "#FFF",
                borderRadius: "10px",
                "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
              }}
            >
              {generatingSummary ? "Preparing Results" : "DOWNLOAD RESULTS"}
            </Button>
            {subTab === RECON360_SUB_TAB.LEDGER && (
              <Button
                className="fw_700 fs_16"
                startIcon={<img src={recon_icon} alt="recon"></img>}
                variant="outlined"
                onClick={() => {
                  getManuallyChangedEntries();
                }}
                sx={{
                  background: "#fff",
                  padding: "16px 22px",
                  color: "#6B6B6B",
                  borderRadius: "10px",
                  borderColor: "#6B6B6B",
                  "&:hover, &.Mui-focusVisible": { borderColor: "#6B6B6B" },
                }}
                disabled={disableUploadBothSide}
              >
                RECONCILE MANUALLY
              </Button>
            )}
          </div>
        )}
    </>
  );
}
