import { ChevronRight, Download, Mail, Upload } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ErrorResponse } from "src/entity/recon-entity/ReconInterfaces";
import { ReconResultForSendingEmail } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { Recon360Context } from "../../Recon360";
import { ManualReconChanges } from "../../Recon360FileModal/ManualMatchDialog";

export default function SignOffStatement() {
  const {
    showExpandedSignOffStatement,
    setIsExpandedSignOffStatement,
    isExpandedSignOffStatement,
    dateOfLastSummary,
    businessPartnerSelected,
    companyId,
    branchCode,
    businessPartnerSelectedCompanyIdRef,
    setDateOfLastSummary,
    setShowDownloadGapSummary,
    setShowUploadSuccessAlert,
    setApiErrorMsg,
    setShowUploadErrorAlert,
    setManualReconChanges,
    setOpenManualMatches,
    ownClosingbalanceRef,
    businessPartnerClosingbalanceRef,
    businessPartnerSelectedRef,
    assignBusinessPartnerName,
    showDownloadGapSummary,
    setReconEmailFiles,
    base64ToFile,
    setEmailTemplateContent,
    emailTemplateContent,
    templateBody,
    options,
    setOpenSendMailDialog,
    disableUploadBothSide,
  } = useContext(Recon360Context);
  const { actor } = useContext(userContext);
  const [showLoadingIconUploadGapSummary, setShowLoadingIconUploadGapSummary] = useState(false);
  const [showLoadingIconDownloadGapSummary, setShowLoadingIconDownloadGapSummary] = useState(false);
  const [loadingReconResultEmail, setLoadingReconResultEmail] = useState(false);

  const toggleExpandedSignoffStatement = () => {
    setIsExpandedSignOffStatement(!isExpandedSignOffStatement);
  };

  const uploadGapSummary = async (e) => {
    uiLogger(uiLoggerNamesRecon.UI_RL_GENERATE_GAP_SUMMARY_CLICK.functionName, companyId.current, branchCode.current, {
      message: uiLoggerNamesRecon.UI_RL_GENERATE_GAP_SUMMARY_CLICK.message,
      businessPartnerId: businessPartnerSelectedRef.current,
    });
    setShowLoadingIconUploadGapSummary(true);
    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", businessPartnerSelected.toString());
    bodyFormData.append("ownCompanyId", companyId.current);
    bodyFormData.append("branchCode", branchCode.current);
    bodyFormData.append("businessPartnerCompanyId", businessPartnerSelectedCompanyIdRef.current);
    bodyFormData.append("file", e.target.files[0]);

    try {
      await axios
        .post<{ manualReconChanges: ManualReconChanges[]; [k: string]: any }>("/api/uploadReconReport", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response);
          setShowLoadingIconUploadGapSummary(false);
          setDateOfLastSummary(new Date());
          setShowDownloadGapSummary(true);
          // setGenerateGapSummaryBase64()
          setManualReconChanges(response.data.manualReconChanges || []);
          setOpenManualMatches(true);
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response?.data?.message);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          downloadGapSummary();
        })
        .catch((error) => {
          console.log("error uploadReconReport", error?.response);
          setShowLoadingIconUploadGapSummary(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}uploadReconReport`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error uploadReconReport", error?.response);
      setShowLoadingIconUploadGapSummary(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}uploadReconReport`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const downloadGapSummary = async () => {
    setShowLoadingIconDownloadGapSummary(true);
    const payloadDownloadSignOffStatement: any = {
      ownId: actor.id,
      ownClosingBalance: ownClosingbalanceRef.current,
      businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
      branchCode: branchCode.current,
    };
    // Conditionally add properties if they are not null
    if (businessPartnerSelectedRef.current !== null) {
      payloadDownloadSignOffStatement.businessPartnerId = businessPartnerSelectedRef.current;
    }
    if (companyId.current !== null) {
      payloadDownloadSignOffStatement.ownCompanyId = companyId.current;
    }
    if (businessPartnerSelectedCompanyIdRef.current !== null) {
      payloadDownloadSignOffStatement.businessPartnerCompanyId = businessPartnerSelectedCompanyIdRef.current;
    }
    try {
      await axios
        .post("/api/DownloadSignOffStatement", payloadDownloadSignOffStatement)
        .then((response) => {
          const excelData = Buffer.from(response.data.finalWorkbookBase64, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, actor?.name + "-" + assignBusinessPartnerName + " Gap Summary.xlsx");
          setShowLoadingIconDownloadGapSummary(false);
        })
        .catch((error) => {
          console.log("error DownloadSignOffStatement", error?.response);
          setShowLoadingIconDownloadGapSummary(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DownloadSignOffStatement`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DownloadSignOffStatement", error?.response);
      setShowLoadingIconDownloadGapSummary(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DownloadSignOffStatement`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const GetReconResultForEmailSend = async (bpId: number, uri: string) => {
    return new Promise((resolve: (v: ReconResultForSendingEmail) => void, reject) => {
      useFetch<ReconResultForSendingEmail>(uri, "GET", {
        config: {
          params: {
            businessPartnerId: bpId,
            companyId: companyId.current,
            branchCode: branchCode.current,
          },
        },
        setApiErrorMsg,
        setShowErrorAlert: setShowUploadErrorAlert,
        thenCallBack: (res) => {
          const { originalOwnLedger, originalBPLedger } = res.data;
          const { reconDoneStatement, summaryTable, summaryOutput } = res.data;

          const Data: ReconResultForSendingEmail = {};

          if (summaryTable && summaryOutput?.content) {
            Data.summaryTable = summaryTable;
            Data.summaryOutput = summaryOutput;
            Data.reconDoneStatement = reconDoneStatement;

            setReconEmailFiles((files) => ({
              ...files,
              summaryOutput: base64ToFile(summaryOutput.content, summaryOutput.fileName),
            }));
          }

          if (originalOwnLedger) {
            Data.originalOwnLedger = originalOwnLedger;

            setReconEmailFiles((files) => ({
              ...files,
              originalOwnLedger: originalOwnLedger.map((ownL) => base64ToFile(ownL.content, ownL.fileName)),
            }));
          }

          if (originalBPLedger) {
            Data.originalBPLedger = originalBPLedger;

            setReconEmailFiles((files) => ({
              ...files,
              originalBPLedger: originalBPLedger.map((bpL) => base64ToFile(bpL.content, bpL.fileName)),
            }));
          }

          resolve({
            ...Data,
          });
        },
        catchCallBack: (err) => reject(err),
        errorCallback: (err) => reject(err),
      });
    });
  };

  const GetReconResultForSendingEmail = async (bpId: number) => {
    const uriOutput = "/api/FetchReconSummaryFilesFromS3";
    const uriInpOwn = "/api/FetchOwnInputFilesFromS3";
    const uriInpBp = "/api/FetchBusinessPartnerInputFilesFromS3";

    const Data: ReconResultForSendingEmail = {
      originalBPLedger: [],
      originalOwnLedger: [],
      reconDoneStatement: "",
      summaryOutput: { fileName: "", content: "" },
      summaryTable: [],
    };

    const data = await Promise.all([
      GetReconResultForEmailSend(bpId, uriOutput),
      GetReconResultForEmailSend(bpId, uriInpOwn),
      GetReconResultForEmailSend(bpId, uriInpBp),
    ]);

    if (data[0].summaryOutput && data[0].summaryTable) {
      Data.summaryOutput = data[0].summaryOutput;
      Data.summaryTable = data[0].summaryTable;
      Data.reconDoneStatement = data[0].reconDoneStatement;
    }

    if (data[1].originalOwnLedger) Data.originalOwnLedger = data[1].originalOwnLedger;

    if (data[2].originalBPLedger) Data.originalBPLedger = data[2].originalBPLedger;

    if (!data[0].summaryOutput?.content || !data[0].reconDoneStatement) {
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`Can't send Email as disputes are not resolved.`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }

    return Data;
  };
  return (
    <>
      {showExpandedSignOffStatement && (
        <Grid xs={12}>
          <div onClick={toggleExpandedSignoffStatement} className="vertical_center_align space_between recon_segment">
            <span style={{ fontSize: "inherit" }}>
              Signoff Statement :{" "}
              <span className="as_on_text">
                {dateOfLastSummary && `As on ${moment(dateOfLastSummary)?.format("DD-MM-YY hh:mmA")}`}
              </span>
            </span>
            <ChevronRight
              className="up_down_arrow"
              sx={{ rotate: isExpandedSignOffStatement ? "90deg" : "-90deg", transition: "all 0.2s ease" }}
            />
          </div>
        </Grid>
      )}

      <Collapse in={isExpandedSignOffStatement} timeout="auto">
        <Grid className="mt_20 center_align_ver_horiz w_100_per" sx={{ gap: 2.5 }}>
          <Button
            className="theme_btn"
            startIcon={
              !showLoadingIconUploadGapSummary ? <Upload /> : <LoadingIcon loading={showLoadingIconUploadGapSummary} />
            }
            sx={{ "& *": { font: "inherit" } }}
          >
            <label htmlFor="invoicefile">
              <input
                // disabled={props.loadingConfig || props.businessPartnerSelected === null}
                className="file-input"
                type="file"
                name="invoicefile"
                id="invoicefile"
                value={""}
                onChange={uploadGapSummary}
                required={true}
                multiple={true}
                accept=".xlsm"
                disabled={showLoadingIconDownloadGapSummary || disableUploadBothSide}
              />

              <span className="file-label">Generate Gap Summary</span>
            </label>
          </Button>
          <div className="download_gap_summary_box">
            {showDownloadGapSummary && (
              <Button
                variant="contained"
                onClick={() => downloadGapSummary()}
                className="theme_outline_btn"
                disabled={showLoadingIconUploadGapSummary || disableUploadBothSide}
                startIcon={
                  !showLoadingIconDownloadGapSummary ? (
                    <Download />
                  ) : (
                    <LoadingIcon loading={showLoadingIconDownloadGapSummary} />
                  )
                }
              >
                Download Gap Summary
              </Button>
            )}
          </div>
          <Button
            className="theme_btn"
            startIcon={!loadingReconResultEmail ? <Mail /> : <LoadingIcon loading={loadingReconResultEmail} />}
            disabled={disableUploadBothSide}
            onClick={async () => {
              setLoadingReconResultEmail(true);

              const ReconResultForEmail = await GetReconResultForSendingEmail(businessPartnerSelected);
              const bpName = options.find((opt) => opt.id === businessPartnerSelected).name;
              const reconRunDateStr =
                "<p>" +
                `<b>Recon Summary: ${actor.name} - ${bpName}: </b>` +
                ReconResultForEmail?.reconDoneStatement +
                "</p>";
              const columns = ReconResultForEmail.summaryTable
                ? Object.values(ReconResultForEmail.summaryTable[0] || {})
                : [""];

              // const dom = new Document();
              const tableEl = document.createElement("table");
              const tableBody = document.createElement("tbody");
              const tableHead = document.createElement("thead");

              tableEl.style.borderColor = "#ddd";
              tableEl.style.borderCollapse = "collapse";
              tableEl.setAttribute("border", "1");
              tableEl.setAttribute("collapse", "true");
              tableEl.setAttribute("cellPadding", "4px");

              columns.forEach((col) => {
                const th = document.createElement("th");
                th.textContent = col;
                tableHead.append(th);
              });

              ReconResultForEmail?.summaryTable.slice(1).forEach((row) => {
                const tr = document.createElement("tr");
                Object.values(row).forEach((value) => {
                  const td = document.createElement("td");
                  td.textContent = value;
                  tr.append(td);
                });
                tableBody.append(tr);
              });

              tableEl.append(tableHead, tableBody);

              setEmailTemplateContent({
                ...emailTemplateContent,
                emailBody: templateBody + reconRunDateStr + tableEl.outerHTML + "<br />",
              });

              setLoadingReconResultEmail(false);

              if (
                ReconResultForEmail?.reconDoneStatement &&
                ReconResultForEmail?.summaryOutput?.content &&
                ReconResultForEmail?.summaryTable?.length
              )
                setOpenSendMailDialog(true);
              uiLogger(
                uiLoggerNamesRecon.UI_RL_SEND_RESULTS_TO_PARTNER_CLICK.functionName,
                companyId.current,
                branchCode.current,
                {
                  message: uiLoggerNamesRecon.UI_RL_SEND_RESULTS_TO_PARTNER_CLICK.message,
                  businessPartnerId: businessPartnerSelectedRef.current,
                }
              );
            }}
          >
            Send Result To Partner
          </Button>
        </Grid>
      </Collapse>
    </>
  );
}
