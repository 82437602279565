import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { PartnerCommsContext } from "./PartnerCommunicationContext";

const EmailTemplateDeleteWarningModal = (props: {
  handleDelete: () => Promise<void>;
  handleDeletePdf: () => Promise<void>;
}) => {
  const { isOpenDeleteModal, handleCloseDeleteModal, showDeleteLoadingIcon } = useContext(PartnerCommsContext);
  return (
    <div>
      <Dialog open={isOpenDeleteModal} aria-labelledby="StatusResetWarningModal">
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer">
          <div className="space_between">
            <Button variant="outlined" onClick={handleCloseDeleteModal} className="theme_outline_btn mr_10">
              Cancel
            </Button>
            <Button
              variant="contained"
              className="theme_btn"
              onClick={() => {
                props.handleDelete();
                props.handleDeletePdf();
              }}
              sx={{ marginRight: 1 }}
              disabled={showDeleteLoadingIcon}
            >
              {<LoadingIcon loading={showDeleteLoadingIcon} />}
              Delete
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailTemplateDeleteWarningModal;
