import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import Axios from "axios";
import React, { useContext } from "react";
import { ActorTypesForRecon, ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { ledgerUploadName } from "../../../Utils/Recon/Recon360/Constants";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { Recon360Context } from "../Recon360";
import { ConfigPopupAnchorRecon360Context } from "./ColumnHeaderConfig";

const ReuseSanitizersModal = () => {
  const {
    businessPartnerSelected,
    setApiErrorMsg,
    setShowUploadSuccessAlert,
    setShowUploadErrorAlert,
    setIsUsedAsTemplateOwn,
    setIsUsedAsTemplateBp,
    getLedgerConfigList,
  } = useContext(Recon360Context);
  const { openReuseSanitizerModal, setOpenReuseSanitizerModal, fromWhichSide } = useContext(
    ConfigPopupAnchorRecon360Context
  );
  const closeReuseSanitizersModal = (isSanitizer: boolean) => {
    saveReuseApi(isSanitizer);
    setOpenReuseSanitizerModal(false);
  };

  const saveReuseApi = async (isSanitizer: boolean) => {
    try {
      await Axios.post("/api/CreateTemplateForReuseConfig", {
        businessPartnerId: businessPartnerSelected,
        keepSanitizerRules: isSanitizer,
        type: fromWhichSide === ledgerUploadName.ownSide ? ActorTypesForRecon.ReconUser : ActorTypesForRecon.Partner,
      })

        .then((response) => {
          console.log("sussess msg", response.data);
          if (fromWhichSide === ledgerUploadName.ownSide) {
            setIsUsedAsTemplateOwn(true);
          } else {
            setIsUsedAsTemplateBp(true);
          }
          setApiErrorMsg(response?.data?.message);
          setShowUploadSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          getLedgerConfigList();
        })
        .catch((error) => {
          console.log("error CreateTemplateForReuseConfig", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}CreateTemplateForReuseConfig`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error CreateTemplateForReuseConfig", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}CreateTemplateForReuseConfig`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <div>
      <MuiDialog open={openReuseSanitizerModal} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Create a template?</DialogTitle>
          <IconButton
            onClick={() => {
              closeReuseSanitizersModal(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <FormLabel>Do you want Sanitizers also in template?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button
            className="theme_btn"
            size="small"
            sx={{
              ".MuiButton-startIcon": {
                display: "inherit",
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              closeReuseSanitizersModal(true);
            }}
          >
            Yes
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            size="small"
            onClick={() => {
              closeReuseSanitizersModal(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ReuseSanitizersModal;
