import { Button, Grid } from "@mui/material";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useRef, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ErrorResponse } from "src/entity/recon-entity/ReconInterfaces";

import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const AlgoCustomizationUpload = () => {
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showLoadingIconDownload, setShowLoadingIconDownload] = useState(false);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState(false);
  // Ref to file input element
  const fileInputRef = useRef(null);

  const downloadCurrentCustomization = () => {
    setShowLoadingIconDownload(true);
    try {
      Axios.post<any>("/api/Recon/GetReconSettings ", {
        fetchJSON: true,
      })
        .then((response) => {
          const jsonData = JSON.parse(Buffer.from(response.data.fileBase64, "base64").toString("utf-8"));
          // Convert JSON data to a Blob
          const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });

          // Save the JSON Blob as a file
          saveAs(jsonBlob, "Current customization.json");

          setShowLoadingIconDownload(false);
          setApiErrorMsg("Downloaded successfully");
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIconDownload(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetReconSettings`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIconDownload(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetReconSettings`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const uploadCurrentCustomization = (filePayload) => {
    setShowLoadingIconUpload(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", filePayload);
    // Clear the value of the file input element
    fileInputRef.current.value = null;
    try {
      Axios.post<any>("/api/Recon/UploadReconSettings ", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          setShowLoadingIconUpload(false);
          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          setShowLoadingIconUpload(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UploadReconSettings`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setShowLoadingIconUpload(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UploadReconSettings`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <>
        <Grid>
          <Grid className="center_align_ver_horiz">
            <Button
              startIcon={<LoadingIcon loading={showLoadingIconDownload} />}
              className="purple_btn"
              style={{ width: "370px" }}
              onClick={() => {
                downloadCurrentCustomization();
              }}
              disabled={showLoadingIconDownload || showLoadingIconUpload}
            >
              DOWNLOAD CURRENT CUSTOMIZATION
            </Button>
            <Button
              className="green_btn ml_30"
              style={{ width: "315px" }}
              disabled={showLoadingIconUpload || showLoadingIconDownload}
              startIcon={<LoadingIcon loading={showLoadingIconUpload} />}
              component="label"
            >
              UPLOAD CUSTOMIZATION FILE
              <input
                ref={fileInputRef} // Assign the ref to the file input element
                hidden={true}
                accept=".json"
                required={true}
                type="file"
                onChange={(e) => {
                  uploadCurrentCustomization(e.target.files[0]);
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AlgoCustomizationUpload;
