import React from "react";
import { useLocation } from "react-router-dom";
import { useFetch2 as useFetch } from "src/Components/Common/useFetch";
import wrapPromise from "../PartnerPortal/wrapPromise";

export type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const cache = new Map<string, Promise<any>>();

export function fetchData<T = any>(
  uri: string,
  setApiErrorMsg: StateDispatch<string>,
  setShowErrorAlert: StateDispatch<boolean>
) {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  if (!cache.has(uri)) {
    const promise = new Promise<T>((resolve, reject) => {
      useFetch<T>(uri, "GET", {
        setApiErrorMsg,
        setShowErrorAlert,
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          resolve(res.data);
        },
        errorCallback: reject,
        catchCallBack: reject,
      });
    });
    cache.set(uri, promise);
  }
  return wrapPromise<T>(cache.get(uri).then((res) => res));
}

export const getShortName = (name: string) => {
  if (!name) return ["", ""];
  const splittedName = name.split(".");
  const extension = splittedName[splittedName.length - 1];
  splittedName.splice(splittedName.length - 1);
  const fileName = splittedName.join("");
  return [fileName, extension] as const;
};

export interface RaisedIssue {
  issueId: number;
  name: string;
  email: string;
  phone: string;
  reason: string;
  resolved: boolean;
}

export interface RaisedIssueBc {
  businessPartnerName: string;
  businessPartnerId: number;
  requestedBy: string;
  emailId: string;
  phoneNumber: string;
  reason: string;
  status: RAISE_REQUEST_STATUS;
  issueId: number;
  vendorCode: string;
  date: Date | string;
}

export enum RAISE_REQUEST_STATUS {
  pending = "Pending",
  resolved = "Resolved",
}
